import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import { QuestionType } from 'models/QuestionTemplate';

export enum DischargeSteps {
  Information = 'information',
  Questions = 'questions',
  Needs = 'needs',
  Confirm = 'confirm',
}

export function buildDischargeFormSteps(hasQuestions = false, canContinueCare = false) {
  const allSteps = [DischargeSteps.Information, DischargeSteps.Questions, DischargeSteps.Needs, DischargeSteps.Confirm];

  if (!hasQuestions) {
    const questionsIndex = allSteps.findIndex((step) => step === DischargeSteps.Questions);
    allSteps.splice(questionsIndex, 1);
  }

  if (!canContinueCare) {
    const needsIndex = allSteps.findIndex((step) => step === DischargeSteps.Needs);
    allSteps.splice(needsIndex, 1);

    const confirmIndex = allSteps.findIndex((step) => step === DischargeSteps.Confirm);
    allSteps.splice(confirmIndex, 1);
  }

  return allSteps;
}

export function buildAnsweredQuestionsFromFormValues(
  questions: QuestionType[],
  answers: Record<string, string>
): AnswerType[] {
  return questions.map((question) => {
    const answer = answers[question.id];
    const answerWeight = findAnswerWeight(question, answer);

    return {
      ...question,
      answer: {
        text: answer,
        weight: answerWeight,
      },
    };
  });
}

function findAnswerWeight(question: QuestionType, answer: string) {
  if (question.kind === 'yes_no') {
    return question.config.positive.toLowerCase() === answer.toLowerCase() ? 'positive' : 'negative';
  }

  return 'n/a';
}
