import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function HhaRefuseIcon({ size = 14, ...props }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <g id='demo-walkthrough' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Refusal-Form' transform='translate(-403.000000, -261.000000)' fill='currentColor' fillRule='nonzero'>
          <g id='Group-20' transform='translate(330.000000, 80.000000)'>
            <g id='Group-26' transform='translate(45.000000, 169.000000)'>
              <g id='Group-15' transform='translate(28.000000, 11.000000)'>
                <g id='home-2' transform='translate(0.000000, 1.000000)'>
                  <path
                    d='M9.58610382,13.9355293 L11.3362488,13.9355293 C11.8612923,13.9355293 12.2113213,13.5855003 12.2113213,13.0604568 L12.2113213,6.93494942 L13.0863937,6.93494942 L13.0863937,6.93494942 C13.3489155,6.93494942 13.6114372,6.84744217 13.7864517,6.67242767 C14.1364807,6.32239868 14.0489735,5.79735519 13.6989445,5.4473262 L7.57343711,0.196891308 C7.22340812,-0.0656304361 6.78587188,-0.0656304361 6.43584289,0.196891308 L0.310335514,5.4473262 C-0.0396934787,5.79735519 -0.127200727,6.32239868 0.222828266,6.67242767 C0.310335514,6.84744217 0.572857259,6.93494942 0.835379003,6.93494942 C1.0103935,6.93494942 1.71045148,6.93494942 1.71045148,6.93494942 L1.71045148,13.0604568 C1.71045148,13.5855003 2.06048048,13.9355293 2.58552397,13.9355293 L4.33566893,13.9355293 C4.86071242,13.9355293 5.21074141,13.5855003 5.21074141,13.0604568 L5.21074141,10.4352393 L8.71103134,10.4352393 L8.71103134,13.0604568 C8.71103134,13.5855003 9.06106033,13.9355293 9.58610382,13.9355293 Z'
                    id='HhhaRefusePath'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
