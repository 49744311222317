import styled from 'styled-components';

import { BodySmallBold } from 'styles/typography';

const QuestionContainer = styled.div<{ $isFirst: boolean }>`
  padding: 16px 16px 16px 0;
  ${({ $isFirst }) => !$isFirst && 'border-top: 1px solid var(--black-25);'}
`;

const AnswerContainer = styled.div`
  display: flex;
  margin: 16px 0px 0px 24px;

  & > * {
    margin-right: 8px;
  }
`;

const Answer = styled.input`
  background-color: white;
  border: 2px solid rgba(15, 18, 38, 0.5);
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 100px;

  &:focus {
    border: 2px solid;
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  }
`;

const StyledQuestion = styled(BodySmallBold)`
  display: flex;
  gap: 8px;
`;

type NumericQuestionType = {
  value: string;
  setValue: (v: string) => void;
  text: string;
  index?: number;
};
export default function NumericQuestion(props: NumericQuestionType) {
  const { text, value, setValue, index = -1 } = props;

  return (
    <QuestionContainer $isFirst={index == 1}>
      <StyledQuestion>
        {index >= 0 && <span>{index}.</span>}
        <span>{text}</span>
      </StyledQuestion>
      <AnswerContainer>
        <Answer
          type='number'
          step='any'
          min='0'
          onChange={(event) => {
            // IE / Edge apparently don't prevent letters in numeric fields so strip them out.
            const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            setValue(sanitizedValue);
          }}
          value={value}
        />
      </AnswerContainer>
    </QuestionContainer>
  );
}
