import { useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import styled from 'styled-components';

import { Blurb, SkeletonBlurb } from 'components/Containers';
import UpdateAuthorizationModal from 'components/modals/UpdateAuthorizationModal';
import { parseDate, simpleDate } from 'lib/date';
import { Episode, LocationEpisode } from 'models';
import Profile from 'models/Profile';
import { colors } from 'styles/theme';
import { BodySmall, H3, Label, LabelBold } from 'styles/typography';
import PatientAuthApprovedIcon from 'svg/PatientAuthApprovedIcon';
import PatientAuthDaysExpiredIcon from 'svg/PatientAuthDaysExpiredIcon';
import PatientAuthDaysRemainingIcon from 'svg/PatientAuthDaysRemainingIcon';
import PatientAuthNotAuthorizedIcon from 'svg/PatientAuthNotAuthorizedIcon';
import PencilIcon from 'svg/PencilIcon';

const DischargeHeader = styled.div`
  display: flex;
  padding: 24px 24px 8px;
  align-items: center;
`;
const Number = styled.div`
  padding: 0 24px 24px;
`;
const Spacer = styled.div`
  flex: 1;
`;
const StyledLabel = styled(LabelBold)`
  margin-left: 8px;
  cursor: pointer;
  color: var(--primary-blue);
`;
const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Approval = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  border-top: 1px solid var(--black-10);
`;
const ApprovalText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;

const LabelSelectDateContainer = styled.div`
  display: flex;
  gap: 4px;
`;
const ActionText = styled(LabelBold)`
  cursor: pointer;
  color: ${colors.primaryBlue};
`;

const SkeletonBlank = styled(SkeletonBlurb)<{
  $width: number;
  $height: number;
  $spacing?: number;
}>`
  border-radius: ${({ $height }) => $height + 'px'};
  height: ${({ $height }) => $height + 'px'};
  width: ${({ $width }) => $width + 'px'};
  margin-bottom: ${({ $spacing }) => ($spacing ?? 0) + 'px'};
`;

type AuthorizationType = {
  locationEpisode?: LocationEpisode;
  episode?: Episode;
  profile?: Profile;
  patientName?: string;
};
export function Authorization(props: AuthorizationType) {
  const { locationEpisode, episode, profile, patientName } = props;

  const [showModal, setShowModal] = useState(false);

  if (!locationEpisode || !profile) {
    return (
      <Blurb>
        <DischargeHeader>
          <SkeletonBlank $width={200} $height={12} $spacing={10} />
        </DischargeHeader>
        <Number>
          <SkeletonBlank $width={140} $height={12} />
        </Number>
        <Approval>
          <SkeletonBlank $width={42} $height={42} />
          <ApprovalText>
            <SkeletonBlank $width={160} $height={17} $spacing={10} />
            <SkeletonBlank $width={200} $height={12} />
          </ApprovalText>
        </Approval>
      </Blurb>
    );
  }

  const authorization = locationEpisode.activeAuthorizationReview;
  if (!authorization) return <></>;

  const rehabState = locationEpisode.currentRehabState;

  const canUpdate = !episode!.archived && profile.isAcute && profile.permissions.reviewAuthorizationEdit;
  const canBeModified =
    profile.actingClientId === locationEpisode.owner.clientId && profile.permissions.reviewAuthorizationEdit;

  const parsedDate = parseDate(authorization.data.approvedThroughDate);

  const isActive = rehabState.queue && (authorization.accepted || authorization.pending);
  const isAccepted = !rehabState.queue && authorization.accepted;
  const isApproved = authorization.data.authorizationNumber && !parsedDate && (isActive || isAccepted);
  const hasExpiry = !rehabState.queue && authorization.data.authorizationNumber && parsedDate;
  const daysRemaining = differenceInCalendarDays(parsedDate ?? new Date(), new Date());

  return (
    <Blurb data-cy='authorizationCard'>
      <DischargeHeader>
        <H3>Authorization</H3>
        <Spacer />
        {canUpdate && (
          <ActionContainer data-cy='clickable' onClick={() => setShowModal(true)}>
            <PencilIcon size={12} color={colors.primaryBlue} />
            <StyledLabel>Update</StyledLabel>
          </ActionContainer>
        )}
      </DischargeHeader>
      <Number>
        <BodySmall>Number: {authorization.data.authorizationNumber || <span>&#8212;</span>}</BodySmall>
      </Number>
      <Approval>
        {isApproved ? (
          <PatientAuthApprovedIcon />
        ) : hasExpiry ? (
          daysRemaining <= 0 ? (
            <PatientAuthDaysExpiredIcon />
          ) : (
            <PatientAuthDaysRemainingIcon />
          )
        ) : (
          <PatientAuthNotAuthorizedIcon />
        )}
        <ApprovalText>
          <H3>
            {' '}
            {isApproved
              ? 'Approved'
              : hasExpiry
                ? `${Math.max(0, daysRemaining)} Day${daysRemaining === 1 ? '' : 's'} Remaining`
                : 'Not Yet Authorized'}{' '}
          </H3>
          <Label>
            {' '}
            {isApproved ? (
              isActive ? (
                'Pending Admission'
              ) : canBeModified ? (
                <LabelSelectDateContainer>
                  Approved Through:
                  <ActionText onClick={() => setShowModal(true)}>Select Date</ActionText>
                </LabelSelectDateContainer>
              ) : (
                'Initial Approval on ' + simpleDate(authorization.data.statusUpdatedAt)
              )
            ) : hasExpiry ? (
              'Approved Through: ' + simpleDate(parsedDate)
            ) : (
              'Pending Approval'
            )}{' '}
          </Label>
        </ApprovalText>
      </Approval>
      {showModal && (
        <UpdateAuthorizationModal
          profile={profile}
          setShow={setShowModal}
          patientName={patientName!}
          locationEpisode={locationEpisode}
        />
      )}
    </Blurb>
  );
}
