import React from 'react';

export default function QueueEmptyState(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='80px'
      height='80px'
      viewBox='0 0 80 80'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path
          d='M33.3194444,3.33333333 L8.06944444,3.33333333 C7.22777778,3.33333333 6.66666667,3.89444444 6.66666667,4.73611111 L6.66666667,35.5972222 C6.66666667,36.4388889 7.22777778,37 8.06944444,37 L33.3194444,37 C34.1611111,37 34.7222222,36.4388889 34.7222222,35.5972222 L34.7222222,4.73611111 C34.7222222,3.89444444 34.1611111,3.33333333 33.3194444,3.33333333 Z M19.2916667,15.9583333 L26.3055556,15.9583333 L26.3055556,18.7638889 L19.2916667,18.7638889 L19.2916667,15.9583333 Z M16.4861111,29.9861111 L12.2777778,29.9861111 L12.2777778,27.1805556 L16.4861111,27.1805556 L16.4861111,29.9861111 Z M16.4861111,24.375 L12.2777778,24.375 L12.2777778,21.5694444 L16.4861111,21.5694444 L16.4861111,24.375 Z M16.4861111,18.7638889 L12.2777778,18.7638889 L12.2777778,15.9583333 L16.4861111,15.9583333 L16.4861111,18.7638889 Z M16.4861111,13.1527778 L12.2777778,13.1527778 L12.2777778,10.3472222 L16.4861111,10.3472222 L16.4861111,13.1527778 Z M24.9027778,29.9861111 L19.2916667,29.9861111 L19.2916667,27.1805556 L24.9027778,27.1805556 L24.9027778,29.9861111 Z M29.1111111,24.375 L19.2916667,24.375 L19.2916667,21.5694444 L29.1111111,21.5694444 L29.1111111,24.375 Z M29.1111111,13.1527778 L19.2916667,13.1527778 L19.2916667,10.3472222 L29.1111111,10.3472222 L29.1111111,13.1527778 Z'
          id='queue-empty-path-1'
        />
      </defs>
      <g id='Feedback-Round-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='None-Applied' transform='translate(-264.000000, -590.000000)'>
          <g id='Group-3' transform='translate(247.000000, 590.000000)'>
            <g id='Group-2' transform='translate(17.000000, 0.000000)'>
              <circle id='Oval' fillOpacity='0.1' fill='#0F1226' cx='40' cy='40' r='40' />
              <g id='Icon/Protocol' opacity='0.25' transform='translate(20.000000, 20.000000)'>
                <mask id='queue-empty-mask-2' fill='white'>
                  <use xlinkHref='#queue-empty-path-1' />
                </mask>
                <g id='Mask' fillRule='nonzero' />
                <g id='Mixin/Fill/Black' mask='url(#queue-empty-mask-2)' fill='#0F1226' fillRule='evenodd'>
                  <rect id='Rectangle' x='0' y='0' width='40' height='40' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
