import colors from './colors';
import devices from './devices';
import dimensions from './dimensions';
import textStyles from './textStyles';

export { colors, dimensions, textStyles, devices };

export default {
  colors,
  dimensions,
  textStyles,
  devices,
};
