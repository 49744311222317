import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

const TagIcon = React.forwardRef<SVGSVGElement, Props>(({ size = 16, ...props }, forwardedRef) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 12 12'
      {...props}
      ref={forwardedRef}>
      <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' clipPath='url(#a)'>
        <path d='M.35 1.15h4.9l5.271 5.271a1.05 1.05 0 0 1 0 1.485l-3.415 3.415a1.05 1.05 0 0 1-1.485 0L.351 6.05v-4.9Z' />
        <path d='M3.5 5.35a1.05 1.05 0 1 0 0-2.1 1.05 1.05 0 0 0 0 2.1Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 .8h11.2V12H0z' />
        </clipPath>
      </defs>
    </svg>
  );
});

export default TagIcon;
