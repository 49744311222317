import LocationEpisodeSummary, { LocationEpisodeSummaryOptions } from 'models/LocationEpisodeSummary';
import RehabFacility, { RehabFacilityOptions } from 'models/RehabFacility';

import Classification from './Classification';
import Location, { LocationOptions } from './Location';
import LocationEpisode, { LocationEpisodeOptions } from './LocationEpisode';
import Patient, { PatientOptions } from './Patient';
import RehabState, { RehabStateName, RehabStateOptions } from './RehabState';

// 'Special' Episode type for use within the portfolio requests
export type SupplementalEpisodeInformation = {
  latest: boolean;
  onTrack: null | string;
  locationEpisodeId: string;
  hasNewChanges: boolean;
  statusOutOfDate: boolean;
  lengthOfStay: number;
  planTypeClassification: {
    id: string;
    name: string;
  };
  hasActiveServiceRefusals: boolean;
  unacknowledgedEscalations: number;
  unacknowledgedPriorityNotes: number;
};

export interface EpisodeOptions {
  id: string;
  payor: string | null;
  medicare: boolean | null;
  admittedOn: string | Date;
  currentRehabState: Partial<RehabStateOptions>;
  hospital: Partial<LocationOptions>;
  patient: Partial<PatientOptions>;
  physicianTeam: any;
  physicianGroup?: any;
  affiliates?: any[];
  rehabInformation: {
    latestRehabFacility: Partial<RehabFacilityOptions>;
    latestRehabFacilityType: string;
  };
  rehabStates?: Partial<RehabStateOptions>[];
  updatedAt?: string;
  archived?: boolean;
  archivedBy?: any;
  latestLocationEpisodeId?: string;
  latestLocationEpisode?: Partial<LocationEpisodeOptions>;
  locationEpisodes: Partial<LocationEpisodeSummaryOptions>[];
  episodeId?: string;
  externalId?: string | null;
  latest?: boolean;
  // onTrack?: string | null;
  trackLos?: boolean;
  // onTrackUpdatedAt?: string | null | Date;
  hasNewChanges?: boolean;
  owner?: Partial<LocationOptions>;
  unacknowledgedEscalations?: number;
  unacknowledgedPriorityNotes?: number;
  statusOutOfDate?: boolean;
}

function getDefaults(): EpisodeOptions {
  return {
    id: '',
    payor: null,
    medicare: null,
    admittedOn: '',
    currentRehabState: {} as RehabStateOptions,
    hospital: {
      name: '',
    },
    latestLocationEpisodeId: '',
    latestLocationEpisode: {} as LocationEpisodeOptions,
    locationEpisodes: [],
    owner: new Location(),
    patient: new Patient(),
    physicianTeam: new Location(),
    physicianGroup: {},
    affiliates: [],
    rehabInformation: {
      latestRehabFacility: {} as RehabFacilityOptions,
      latestRehabFacilityType: '',
    },
    rehabStates: [
      {
        current: true,
        position: 0,
        state: RehabStateName.Queue,
      } as RehabStateOptions,
    ],
    updatedAt: '',
    archived: false,
    archivedBy: null,
  };
}
/**
 * Episode model
 * @param options EpisodeOptions
 * @property id string
 * @property payor string | null
 * @property medicare boolean | null
 * @property admittedOn string | Date
 * @property currentRehabState RehabState
 * @property hospital Location
 * @property patient Patient
 * @property physicianTeam any
 * @property planTypeClassification Classification
 * @property episodeClassification Classification
 * @property physicianGroup any
 * @property affiliates any[]
 * @property rehabInformation object
 * @property rehabStates RehabState[]
 * @property updatedAt string
 * @property archived boolean
 * @property archivedBy any
 * @property latestLocationEpisodeId string
 * @property locationEpisodes LocationEpisode[]
 * @property episodeId string
 * @property externalId string | null
 * @property latest boolean
 * @property trackLos boolean
 * @property hasNewChanges boolean
 * @property owner Location
 * @property unacknowledgedEscalations number
 * @property unacknowledgedPriorityNotes number
 * @property statusOutOfDate boolean
 * @returns Episode
 * @example const episode = new Episode({ id: '123' });
 */
export class Episode {
  id: string;
  payor: string | null;
  medicare: boolean | null;
  admittedOn: string | Date;
  currentRehabState: RehabState;
  hospital: Location;
  patient: Patient;
  physicianTeam: any;

  planTypeClassification: Classification;
  episodeClassification: Classification;
  physicianGroup?: any;
  affiliates: any[];
  rehabInformation: {
    latestRehabFacility: RehabFacility;
    latestRehabFacilityType: string;
  };
  rehabStates?: RehabState[];
  updatedAt?: string;
  archived?: boolean;
  archivedBy?: any;
  latestLocationEpisodeId?: string;
  latestLocationEpisode?: LocationEpisode;
  locationEpisodes: LocationEpisodeSummary[];
  episodeId?: string;
  externalId?: string | null;
  latest?: boolean;
  // onTrack?: string | null;
  trackLos?: boolean;
  // onTrackUpdatedAt?: string | null | Date;
  hasNewChanges?: boolean;
  owner: Location;
  unacknowledgedEscalations?: number;
  unacknowledgedPriorityNotes?: number;
  statusOutOfDate?: boolean;

  constructor(options: Partial<EpisodeOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.payor = opts.payor;
    this.medicare = opts.medicare;
    this.admittedOn = opts.admittedOn;
    this.currentRehabState = new RehabState(opts.currentRehabState);
    this.hospital = new Location(opts.hospital);
    this.patient = new Patient(opts.patient);
    this.physicianTeam = opts.physicianTeam;

    this.physicianGroup = opts.physicianGroup;
    this.affiliates = opts.affiliates ?? [];
    this.rehabInformation = {
      latestRehabFacility: new RehabFacility(opts.rehabInformation.latestRehabFacility),
      latestRehabFacilityType: opts.rehabInformation.latestRehabFacilityType,
    };
    this.rehabStates = opts.rehabStates?.map((x) => new RehabState(x));
    this.updatedAt = opts.updatedAt;
    this.archived = opts.archived;
    this.archivedBy = opts.archivedBy;
    this.latestLocationEpisodeId = opts.latestLocationEpisodeId;
    this.latestLocationEpisode = new LocationEpisode(opts.latestLocationEpisode);
    this.locationEpisodes = opts.locationEpisodes.map((x) => new LocationEpisodeSummary(x));
    this.episodeId = opts.episodeId;
    this.externalId = opts.externalId;
    this.latest = opts.latest;
    // this.onTrack = opts.onTrack;
    this.trackLos = opts.trackLos;
    // this.onTrackUpdatedAt = opts.onTrackUpdatedAt;
    this.hasNewChanges = opts.hasNewChanges;
    this.owner = new Location(opts.owner);
    this.unacknowledgedEscalations = opts.unacknowledgedEscalations;
    this.unacknowledgedPriorityNotes = opts.unacknowledgedPriorityNotes;
    this.statusOutOfDate = opts.statusOutOfDate;
  }
  get type() {
    return 'Episode';
  }
  get latestRehabFacility() {
    return this.rehabInformation.latestRehabFacility;
  }
}
