import { parseDate } from 'lib/date';
import AltcsApplication from 'models/reviews/AltcsApplication';
import User from 'models/User';

import Classification, { ClassificationOptions } from './Classification';
import Location, { LocationOptions } from './Location';
import Patient, { PatientOptions } from './Patient';
import QuestionTemplate, { QuestionTemplateOptions, QuestionTemplateType } from './QuestionTemplate';
import RehabState, { RehabStateOptions } from './RehabState';
import { Review, ReviewOptions } from './Review';

export interface LocationEpisodeOptions {
  id: string;
  archived: boolean;
  currentRehabState: Partial<RehabStateOptions>;
  admittedOn: string | Date;
  endDate: string | Date;
  episodeId: string;
  episodeClassification: Partial<ClassificationOptions>;
  hasNewChanges?: boolean;
  hospital: any;
  lastValidPredecessor: any;
  latest: boolean;
  lengthOfStay: number;
  locationId: string;
  owner: Partial<LocationOptions>;
  patient: Partial<PatientOptions>;
  planTypeClassification: Partial<ClassificationOptions>;
  physicianTeam: any;
  questionTemplates: Partial<QuestionTemplateOptions>[];
  rehabInformation: any;
  rehabStates: Partial<RehabStateOptions>[];
  reviews: Partial<ReviewOptions>[];
  startDate: string;
  onTrack: string | null;
  onTrackUpdatedAt: string | null;
  statusOutOfDate: boolean;
  caseManager: User | null;
  hasActiveServiceRefusals: boolean;
}

function getDefaults(): LocationEpisodeOptions {
  return {
    id: '',
    archived: false,
    currentRehabState: new RehabState(),
    admittedOn: '',
    endDate: '',
    episodeId: '',
    episodeClassification: {} as ClassificationOptions,
    hasNewChanges: false,
    hospital: {
      name: '',
    },
    lastValidPredecessor: {},
    latest: true,
    lengthOfStay: 0,
    locationId: '',
    owner: new Location(),
    patient: new Patient(),
    planTypeClassification: {} as ClassificationOptions,
    physicianTeam: {
      name: '',
    },
    questionTemplates: [],
    rehabInformation: {
      eligibleForProgressUpdate: false,
      latestRehabFacility: {
        id: '',
        name: '',
      },
    },
    rehabStates: [new RehabState()],
    reviews: [],
    startDate: '',
    onTrack: null,
    onTrackUpdatedAt: null,
    statusOutOfDate: false,
    caseManager: null,
    hasActiveServiceRefusals: false,
  };
}
/**
 * LocationEpisode
 * @class LocationEpisode
 * @param {Partial<LocationEpisodeOptions>} [options={}]
 * @property {string} id - The id of the location episode
 * @property {boolean} archived - Whether the location episode is archived
 * @property {RehabState} currentRehabState - The current rehab state of the location episode
 * @property {Date} admittedOn - The date the patient was admitted
 * @property {Date} endDate - The date the location episode ended
 * @property {string} episodeId - The id of the episode
 * @property {Classification} episodeClassification - The classification of the episode
 * @property {boolean} hasNewChanges - Whether the location episode has new changes
 * @property {any} hospital - The hospital of the location episode
 * @property {any} lastValidPredecessor - The last valid predecessor of the location episode
 * @property {boolean} latest - Whether the location episode is the latest
 * @property {number} lengthOfStay - The length of stay of the location episode
 * @property {string} locationId - The id of the location
 * @property {Location} owner - The owner of the location episode
 * @property {Patient} patient - The patient of the location episode
 * @property {Classification} planTypeClassification - The plan type classification of the location episode
 * @property {any} physicianTeam - The physician team of the location episode
 * @property {QuestionTemplate[]} questionTemplates - The question templates of the location episode
 * @property {any} rehabInformation - The rehab information of the location episode
 * @property {RehabState[]} rehabStates - The rehab states of the location episode
 * @property {Review[]} reviews - The reviews of the location episode
 * @property {string} startDate - The start date of the location episode
 * @property {string | null} onTrack - The on track of the location episode
 * @property {string | null} onTrackUpdatedAt - The on track updated at of the location episode
 * @property {boolean} statusOutOfDate - Whether the status of the location episode is out of date
 * @property {User | null} caseManager - The case manager of the location episode
 * @property {boolean} hasActiveServiceRefusals - Whether the location episode has active service refusals
 * @method stayDescriptor - The stay descriptor of the location episode
 * @method activeAuthorizationReview - The active authorization review of the location episode
 * @method projectedDischargeReview - The projected discharge review of the location episode
 * @method discharged - The discharged of the location episode
 * @method dischargedBeforeProjectedDate - The discharged before projected date of the location episode
 * @method dischargeOverdue - The discharge overdue of the location episode
 * @method progressTemplate - The progress template of the location episode
 * @method dischargeTemplate - The discharge template of the location episode
 * @method inAdmission - The in admission of the location episode
 * @method inTreatment - The in treatment of the location episode
 * @example const locationEpisode = new LocationEpisode({ id: '38ce0cea-f787-11ea-8e07-07fe8aa91684' });
 * @returns {LocationEpisode}
 */
export default class LocationEpisode {
  id: string;
  archived: boolean;
  currentRehabState: RehabState;
  admittedOn: Date;
  endDate: Date;
  episodeId: string;
  episodeClassification: Classification;
  hasNewChanges?: boolean;
  hospital: any;
  lastValidPredecessor: any;
  latest: boolean;
  lengthOfStay: number;
  locationId: string;
  owner: Location;
  patient: Patient;
  planTypeClassification: Classification;
  physicianTeam: any;
  questionTemplates: QuestionTemplate[];
  rehabInformation: {
    [key: string]: any;
    latestRehabFacility: Location;
  };
  rehabStates: RehabState[];
  reviews: Review[];
  startDate: string;
  onTrack: string | null;
  onTrackUpdatedAt: string | null;
  statusOutOfDate: boolean;
  caseManager: User | null;
  hasActiveServiceRefusals: boolean;

  constructor(options: Partial<LocationEpisodeOptions> = {}) {
    const opts = { ...getDefaults(), ...options };
    this.id = opts.id;
    this.archived = opts.archived;
    this.currentRehabState = new RehabState(opts.currentRehabState);
    this.endDate = parseDate(opts.endDate) as Date;
    this.episodeId = opts.episodeId;
    this.episodeClassification = new Classification(opts.episodeClassification);
    this.hasNewChanges = opts.hasNewChanges;
    this.hospital = opts.hospital;
    this.lastValidPredecessor = opts.lastValidPredecessor;
    this.latest = opts.latest;
    this.lengthOfStay = opts.lengthOfStay;
    this.locationId = opts.locationId;
    this.owner = new Location(opts.owner);
    this.patient = new Patient(opts.patient);
    this.planTypeClassification = new Classification(opts.planTypeClassification);
    this.physicianTeam = opts.physicianTeam;
    this.questionTemplates = opts.questionTemplates.map((x) => new QuestionTemplate(x));
    this.rehabInformation = {
      ...opts.rehabInformation,
      latestRehabFacility: new Location(opts.rehabInformation?.latestRehabFacility),
    };
    this.rehabStates = opts.rehabStates.map((x) => new RehabState(x));
    // this.reviews = opts.reviews;
    this.reviews = opts.reviews.map((review) => new Review(review));

    this.startDate = opts.startDate;
    this.onTrack = opts.onTrack;
    this.onTrackUpdatedAt = opts.onTrackUpdatedAt;
    this.statusOutOfDate = opts.statusOutOfDate;
    this.caseManager = opts.caseManager ? new User(opts.caseManager) : null;
    this.hasActiveServiceRefusals = opts.hasActiveServiceRefusals;
  }

  get stayDescriptor() {
    if (this.archived) return 'Canceled';
    return this.latest ? 'Current' : 'Past';
  }

  get activeAuthorizationReview() {
    //used to reference r.active
    return this.reviews.find((r) => (r.pending || r.accepted) && r.isAuthorization) || null;
  }

  get projectedDischargeReview() {
    return this.reviews.find((r) => r.isProjectedDischarge) || null;
  }

  get altcsApplicationReview() {
    const review = this.reviews.find((r) => r.isAltcsApplication);
    return review ? new AltcsApplication(review) : null;
  }

  get discharged() {
    return this.currentRehabState.discharged;
  }

  get dischargedBeforeProjectedDate() {
    if (!this.discharged) return false;
    if (!this.projectedDischargeReview?.date) return false;
    const enteredAt = this.currentRehabState.enteredAt;
    return enteredAt && enteredAt < this.projectedDischargeReview?.date;
  }

  get dischargeOverdue() {
    if (!this.projectedDischargeReview?.date) return false;
    if (this.dischargedBeforeProjectedDate) return false;

    return new Date() > this.projectedDischargeReview.date;
  }

  get progressTemplate() {
    return (
      this.questionTemplates.find((template) => template.isProgress) ||
      new QuestionTemplate({ type: QuestionTemplateType.PROGRESS })
    );
  }

  get dischargeTemplate() {
    return this.questionTemplates.find((template) => template.isDischarge) || null;
  }

  get inAdmission() {
    return this.currentRehabState.admission;
  }

  get inTreatment() {
    return this.currentRehabState.inTreatment;
  }
}
