import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions } from 'components/shared/StandardModal';
import { default as Client } from 'models/Client';
import { default as Profile } from 'models/Profile';
import { useInfiniteClientOptions } from 'services/api/client';
import { updateProfile } from 'services/api/profile';

const FormContainer = styled.div`
  padding-bottom: 20px;
`;

export type SelectViewModalType = {
  setShow: (b: boolean) => void;
  profile: Profile;
};

export function SelectViewModal(props: SelectViewModalType) {
  const queryClient = useQueryClient();
  const { setShow, profile } = props;
  const [currentClient, setCurrentClient] = useState<Client | null>(profile?.actingClient);
  const navigate = useNavigate();

  const asyncClientOptions = useInfiniteClientOptions({
    leaf: true,
    sortBy: 'name',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: updateProfile,
  });

  useEffect(() => {
    if (currentClient == null) setCurrentClient(profile?.actingClient);
  }, [currentClient, profile?.actingClient]);

  const cancel = () => {
    setShow(false);
  };

  const submit = () => {
    mutate(
      { actingClientId: currentClient?.id },
      {
        onSuccess: () => {
          navigate('/patients');
          setShow(false);
          queryClient.clear();
        },
      }
    );
  };

  const changed = useMemo(() => {
    return currentClient?.id !== profile?.actingClient?.id;
  }, [currentClient, profile?.actingClient]);

  return (
    <StandardModal title='Select View' onCancel={cancel}>
      <FormContainer>
        <InputGroup title='Select a view'>
          <Select<Client>
            {...asyncClientOptions}
            placeholder='Select a view'
            getOptionValue={(client) => client.id}
            getOptionLabel={(client) => client.name}
            value={currentClient}
            onChange={(client) => {
              setCurrentClient(client);
            }}
          />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={!changed} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}
