import { createContext, useContext, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useAuth } from 'context/auth';
import { Profile } from 'models';
import { showProfile } from 'services/api/profile';
import { PortfolioSorts } from 'stores/portfolioStore';
import { usePortfolioActions } from 'stores/portfolioStore';

type ProfileContextType = {
  profile?: Profile;
  loading: boolean;
  refetch: () => void;
  errored: boolean;
};

const ProfileContext = createContext<ProfileContextType>({
  profile: undefined,
  loading: true,
  refetch: () => {},
  errored: false,
});

export function ProfileProvider({ children }: { children: React.ReactNode }) {
  const { loading: loadingAuth, token } = useAuth();
  const [ready, setReady] = useState(false);

  const { setFilters, setSorts } = usePortfolioActions();

  const {
    data: profile,
    isPending,
    refetch,
    isError,
    isLoadingError,
  } = useQuery({
    queryKey: ['profile'],
    queryFn: showProfile,
    retry: 3,
    retryDelay: 1000,
    enabled: ready,
  });

  const loading = isPending || isLoadingError || loadingAuth;

  // Gives just enough time for AxiosProvider to set the token
  // on the axios instance before we start making requests
  useEffect(() => {
    if (token && !ready) {
      setReady(true);
    }
  }, [ready, token]);

  // Set portfolio filters on load of the user's profile. This needs to be done here so that the
  // mobile navigation has access to it if the user has yet to navigate to the portfolio page.
  useEffect(() => {
    if (profile?.portfolioPreference) {
      const { sorts, ...initialFilters } = profile?.portfolioPreference?.value ?? {};

      setFilters(initialFilters);

      setSorts((sorts ?? {}) as PortfolioSorts);
    }
    // setFilters and setSorts not needed in deps array as they will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.portfolioPreference]);

  return (
    <ProfileContext.Provider value={{ profile, loading, errored: isError, refetch }}>
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfile() {
  const context = useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }

  return context;
}
