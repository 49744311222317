import { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import EditDischargeQuestionsModal from 'components/modals/EditDischargeQuestionsModal';
import Menu from 'components/shared/menu';
import { Activity, Profile } from 'models';
import { activityQueryKeys, updateActivity } from 'services/api/activity';
import { colors } from 'styles/theme';
import CircleXIcon from 'svg/CircleXIcon';
import MenuHorizontalIcon from 'svg/MenuHorizontalIcon';
import RestoreIcon from 'svg/RestoreIcon';

type Props = {
  activity: Activity;
  profile: Profile;
};

export function ActivityCardMenu(props: Props) {
  const { activity, profile } = props;
  const queryClient = useQueryClient();
  const [showEditDischargeModal, setShowEditDischargeModal] = useState(false);

  const { mutate } = useMutation({
    mutationFn: updateActivity,
  });

  const toggleRemoved = () => {
    mutate(
      {
        id: activity.id,
        textRemoved: !activity.data.textRemoved,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: activityQueryKeys.base,
          });
        },
      }
    );
  };

  const canDischarge = !!activity.isDischargeQuestions && (activity.ageInHours ?? 0) <= 72;
  const canRemove =
    activity.createdBy.id === profile.id &&
    !activity.escalated &&
    !!(activity.data.text?.length > 0 || activity.attachments.length) &&
    (activity.isNote || activity.isProgressUpdate);

  if (!canDischarge && !canRemove) return <></>;

  return (
    <Container>
      <Menu>
        <Menu.Trigger>
          <MenuHorizontalIcon />
        </Menu.Trigger>
        <Menu.Content position='right'>
          {canRemove && (
            <Menu.Item onClick={toggleRemoved}>
              {activity.data.textRemoved ? <RestoreIcon /> : <CircleXIcon color={colors.accentRed} size={18} />}
              {activity.data.textRemoved ? 'Restore Note' : 'Remove Note'}
              {!!activity.attachments.length && ' & Attachments'}
            </Menu.Item>
          )}
          {canDischarge && (
            <Menu.Item onClick={() => setShowEditDischargeModal(true)}>Edit Discharge Details</Menu.Item>
          )}
        </Menu.Content>
      </Menu>
      {activity.isDischargeQuestions && showEditDischargeModal && (
        <EditDischargeQuestionsModal activity={activity} setShow={setShowEditDischargeModal} />
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-left: auto;
  width: 20px;
  position: relative;
`;
