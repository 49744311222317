import { useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

import { InputStyles } from 'components/shared/form/Input';
import { colors } from 'styles/theme';
import CalendarIcon2 from 'svg/CalendarIcon2';

type Props = {
  minDate?: Date;
  maxDate?: Date;
  selected: Date | null;
  placeholder?: string;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  $hasError?: boolean;
};

export default function DatePicker(props: Props) {
  const dateInput = useRef<any>(null);
  const { disabled, $hasError, placeholder, ...rest } = props;
  return (
    <DatePickerWrapper $hasError={$hasError ?? false} disabled={disabled ?? false}>
      <div>
        <ReactDatePicker
          placeholderText={placeholder ?? 'MM/DD/YYYY'}
          disabled={disabled}
          {...rest}
          ref={dateInput}
          popperProps={{ strategy: 'fixed' }}
        />
      </div>
      <CalendarIconWrapper>
        <CalendarIconContainer onClick={() => dateInput.current?.handleFocus?.()}>
          <CalendarIcon2 size={14} color={disabled ? colors.black25 : colors.black} />
        </CalendarIconContainer>
      </CalendarIconWrapper>
    </DatePickerWrapper>
  );
}

const DatePickerWrapper = styled.div<{
  $hasError: boolean;
  disabled: boolean;
}>`
  display: flex;
  input {
    ${InputStyles}
    width: 200px;
  }
`;
const CalendarIconWrapper = styled.div`
  position: relative;
  width: 0;
`;
const CalendarIconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;
