import { create } from 'zustand';

export type FilterState = Record<string, FilterValue[]>;

export type FilterValue = {
  id: string;
  name: string;
  type?: string;
};

export type PortfolioSorts = Record<string, PortfolioSortValue>;

export type PortfolioSortValue = {
  key: string;
  attributeName: string;
  rehabStateApiName: string;
  direction: 'asc' | 'desc';
};

type Actions = {
  setPersistedSearch: (value: string) => void;
  setFilter: (filterKey: string, values: FilterValue[]) => void;
  setFilters: (filters: FilterState) => void;
  removeFilter: (filterKey: string, id: string) => void;
  setSorts: (sorts: PortfolioSorts) => void;
  setSort: (sortKey: string, value: PortfolioSortValue) => void;
};

type State = {
  persistedSearch: string;
  filters: FilterState;
  sorts: PortfolioSorts;
  actions: Actions;
};

export const usePortfolioStore = create<State>((set) => ({
  persistedSearch: '',
  filters: {},
  sorts: {},
  actions: {
    setPersistedSearch: (value) => set({ persistedSearch: value }),
    setFilter: (filterKey, values) =>
      set((state) => ({
        filters: {
          ...state.filters,
          [filterKey]: values,
        },
      })),
    setFilters: (filters) => set({ filters }),
    removeFilter: (filterKey, id) =>
      set((state) => ({
        filters: {
          ...state.filters,
          ...(state.filters[filterKey] ? { [filterKey]: state.filters[filterKey].filter((x) => x.id !== id) } : {}),
        },
      })),
    setSorts: (sorts) => set({ sorts }),
    setSort: (sortKey, value) =>
      set((state) => ({
        sorts: {
          ...state.sorts,
          [sortKey]: value,
        },
      })),
  },
}));

export const usePortfolioActions = () => usePortfolioStore((state) => state.actions);
