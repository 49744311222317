export enum ActivityType {
  ALTCS_APPLICATION_STATUS_UPDATE = 'altcs_application_status_update',
  AUTHORIZATION_UPDATES = 'authorization_updates',
  DISCHARGE_QUESTIONS = 'discharge_questions',
  NOTE = 'note',
  PATIENT_FIELD_CHANGES = 'patient_field_changes',
  PATIENT_MATCH_COLLABORATION = 'patient_match_collaboration',
  PROGRESS_UPDATE = 'progress_update',
  PROJECTED_DISCHARGE_UPDATES = 'projected_discharge_updates',
  REHAB_STATE_CHANGE = 'rehab_state_change',
  SERVICE_REFUSAL = 'service_refusal',
}

export const STATUS = 'status';

import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import { parseDate } from 'lib/date';
import Attachment, { AttachmentOptions } from 'models/Attachment';
import Escalation, { EscalationOptions } from 'models/Escalation';
import GroupType from 'models/GroupType';
import Patient, { PatientOptions } from 'models/Patient';

export interface ActivityOptions {
  id: string;
  createdAt: string | Date | null;
  updatedAt: string | Date | null;
  admittedAt: string | Date | null;
  enteredAt: string | Date | null;
  // attachments: any[];
  // attachmentRemoved: boolean;
  data: any;
  escalated: boolean;
  locationEpisodeId: string;
  patient: Partial<PatientOptions>;
  type: ActivityType;

  attachments: Partial<AttachmentOptions>[];
  escalation?: Partial<EscalationOptions>;
  escalationType: string;

  questions?: AnswerType[];
}
function getDefaults(): ActivityOptions {
  return {
    id: '',
    data: {},
    escalated: false,
    locationEpisodeId: '',
    patient: new Patient(),
    type: ActivityType.NOTE,

    attachments: [],
    escalation: undefined,
    escalationType: '',
    admittedAt: null,
    enteredAt: null,
    createdAt: null,
    updatedAt: null,
  };
}
/**
 * @class Activity
 * @classdesc Represents an activity in the system
 * @property {string} id - The activity's id
 * @property {Date} createdAt - The activity's creation date
 * @property {Date} updatedAt - The activity's update date
 * @property {Date} admittedAt - The activity's admission date
 * @property {Date} enteredAt - The activity's entry date
 * @property {any} data - The activity's data
 * @property {boolean} escalated - Whether the activity is escalated
 * @property {string} locationEpisodeId - The activity's location episode id
 * @property {Partial<PatientOptions>} patient - The activity's patient
 * @property {ActivityType} type - The activity's type
 * @property {Attachment[]} attachments - The activity's attachments
 * @property {boolean} attachmentRemoved - Whether the activity's attachment is removed
 * @property {Escalation} escalation - The activity's escalation
 * @param {Partial<ActivityOptions>} [options={}]
 * @example const activity = new Activity({ id: '123' });
 * @example const activity = new Activity({ id: '123', createdAt: '2022-01-01T00:00:00.000Z' });
 */
export class Activity {
  id: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  admittedAt: Date | null;
  enteredAt: Date | null;
  data: any;
  escalated: boolean;
  locationEpisodeId: string;
  patient: Partial<PatientOptions>;
  type: ActivityType;

  attachments: Attachment[];
  attachmentRemoved?: boolean;
  escalation?: Escalation;
  escalationType: string;

  questions?: AnswerType[];

  constructor(options: Partial<ActivityOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    if ((options as unknown as { text: string }).text) {
      //deal with bizarre data model
      opts.data = opts.data ?? {};
      opts.data.text = (options as unknown as { text: string }).text;
    }
    if ((options as unknown as { createdBy: any }).createdBy) {
      //deal with bizarre data model
      opts.data = opts.data ?? {};
      opts.data.createdByUser = (options as unknown as { createdBy: any }).createdBy;
    }
    if ((options as unknown as { createdByLocation: any }).createdByLocation) {
      //deal with bizarre data model
      opts.data = opts.data ?? {};
      opts.data.createdByLocation = (options as unknown as { createdByLocation: any }).createdByLocation;
    }

    this.id = opts.id;
    this.escalationType = opts.escalationType;
    this.admittedAt = parseDate(opts.admittedAt) ?? null;
    this.createdAt = parseDate(opts.createdAt) ?? null;
    this.updatedAt = parseDate(opts.updatedAt) ?? null;
    this.enteredAt = parseDate(opts.enteredAt) ?? null;
    this.questions = opts.questions;
    this.data = {
      ...opts.data,
    };
    this.escalated = opts.escalated;
    this.locationEpisodeId = opts.locationEpisodeId;
    this.patient = opts.patient;
    this.type = opts.type;
    this.attachments = opts.attachments.map((x) => new Attachment(x));

    const { escalated, escalation, data } = opts;
    if (escalation || escalated) {
      const { plaintext, text } = data;
      this.escalation = new Escalation({
        ...(escalation || {}),
        text: plaintext || text,
      });
    }
  }

  get createdBy() {
    return this.data.createdByUser;
  }

  get createdByLocation() {
    return this.data.createdByLocation;
  }

  get createdByGroupType() {
    const groupType = this.data.createdByUser?.groupType;

    return groupType ? new GroupType(groupType) : null;
  }

  get isAuthorizationUpdates() {
    return this.type === ActivityType.AUTHORIZATION_UPDATES;
  }

  get isDischargeQuestions() {
    return this.type === ActivityType.DISCHARGE_QUESTIONS;
  }

  get isNote() {
    return this.type === ActivityType.NOTE;
  }

  get isPatientFieldChange() {
    return this.type === ActivityType.PATIENT_FIELD_CHANGES;
  }

  get isPatientMatchCollaboration() {
    return this.type === ActivityType.PATIENT_MATCH_COLLABORATION;
  }

  get isProgressUpdate() {
    return this.type === ActivityType.PROGRESS_UPDATE;
  }

  get isProjectedDischargeUpdates() {
    return this.type === ActivityType.PROJECTED_DISCHARGE_UPDATES;
  }

  get isStatusUpdate() {
    return this.isProgressUpdate && this.data.questions[0]?.kind === 'status';
  }

  get isStateChange() {
    return this.type === ActivityType.REHAB_STATE_CHANGE;
  }

  get typeLabel() {
    return (
      {
        altcs_application_status_update: 'ALTCS Status Update',
        authorization_updates: 'Authorization Details',
        discharge_questions: 'Discharge details',
        patient_field_changes: 'Patient Details',
        patient_match_collaboration: 'Patient Match Collaboration',
        projected_discharge_updates: 'Projected Discharge',
        rehab_state_change: 'Status Change',
        service_refusal: 'Service Refusal Update',
        progress_update: 'Progress Update',
      }[this.type as string] ?? 'Note'
    );
  }

  get ageInHours() {
    if (!this.createdAt) return null;
    return Math.floor((new Date().getTime() - new Date(this.createdAt).getTime()) / 1000 / 60 / 60);
  }

  get edited() {
    if (!this.updatedAt || !this.createdAt) return false;
    return this.updatedAt > this.createdAt;
  }
}
