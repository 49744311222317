import { GenericAbortSignal } from 'axios';

import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import { Activity, ActivityOptions, ActivityType } from 'models/Activity';
import { Note } from 'models/Notes';
import { Paginated } from 'models/Paginated';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';

export const activityQueryKeys = {
  base: ['activities'],
  index: (params: Record<string, any> = {}) => ['activities', 'index', params],
};

type IndexParams = { locationEpisodeId: string } & BaseIndexQueryParams;
export async function indexActivities({ locationEpisodeId, ...params }: IndexParams, signal: GenericAbortSignal) {
  return http
    .get<Paginated<ActivityOptions>>(`/location_episodes/${locationEpisodeId}/activities`, { params, signal })
    .then((res) => ({
      ...res.data,
      data: res.data.data.map((x) => new Activity(x)),
    }));
}

type CreateParams = { locationEpisodeId: string; type: ActivityType } & Note;
export async function createActivity({ locationEpisodeId, ...params }: CreateParams) {
  return http
    .post<ActivityOptions>(`/location_episodes/${locationEpisodeId}/activities`, params)
    .then((res) => new Activity(res.data));
}

type UpdateParams = {
  id: string;
  questions?: AnswerType[];
  textRemoved?: boolean;
};
export async function updateActivity({ id, ...params }: UpdateParams) {
  return http.put<ActivityOptions>(`activities/${id}`, params).then((res) => new Activity(res.data));
}
