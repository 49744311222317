import React from 'react';
import styled from 'styled-components';

import XIcon from 'svg/XIcon';

type Props = {
  label: string;
  onClear: () => void;
  disabled?: boolean;
};

export default function Tag({ disabled, label, onClear }: Props) {
  const handleClear = () => {
    if (disabled) return;

    onClear();
  };

  return (
    <TagContainer disabled={disabled}>
      <Label>{label}</Label>
      <IconContainer type='button' disabled={disabled} onClick={handleClear}>
        <XIcon size={5} fill='currentColor' />
      </IconContainer>
    </TagContainer>
  );
}

const TagContainer = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.black10};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => disabled && 0.25};
`;

const Label = styled.p`
  margin: 0;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.black};
  padding: 4px 3px 4px 6px;
`;

const IconContainer = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: none;
  border: none;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.black75};

  &:hover {
    color: ${({ disabled, theme }) => !disabled && theme.colors.accentRed};
    background-color: ${({ disabled, theme }) => !disabled && theme.colors.accentRed10};
  }
`;
