import { GenericAbortSignal } from 'axios';

import { applicableTabFilters } from 'components/portfolio/helpers';
import { PortfolioTab, PortfolioTabFilter } from 'components/portfolio/Portfolio';
import { PHYSICIAN_TEAM } from 'constants/filterKeysConstants';
import { Episode, EpisodeOptions, SupplementalEpisodeInformation } from 'models/Episode';
import { indexLocationEpisodes } from 'services/api/locationEpisodes';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';
import { FilterValue } from 'stores/portfolioStore';

// Tactically defined types for use by portfolio api code.
// References models, probably does not need to be defined
// as a re-usable type within models
export type PortfolioColumn = {
  data: {
    episode: Episode;
    info: SupplementalEpisodeInformation;
  }[];
  meta: { totalPages: number; totalRecords: number };
};
type LaneResponse = {
  data: (EpisodeOptions & SupplementalEpisodeInformation)[];
  meta: { totalPages: number; totalRecords: number };
};

function extractSupplementalEpisodeInformation(
  d: EpisodeOptions & SupplementalEpisodeInformation
): SupplementalEpisodeInformation {
  return {
    locationEpisodeId: d.id,
    latest: d.latest,
    lengthOfStay: d.lengthOfStay,
    onTrack: d.onTrack,
    hasNewChanges: d.hasNewChanges,
    statusOutOfDate: d.statusOutOfDate,
    planTypeClassification: d.planTypeClassification,
    hasActiveServiceRefusals: d.hasActiveServiceRefusals,
    unacknowledgedEscalations: d.unacknowledgedEscalations,
    unacknowledgedPriorityNotes: d.unacknowledgedPriorityNotes,
  };
}

export type PortfolioFilter = {
  key: string;
  options: { id: string; name: string }[];
  isAttrValueStyle: boolean;
};

function mapPortfolioResponseToColumn(y: LaneResponse): PortfolioColumn {
  return {
    data: y.data.map((x) => ({
      episode: new Episode({ ...x, id: x.episodeId }),
      info: extractSupplementalEpisodeInformation(x),
    })),
    meta: y.meta,
  };
}

type PortfolioResponse = {
  queue: LaneResponse;
  admission: LaneResponse;
  discharged: LaneResponse;
  'in treatment': LaneResponse;
};

type IndexParams = {
  locationType?: string;
  patientState?: string;
  filters?: { [key: string]: FilterValue[] };
  currentRehabState?: string;
  active?: boolean;
} & BaseIndexQueryParams;

function serializeFilters(filters: PatientCountParams['filters']) {
  return Object.entries(filters).reduce((acc, [key, values]) => {
    if (!values.length) return acc;

    if (key === PHYSICIAN_TEAM) {
      acc[key + '[]'] = values.map((x) => x.name).join(',');
    } else {
      acc[key] = values.map((x) => x.id).join(',');
    }
    return acc;
  }, {});
}

export async function indexPortfolio({ filters, ...params }: IndexParams, signal: GenericAbortSignal) {
  const serializedParams = {
    ...params,
    ...serializeFilters(filters ?? {}),
  };

  return http
    .get<PortfolioResponse>('/location_episodes/portfolio', { params: serializedParams, signal })
    .then((res) => {
      return {
        queue: mapPortfolioResponseToColumn(res.data.queue),
        admission: mapPortfolioResponseToColumn(res.data.admission),
        discharged: mapPortfolioResponseToColumn(res.data.discharged),
        'in treatment': mapPortfolioResponseToColumn(res.data['in treatment']),
      };
    });
}

export async function indexPortfolioLane({ filters, ...params }: IndexParams, signal: GenericAbortSignal) {
  const serializedParams = {
    ...params,
    ...serializeFilters(filters ?? {}),
  };

  return http.get<LaneResponse>('/location_episodes/portfolio', { params: serializedParams, signal }).then((res) => {
    return mapPortfolioResponseToColumn(res.data);
  });
}

type PatientCountParams = {
  tab: PortfolioTabFilter;
  filters: { [key: string]: FilterValue[] };
  search: string;
};

export async function getPatientCounts({ tab, filters, search }: PatientCountParams, signal: GenericAbortSignal) {
  const applicableFilters = applicableTabFilters(filters, tab as PortfolioTab);

  return indexLocationEpisodes(
    {
      locationType: tab.locationType,
      patientState: tab.patientState,
      active: true,
      pageSize: 1,
      search,
      ...serializeFilters(applicableFilters),
    },
    signal
  );
}

export const portfolioQueryKeys = {
  index: ['portfolio'],
  count: (locationTypeOrPatientState: string) => ['portfolio', 'count', locationTypeOrPatientState],
  lane: (lane: string) => ['portfolio', lane],
};
