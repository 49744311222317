import * as yup from 'yup';

const altcsApprovalFormValidation = yup.object().shape({
  provider: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .nullable()
    .required('ALTCS Provider is required.'),
  ltcGroup: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .test('ltcGroup', 'LTC Group is required.', (obj) => Boolean(obj && !!obj.id)),
});

export default altcsApprovalFormValidation;
