import { useState } from 'react';
import { camelCase } from 'lodash';
import styled from 'styled-components';

import { useMutation, useQuery } from '@tanstack/react-query';

import { CASE_MANAGER, EPISODE_CLASSIFICATION, NONE, PLAN_TYPE_CLASSIFICATION } from 'constants/filterKeysConstants';
import {
  HOSPITAL as HOSPITAL_FILTER,
  PAYER as PAYER_FILTER,
  PHYSICIAN_GROUP as PHYSICIAN_GROUP_FILTER,
  PHYSICIAN_TEAM as PHYSICIAN_TEAM_FILTER,
} from 'constants/filterKeysConstants';
import { HOSPITAL, PAYER, PHYSICIAN_GROUP, PHYSICIAN_TEAM } from 'constants/locationTypes';
import { AttrValueOptions } from 'models/AttrValue';
import Classification, { ClassificationApiName } from 'models/Classification';
import { ClientType } from 'models/Client';
import { LocationOptions } from 'models/Location';
import Profile from 'models/Profile';
import User from 'models/User';
import { useInfiniteAttrValues } from 'services/api/attrValue';
import { useInfiniteClassifications } from 'services/api/classification';
import { useInfiniteLocations } from 'services/api/location';
import { indexPortfolio } from 'services/api/portfolio';
import { upsertPortfolioFilter } from 'services/api/preferences';
import { useInfiniteUsers } from 'services/api/user';
import { FilterState, usePortfolioActions, usePortfolioStore } from 'stores/portfolioStore';
import { colors } from 'styles/theme';
import { Label } from 'styles/typography';

import Button from '../shared/Button';
import FiltersFoldout, { ClearFilterButton } from '../shared/filters/FiltersFoldout';
import { MultiSelect } from '../shared/form/InfiniteScrollDropdown';

const DropdownContainer = styled.div<{ order?: number }>`
  display: ${({ order }) => (typeof order === 'number' ? 'block' : 'none')};
  order: ${({ order }) => (typeof order === 'number' ? order + 2 : '')};
  width: 100%;
`;

type FilterFoldoutContentType = {
  tabFilter: { locationType?: string; patientState?: string };
  enabledProviderTypeID: string;
  enabledProviderTypeName: string;
  enabledProviderApiName?: string;
  actingClientType: string;
  profile: Profile;
  closeModal: () => void;
};

export default function FilterFoldoutContent(props: FilterFoldoutContentType) {
  const {
    tabFilter,
    enabledProviderTypeID,
    enabledProviderTypeName,
    enabledProviderApiName,
    actingClientType,
    profile,
    closeModal,
  } = props;

  const filters = usePortfolioStore((state) => state.filters);
  const { setFilters } = usePortfolioActions();

  const [tempFilters, setTempFilters] = useState<FilterState>(filters);

  const { mutate } = useMutation({
    mutationFn: upsertPortfolioFilter,
  });

  const dropdownOrder = (() => {
    switch (actingClientType) {
      case ClientType.HEALTH_SYSTEM:
        return { Hospital: 1, 'Physician Team': 2 };
      case ClientType.PHYSICIAN_GROUP:
        return { 'Physician Group': 1, Hospital: 2, 'Physician Team': 3 };
      case ClientType.PAYOR:
        return { Payer: 1, Hospital: 2, 'Physician Team': 3 };
      default:
        return { Hospital: 1, 'Physician Group': 2, Payer: 3 };
    }
  })();

  const enabledProviderFilterName = camelCase(enabledProviderApiName ?? '');

  const asyncProviderOptions = useInfiniteLocations({
    sortBy: 'name asc',
    include: 'groupType',
    groupType: enabledProviderTypeID,
    viewOnly: true,
  });

  const asyncHospitalOptions = useInfiniteLocations({
    sortBy: 'name asc',
    type: HOSPITAL,
    viewOnly: true,
  });

  const asyncPhysicianTeamOptions = useInfiniteAttrValues({
    distinctDisplayName: true,
    type: PHYSICIAN_TEAM,
    active: true,
  });

  const asyncPayorOptions = useInfiniteLocations({
    type: PAYER,
    viewOnly: true,
  });

  const asyncPhysicianGroupOptions = useInfiniteLocations({
    type: PHYSICIAN_GROUP,
    viewOnly: true,
  });

  const asyncPlanTypeOptions = useInfiniteClassifications({
    type: ClassificationApiName.PLAN_TYPE,
    sortBy: 'name',
  });

  const asyncEpisodeTypeOptions = useInfiniteClassifications({
    type: ClassificationApiName.EPISODE,
    sortBy: 'name',
  });

  const asyncUserOptions = useInfiniteUsers({
    include: 'credential',
    sortBy: 'name asc',
    activeOrCaseManager: true,
  });

  const handleChangeFilters = (key: string, value: any[]) => {
    setTempFilters((prev) => ({ ...prev, [key]: value }));
  };

  const applyFilters = async () => {
    setFilters(tempFilters);
    mutate({
      clientId: profile?.actingClient.id,
      value: tempFilters,
    });

    closeModal();
  };

  const handleClear = () => {
    setTempFilters({});
  };

  const { data: resultsCount } = useQuery({
    queryKey: [
      'portfolio',
      {
        locationType: tabFilter.locationType,
        patientState: tabFilter.patientState,
        filters: tempFilters,
        currentRehabState: 'All',
        active: true,
      },
    ],
    queryFn: ({ signal }) =>
      indexPortfolio(
        {
          locationType: tabFilter.locationType,
          patientState: tabFilter.patientState,
          filters: tempFilters,
          currentRehabState: 'All',
          active: true,
        },
        signal
      ),
    select: (data) => {
      return (
        (data.queue.meta.totalRecords ?? 0) +
        (data.admission.meta.totalRecords ?? 0) +
        (data.discharged.meta.totalRecords ?? 0) +
        (data['in treatment'].meta.totalRecords ?? 0)
      );
    },
  });

  const tempFilterCount = Object.keys(tempFilters).reduce((acc, key) => acc + tempFilters[key].length, 0);
  const hasCaseManagerFlag = profile?.flags?.includes('case_manager_assignment') ?? false;

  return (
    <FiltersFoldout closeModal={closeModal}>
      {{
        content: (
          <>
            <Section>
              <SectionTitle>Co-Management Team</SectionTitle>
              <DropdownContainer order={0} data-cy={`${enabledProviderFilterName}Filter`}>
                <MultiSelect
                  {...asyncProviderOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder={enabledProviderTypeName}
                  onChange={(changes) => handleChangeFilters(enabledProviderFilterName, changes)}
                  value={tempFilters[enabledProviderFilterName] ?? []}
                />
              </DropdownContainer>
              <DropdownContainer order={dropdownOrder['Hospital']} data-cy='hospitalFilter'>
                <MultiSelect
                  {...asyncHospitalOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Hospital'
                  onChange={(changes) => handleChangeFilters(HOSPITAL_FILTER, changes)}
                  value={tempFilters[HOSPITAL_FILTER] ?? []}
                />
              </DropdownContainer>
              <DropdownContainer order={dropdownOrder['Physician Team']} data-cy='physicianTeamFilter'>
                <MultiSelect
                  {...asyncPhysicianTeamOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Physician'
                  onChange={(changes) => handleChangeFilters(PHYSICIAN_TEAM_FILTER, changes)}
                  value={(tempFilters[PHYSICIAN_TEAM_FILTER] ?? []) as AttrValueOptions[]}
                />
              </DropdownContainer>
              <DropdownContainer order={dropdownOrder['Payer']} data-cy='payerFilter'>
                <MultiSelect
                  {...asyncPayorOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Payer'
                  onChange={(changes) => handleChangeFilters(PAYER_FILTER, changes)}
                  value={(tempFilters[PAYER_FILTER] ?? []) as LocationOptions[]}
                />
              </DropdownContainer>
              <DropdownContainer order={dropdownOrder['Physician Group']} data-cy='physicianGroupFilter'>
                <MultiSelect
                  {...asyncPhysicianGroupOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Physician Group'
                  onChange={(changes) => handleChangeFilters(PHYSICIAN_GROUP_FILTER, changes)}
                  value={(tempFilters[PHYSICIAN_GROUP_FILTER] ?? []) as LocationOptions[]}
                />
              </DropdownContainer>
            </Section>
            <Section>
              <SectionTitle>Additional Filters</SectionTitle>
              <DropdownContainer order={0} data-cy='planTypeClassificationFilter'>
                <MultiSelect
                  {...asyncPlanTypeOptions}
                  prependedOptions={[new Classification({ id: 'null', name: NONE })]}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Plan Type'
                  onChange={(changes) => handleChangeFilters(PLAN_TYPE_CLASSIFICATION, changes)}
                  value={(tempFilters[PLAN_TYPE_CLASSIFICATION] ?? []) as Classification[]}
                />
              </DropdownContainer>
              <DropdownContainer order={1} data-cy='episodeClassificationFilter'>
                <MultiSelect
                  {...asyncEpisodeTypeOptions}
                  prependedOptions={[new Classification({ id: 'null', name: NONE })]}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Episode Type'
                  onChange={(changes) => handleChangeFilters(EPISODE_CLASSIFICATION, changes)}
                  value={(tempFilters[EPISODE_CLASSIFICATION] ?? []) as Classification[]}
                />
              </DropdownContainer>
              {hasCaseManagerFlag && (
                <DropdownContainer order={2} data-cy='caseManagerFilter'>
                  <MultiSelect
                    {...asyncUserOptions}
                    getOptionValue={(prop) => prop.id}
                    getOptionLabel={(prop) => prop.fullName}
                    placeholder='Case Manager'
                    onChange={(changes) => handleChangeFilters(CASE_MANAGER, changes)}
                    value={(tempFilters[CASE_MANAGER] ?? []) as User[]}
                  />
                </DropdownContainer>
              )}
            </Section>
          </>
        ),
        footer: (
          <>
            <Results>{resultsCount} patients found</Results>
            <ClearFilters>
              <ClearFilterButton disabled={!tempFilterCount} onClick={handleClear}>
                Clear Filters
              </ClearFilterButton>
              <ButtonGroup>
                <Button variant='ghost' onClick={closeModal}>
                  Cancel
                </Button>
                <Button onClick={applyFilters}>Apply</Button>
              </ButtonGroup>
            </ClearFilters>
          </>
        ),
      }}
    </FiltersFoldout>
  );
}

const ButtonGroup = styled.div`
  display: flex;
`;

const Results = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.black10};
  font-size: 12px;
  font-weight: bold;
  color: var(--black);
`;

const ClearFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SectionTitle = styled(Label)`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.black};
  order: 1;
  margin: 0;
`;
