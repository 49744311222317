import { useState } from 'react';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import ProviderFilter from 'components/allPatients/ProviderFilter';
import Button from 'components/shared/Button';
import FiltersFoldout, { ClearFilterButton } from 'components/shared/filters/FiltersFoldout';
import { MultiSelect } from 'components/shared/form/InfiniteScrollDropdown';
import {
  CASE_MANAGER,
  EPISODE_CLASSIFICATION,
  HOSPITAL,
  LATEST_REHAB_STATE,
  NONE,
  PAYER as PAYER_FILTER,
  PHYSICIAN_GROUP as PHYSICIAN_GROUP_FILTER,
  PHYSICIAN_TEAM,
  PLAN_TYPE_CLASSIFICATION,
} from 'constants/filterKeysConstants';
import { PAYER, PHYSICIAN_GROUP } from 'constants/locationTypes';
import { Profile } from 'models';
import { AttrValueOptions } from 'models/AttrValue';
import Classification, { ClassificationApiName } from 'models/Classification';
import { ClientType } from 'models/Client';
import Location from 'models/Location';
import RehabState from 'models/RehabState';
import User from 'models/User';
import { useInfiniteAttrValues } from 'services/api/attrValue';
import { useInfiniteClassifications } from 'services/api/classification';
import { DEFAULT_SORT } from 'services/api/constants';
import { useInfiniteLocations } from 'services/api/location';
import { indexAllPatients } from 'services/api/locationEpisode/allPatients';
import { indexRehabStates, rehabStatesQueryKeys } from 'services/api/rehabStates';
import { useInfiniteUsers } from 'services/api/user';
import { FilterState, usePatientFiltersActions, usePatientFiltersStore } from 'stores/patientFiltersStore';
import { colors } from 'styles/theme';
import { Label } from 'styles/typography';

const DropdownContainer = styled.div<{ order: number | undefined }>`
  display: ${({ order }) => (order == null ? 'none' : 'block')};
  order: ${({ order }) => (order ? order + 2 : 2)};
  width: 100%;
`;

type AllPatientsFiltersType = {
  profile: Profile;
  closeModal: () => void;
};

export default function AllPatientsFilters(props: AllPatientsFiltersType) {
  const { profile, closeModal } = props;

  const filters = usePatientFiltersStore((state) => state.filters);
  const { setFilters } = usePatientFiltersActions();

  const [tempFilters, setTempFilters] = useState<FilterState>(filters);

  const dropdownOrder = (() => {
    switch (profile.clientType) {
      case ClientType.HEALTH_SYSTEM:
        return { Hospital: 1, 'Physician Team': 2 };
      case ClientType.PHYSICIAN_GROUP:
        return { 'Physician Group': 1, Hospital: 2, 'Physician Team': 3 };
      case ClientType.PAYOR:
        return { Payer: 1, Hospital: 2, 'Physician Team': 3 };
      default:
        return { Hospital: 1, 'Physician Group': 2, Payer: 3 };
    }
  })();

  const asyncPayerOptions = useInfiniteLocations({
    sortBy: 'name asc',
    type: PAYER,
  });

  const asyncHospitalOptions = useInfiniteLocations({
    sortBy: DEFAULT_SORT,
    type: 'hospital',
  });

  const asyncPhysicianTeamOptions = useInfiniteAttrValues({
    sortBy: DEFAULT_SORT,
    active: dropdownOrder['Physician Team'] !== undefined,
    type: 'physician_team',
  });

  const asyncPhysicianGroupOptions = useInfiniteLocations({
    type: PHYSICIAN_GROUP,
  });

  const asyncPlanTypeOptions = useInfiniteClassifications({
    sortBy: DEFAULT_SORT,
    type: ClassificationApiName.PLAN_TYPE,
  });

  const asyncEpisodeTypeOptions = useInfiniteClassifications({
    sortBy: DEFAULT_SORT,
    type: ClassificationApiName.EPISODE,
  });

  const { data: rehabStateOptions } = useQuery({
    queryKey: rehabStatesQueryKeys.index,
    queryFn: indexRehabStates,
    select: (res) => {
      return res.data.map(({ id, state }) => ({ id, name: state }));
    },
  });

  const hasCaseManagerFlag = profile?.flags?.includes('case_manager_assignment') ?? false;

  const asyncUserOptions = useInfiniteUsers({
    include: 'credential',
    sortBy: DEFAULT_SORT,
    active: true,
  });

  const applyFilters = () => {
    setFilters(tempFilters);
    closeModal();
  };

  const handleClear = () => {
    setTempFilters({});
  };

  const handleChangeFilters = (key: string, value: any[]) => {
    setTempFilters((prev) => ({ ...prev, [key]: value }));
  };

  const { data } = useQuery({
    queryKey: ['allPatients', 'count', { filters: tempFilters }],
    queryFn: ({ signal }) => indexAllPatients({ filters: tempFilters, page: 1, pageSize: 1 }, signal),
  });

  const tempFilterCount = Object.keys(tempFilters).reduce((acc, key) => acc + tempFilters[key].length, 0);
  const resultsCount = data?.meta.totalRecords;

  return (
    <FiltersFoldout closeModal={closeModal}>
      {{
        content: (
          <>
            <Section>
              <SectionTitle>Co-Management Team</SectionTitle>
              {profile?.enabledProviderTypes.map((provider) => (
                <DropdownContainer order={0} key={provider.id} data-cy={`${provider.camelCaseApiName}Filter`}>
                  <ProviderFilter
                    provider={provider}
                    value={(tempFilters[provider.apiName] ?? []) as Location[]}
                    onChange={(changes) => handleChangeFilters(provider.apiName, changes)}
                    queryParams={{
                      groupType: provider.id,
                      sortBy: DEFAULT_SORT,
                      include: 'groupType',
                    }}
                  />
                </DropdownContainer>
              ))}
              <DropdownContainer order={dropdownOrder['Payer']} data-cy='payerFilter'>
                <MultiSelect
                  {...asyncPayerOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Payer'
                  value={(tempFilters[PAYER_FILTER] ?? []) as Location[]}
                  onChange={(changes) => handleChangeFilters(PAYER_FILTER, changes)}
                />
              </DropdownContainer>
              <DropdownContainer order={dropdownOrder['Hospital']} data-cy='hospitalFilter'>
                <MultiSelect
                  {...asyncHospitalOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Hospital'
                  value={(tempFilters[HOSPITAL] ?? []) as Location[]}
                  onChange={(changes) => handleChangeFilters(HOSPITAL, changes)}
                />
              </DropdownContainer>
              <DropdownContainer order={dropdownOrder['Physician Team']} data-cy='physicianTeamFilter'>
                <MultiSelect
                  {...asyncPhysicianTeamOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Physician'
                  value={(tempFilters[PHYSICIAN_TEAM] ?? []) as AttrValueOptions[]}
                  onChange={(changes) => handleChangeFilters(PHYSICIAN_TEAM, changes)}
                />
              </DropdownContainer>
              <DropdownContainer order={dropdownOrder['Physician Group']} data-cy='physicianGroupFilter'>
                <MultiSelect
                  {...asyncPhysicianGroupOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Physician Group'
                  value={tempFilters[PHYSICIAN_GROUP_FILTER] as Location[]}
                  onChange={(changes) => handleChangeFilters(PHYSICIAN_GROUP_FILTER, changes)}
                />
              </DropdownContainer>
            </Section>
            <Section>
              <SectionTitle>Additional Filters</SectionTitle>
              <DropdownContainer order={0} data-cy='planTypeClassificationFilter'>
                <MultiSelect
                  {...asyncPlanTypeOptions}
                  prependedOptions={[new Classification({ id: 'null', name: NONE })]}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Plan Type'
                  value={(tempFilters[PLAN_TYPE_CLASSIFICATION] ?? []) as Classification[]}
                  onChange={(changes) => handleChangeFilters(PLAN_TYPE_CLASSIFICATION, changes)}
                />
              </DropdownContainer>
              <DropdownContainer order={1} data-cy='episodeClassificationFilter'>
                <MultiSelect
                  {...asyncEpisodeTypeOptions}
                  prependedOptions={[new Classification({ id: 'null', name: NONE })]}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Episode Type'
                  value={(tempFilters[EPISODE_CLASSIFICATION] ?? []) as Classification[]}
                  onChange={(changes) => handleChangeFilters(EPISODE_CLASSIFICATION, changes)}
                />
              </DropdownContainer>
              <DropdownContainer order={2} data-cy='stageFilter'>
                <MultiSelect
                  options={rehabStateOptions ?? []}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Stage'
                  value={(tempFilters[LATEST_REHAB_STATE] ?? []) as RehabState[]}
                  onChange={(changes) => handleChangeFilters(LATEST_REHAB_STATE, changes)}
                />
              </DropdownContainer>

              {hasCaseManagerFlag && (
                <DropdownContainer order={3} data-cy='caseManagerFilter'>
                  <MultiSelect
                    {...asyncUserOptions}
                    getOptionValue={(prop) => prop.id}
                    getOptionLabel={(prop) => prop.name}
                    placeholder='Case Manager'
                    value={(tempFilters[CASE_MANAGER] ?? []) as User[]}
                    onChange={(changes) => handleChangeFilters(CASE_MANAGER, changes)}
                  />
                </DropdownContainer>
              )}
            </Section>
          </>
        ),
        footer: (
          <>
            <Results>{resultsCount} patients found</Results>
            <ClearFilters>
              <ClearFilterButton disabled={!tempFilterCount} onClick={handleClear}>
                Clear Filters
              </ClearFilterButton>
              <ButtonGroup>
                <Button variant='ghost' onClick={closeModal}>
                  Cancel
                </Button>
                <Button onClick={applyFilters}>Apply</Button>
              </ButtonGroup>
            </ClearFilters>
          </>
        ),
      }}
    </FiltersFoldout>
  );
}

const ButtonGroup = styled.div`
  display: flex;
`;

const Results = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.black10};
  font-size: 12px;
  font-weight: bold;
  color: var(--black);
`;

const ClearFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SectionTitle = styled(Label)`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.black};
  order: 1;
  margin: 0;
`;
