import { useRef, useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useActivityInput from 'components/shared/activityInput/useActivityInput';
import Button from 'components/shared/Button';
import DatePicker from 'components/shared/form/DatePicker';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { simpleDate } from 'lib/date';
import { Activity, ActivityType, LocationEpisode } from 'models';
import Profile from 'models/Profile';
import { QuestionType } from 'models/QuestionTemplate';
import { activityQueryKeys, createActivity } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { episodeQueryKeys } from 'services/api/episode';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';
import { colors } from 'styles/theme';
import { BodySmallBold, Label, LabelBold } from 'styles/typography';

import QuestionList, { QuestionListRefType } from './QuestionList/QuestionList';

export type DischargeModalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
  activities: Activity[];
  profile: Profile;
};

export default function ProgressUpdateModal(props: DischargeModalType) {
  const { setShow, patientName, locationEpisode, activities, profile } = props;
  const questionsRef = useRef<QuestionListRefType>(null);
  const [admittedDate, setAdmittedDate] = useState<Date | null>(null);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: createActivity,
  });

  const cancel = () => {
    questionsRef.current?.reset();
    resetNote();
    setAllQuestionsAnswered(false);
    setAdmittedDate(null);
    setShow(false);
  };

  const submit = () => {
    mutate(
      {
        ...getSerializedNoteValues(),
        type: ActivityType.PROGRESS_UPDATE,
        questions: questionsRef.current!.getAnswers(),
        admittedAt: admittedDate ?? undefined,
        locationEpisodeId: locationEpisode.id,
      },
      {
        onSuccess: () => {
          // only invalidate episode and location episode if the progress update results in the start of treatment
          if (locationEpisode.currentRehabState.state === 'Queue') {
            queryClient.invalidateQueries({ queryKey: episodeQueryKeys.show({ id: locationEpisode.episodeId }) });
          }
          queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }) });
          queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
          queryClient.invalidateQueries({ queryKey: attachmentsQueryKeys.count });
          cancel();
        },
      }
    );
  };

  const latestStatus = activities
    .find((a) => a.isStatusUpdate)
    ?.data?.questions?.find((q: QuestionType) => q.kind === 'status').answer.text;

  const submitDisabled =
    !allQuestionsAnswered || (locationEpisode?.currentRehabState.state === 'Queue' && !admittedDate);

  const questions: QuestionType[] = locationEpisode.progressTemplate.questions;

  return (
    <StandardModal title='Update Progress' onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        {locationEpisode?.dischargeOverdue && (
          <DischargeOverdue>
            Patient&apos;s projected discharge date of&nbsp;
            <LabelBold as='span'>{simpleDate(locationEpisode.projectedDischargeReview?.date)}</LabelBold>
            &nbsp; has passed. Please provide details regarding any barriers to discharge.
          </DischargeOverdue>
        )}
        {locationEpisode?.currentRehabState.state === 'Queue' && (
          <AdmissionDate>
            <BodySmallBold>Confirm patient&apos;s actual admission date:</BodySmallBold>
            <DatePicker
              data-cy='dateInput'
              placeholder='Select Date'
              maxDate={new Date()}
              selected={admittedDate}
              onChange={setAdmittedDate}
            />
          </AdmissionDate>
        )}
        <QuestionList
          ref={questionsRef}
          questions={questions}
          setAllQuestionsAnswered={setAllQuestionsAnswered}
          profile={profile}
          latestStatus={latestStatus}
          locationEpisode={locationEpisode}
          noteValues={note}
          setNoteValues={setNote}
          resetNoteValues={resetNote}
        />
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={submitDisabled} loading={isPending}>
          Submit
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  padding-bottom: 20px;
`;

const DischargeOverdue = styled(Label)`
  display: inline-block;
  padding: 12px 15px;
  background-color: ${() => colors.secondaryOrange25};
  border-radius: 3px;
  margin-bottom: 8px;
`;

const AdmissionDate = styled.div`
  padding-top: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--black-25);
`;
