import React, { useMemo } from 'react';
import styled from 'styled-components';

import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';

import { Blurb, RedBold, SkeletonBlurb } from 'components/Containers';
import MoreContentRequester from 'components/MoreContentRequester';
import { ActivityCardMenu } from 'components/patient/activities/ActivityMenu';
import { FieldChangesContent } from 'components/patient/activities/FieldChangesContent';
import { NoteInnerContent } from 'components/patient/activities/NoteInnerContent';
import { QuestionsContent } from 'components/patient/activities/QuestionsContent';
import { StatusContent } from 'components/patient/activities/StatusContent';
import { EscalationFooter } from 'components/shared/EscalationFooter';
import { dateAtTime, dateWithoutTimezone } from 'lib/date';
import { Activity, ActivityType, Episode, LocationEpisode } from 'models';
import { AcuteLocationType } from 'models/LocationType';
import { Paginated } from 'models/Paginated';
import Profile from 'models/Profile';
import RehabState, { RehabStateName } from 'models/RehabState';
import { colors } from 'styles/theme';
import { Label } from 'styles/typography';
import ActivityAltcsApplicationStatusUpdateIcon from 'svg/ActivityAltcsApplicationStatusUpdateIcon';
import ActivityAuthorizationUpdatesIcon from 'svg/ActivityAuthorizationUpdatesIcon';
import ActivityDischargeQuestionsIcon from 'svg/ActivityDischargeQuestionsIcon';
import ActivityEscalationIcon from 'svg/ActivityEscalationIcon';
import ActivityNoteIcon from 'svg/ActivityNoteIcon';
import ActivityPatientFieldChangeIcon from 'svg/ActivityPatientFieldChangeIcon';
import ActivityPatientMatchIcon from 'svg/ActivityPatientMatchIcon';
import ActivityProgressUpdateIcon from 'svg/ActivityProgressUpdateIcon';
import ActivityProjectedDischargeUpdatesIcon from 'svg/ActivityProjectedDischargeUpdatesIcon';
import ActivityRefuseServiceIcon from 'svg/ActivityRefuseServiceIcon';
import ActivityStateChangeIcon from 'svg/ActivityStateChangeIcon';

type NoteContentType = {
  activity: Activity;
  locationEpisode: LocationEpisode;
};
function NoteContent(props: NoteContentType) {
  const { activity, locationEpisode } = props;
  switch (activity.type) {
    case ActivityType.AUTHORIZATION_UPDATES:
    case ActivityType.PATIENT_FIELD_CHANGES:
    case ActivityType.PROJECTED_DISCHARGE_UPDATES:
      return <FieldChangesContent activity={activity} />;

    case ActivityType.REHAB_STATE_CHANGE:
    case ActivityType.PATIENT_MATCH_COLLABORATION:
      return <></>;

    case ActivityType.DISCHARGE_QUESTIONS:
      return <QuestionsContent activity={activity} />;

    case ActivityType.PROGRESS_UPDATE:
      return activity.isStatusUpdate ? (
        <StatusContent activity={activity} locationEpisode={locationEpisode} />
      ) : (
        <QuestionsContent activity={activity} />
      );

    default:
      return <NoteInnerContent activity={activity} locationEpisode={locationEpisode} />;
  }
}

const ModifiedBlurb = styled(Blurb)`
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: ${({ color, $border }) => ($border ? `5px solid ${color}` : 'none')};
`;

const Separator = styled.div`
  margin-left: 26px;
  height: 24px;
  border-left: 2px dotted var(--black-25);
`;
const NoteHeader = styled.div`
  display: flex;
  padding: 16px;
  > div {
    display: flex;
    flex-direction: column;
  }
  .icon {
    margin-right: 16px;
  }
`;
const Content = styled.div`
  border-top: 1px solid var(--black-05);
  padding: 16px 16px 0;
`;
const DateLabel = styled(Label)`
  color: var(--black-50);
  line-height: 16px;
`;
const SectionContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
  overflow: hidden;
`;

function NoteIcon(props: { activity: Activity }) {
  switch (props.activity.type) {
    case ActivityType.ALTCS_APPLICATION_STATUS_UPDATE:
      return <ActivityAltcsApplicationStatusUpdateIcon />;
    case ActivityType.AUTHORIZATION_UPDATES:
      return <ActivityAuthorizationUpdatesIcon />;
    case ActivityType.DISCHARGE_QUESTIONS:
      return <ActivityDischargeQuestionsIcon />;
    case ActivityType.PATIENT_FIELD_CHANGES:
      return <ActivityPatientFieldChangeIcon />;
    case ActivityType.PATIENT_MATCH_COLLABORATION:
      return <ActivityPatientMatchIcon />;
    case ActivityType.PROGRESS_UPDATE:
      return <ActivityProgressUpdateIcon />;
    case ActivityType.PROJECTED_DISCHARGE_UPDATES:
      return <ActivityProjectedDischargeUpdatesIcon />;
    case ActivityType.REHAB_STATE_CHANGE:
      return <ActivityStateChangeIcon />;
    case ActivityType.SERVICE_REFUSAL:
      return <ActivityRefuseServiceIcon />;
    default:
      if (props.activity.escalation?.isDefault) {
        return <ActivityEscalationIcon />;
      }
      return <ActivityNoteIcon />;
  }
}

function NoteContentHeader(props: { activity: Activity }) {
  const { activity } = props;

  if (activity.type === ActivityType.PATIENT_MATCH_COLLABORATION) {
    return (
      <Label>
        {`${activity.data.collaboratorName} has been added as a ${activity.data.collaboratorType} by `}
        <b>{activity.createdBy.fullName}</b>
        {activity.createdByLocation?.name && ` at ${activity.createdByLocation.name}`}
      </Label>
    );
  }
  if (activity.type === ActivityType.REHAB_STATE_CHANGE) {
    const rehabFacilityText =
      (activity.data.currentRehabState as RehabState).state === RehabStateName.Discharged
        ? ''
        : `${activity.data.rehabFacility.name} by `;
    let rehabStateText = {
      [RehabStateName.Queue]: 'added to the queue at',
      [RehabStateName.Admission]: 'admitted to',
      [RehabStateName.InTreatment]: 'started treatment at',
      [RehabStateName.Discharged]: '<>',
    }[(activity.data.currentRehabState as RehabState).state];
    if ((activity.data.currentRehabState as RehabState).state === RehabStateName.Discharged) {
      const reason = activity.data.dischargeInfo.reason;

      const dischargeReason = reason.charAt(0).toLowerCase() + reason.slice(1);
      const dischargeLocation = activity.data.dischargeInfo.location?.name || activity.data.dischargeInfo.locationOther;

      const dischargeReasonText = dischargeReason || 'discharged; no reason available';
      const dischargeLocationText = dischargeLocation ? `to ${dischargeLocation} ` : '';

      rehabStateText = `was ${dischargeReasonText}. Discharged from ${activity.data.rehabFacility.name} ${dischargeLocationText}by`;
    }
    return (
      <Label>
        {activity.patient.name} {rehabStateText} {rehabFacilityText}
        <b> {activity.createdBy.fullName} </b>
        on {dateWithoutTimezone(activity.enteredAt!)}
      </Label>
    );
  }
  return (
    <Label>
      {activity.isDischargeQuestions && activity.edited && <i>(Edited) </i>}
      {activity.escalation?.isDefault ? <RedBold>Escalation {activity.typeLabel}</RedBold> : activity.typeLabel}
      {!!activity.attachments?.length && ' (with attachments)'}
      {activity.type === ActivityType.PATIENT_FIELD_CHANGES ? ' updated by ' : ' added by '}
      <b>{activity.createdBy.fullName}</b>
      {activity.createdByLocation?.name && ` at ${activity.createdByLocation.name}`}
    </Label>
  );
}

function getBorderColor(activity: Activity) {
  const locationType = activity.createdBy?.locationType;

  if (activity.createdByGroupType?.isProvider) {
    return colors.accentGreen;
  }

  switch (locationType) {
    case 'physician_team':
    case AcuteLocationType.HOSPITAL:
    case AcuteLocationType.PHYSICIAN_GROUP:
    case AcuteLocationType.PAYER:
      return colors.pictonBlue;
    default:
      return colors.black10;
  }
}

function showBorder(activity: Activity) {
  return !(activity.isStateChange || activity.isPatientMatchCollaboration);
}

type AssembleNoteListInput = {
  activitiesQuery: UseInfiniteQueryResult<InfiniteData<Paginated<Activity>, unknown>, Error>;
  profile: Profile;
  episode: Episode;
  locationEpisode: LocationEpisode;
  initialData?: Activity[];
};

export function ActivityList(props: AssembleNoteListInput) {
  const { activitiesQuery, episode, profile, locationEpisode } = props;

  const activities = useMemo(() => {
    const initialData = props.initialData || [];
    return activitiesQuery.data ? [...initialData, ...activitiesQuery.data.pages.flatMap((page) => page.data)] : [];
  }, [activitiesQuery.data, props.initialData]);

  return (
    <div>
      {activities.map((activity, activityIndex) => (
        <div key={activity.id}>
          {activityIndex > 0 && <Separator />}
          <ModifiedBlurb color={getBorderColor(activity)} $border={showBorder(activity)}>
            <NoteHeader>
              <div className='icon'>
                <NoteIcon activity={activity} />
              </div>
              <div>
                <NoteContentHeader activity={activity} />
                <DateLabel>Added {dateAtTime(activity.createdAt!)}</DateLabel>
              </div>
              <ActivityCardMenu activity={activity} profile={profile} />
            </NoteHeader>
            {activity.type !== ActivityType.PATIENT_MATCH_COLLABORATION &&
              activity.type !== ActivityType.REHAB_STATE_CHANGE && (
                <Content>
                  <NoteContent activity={activity} locationEpisode={locationEpisode} />

                  {activity.escalation && (!episode.archived || activity.escalation.acknowledged) && (
                    <SectionContainer>
                      <EscalationFooter
                        locationEpisode={locationEpisode}
                        profile={profile}
                        escalation={activity.escalation}
                      />
                    </SectionContainer>
                  )}
                </Content>
              )}
          </ModifiedBlurb>
        </div>
      ))}

      <MoreContentRequester resource={activitiesQuery} />
    </div>
  );
}

const SkeletonBlank = styled(SkeletonBlurb)<{
  $width: number;
  $height: number;
  $rounding?: number;
  $margin?: number;
}>`
  border-radius: ${({ $rounding, $height }) => ($rounding ?? $height) + 'px'};
  margin-bottom: ${({ $margin }) => ($margin ?? 0) + 'px'};
  height: ${({ $height }) => $height + 'px'};
  width: ${({ $width }) => $width + 'px'};
`;

export function SkeletonActivityList() {
  return (
    <div>
      {[0, 1, 2, 3].map((i) => (
        <div key={i}>
          {i > 0 && <Separator />}
          <ModifiedBlurb color={colors.black10} $border={false}>
            <NoteHeader>
              <div className='icon'>
                <SkeletonBlank $width={24} $height={24} $rounding={4} />
              </div>
              <div>
                <SkeletonBlank $width={300} $height={12} $margin={5} />
                <SkeletonBlank $width={100} $height={12} />
              </div>
            </NoteHeader>
            <Content>
              <SectionContainer>
                <SkeletonBlank $width={200} $height={12} $margin={5} />
              </SectionContainer>
            </Content>
          </ModifiedBlurb>
        </div>
      ))}
    </div>
  );
}
