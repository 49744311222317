import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function AllPatientsIcon({ size = 24 }: Props) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='all-patients-icon-white' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g fill='currentColor' fillRule='nonzero' id='Mask'>
          <path d='M22.1052632,0 L1.05263158,0 C0.471279211,0 0,0.44771525 0,1 L0,23 C0,23.5522847 0.471279211,24 1.05263158,24 L14.7368421,24 L14.7368421,17 C14.7368421,16.4477153 15.2081213,16 15.7894737,16 L23.1578947,16 L23.1578947,1 C23.1578947,0.44771525 22.6866155,0 22.1052632,0 Z M5.26315789,6 L8.42105263,6 L8.42105263,8 L5.26315789,8 L5.26315789,6 Z M5.26315789,11 L8.42105263,11 L8.42105263,13 L5.26315789,13 L5.26315789,11 Z M10.5263158,18 L5.26315789,18 L5.26315789,16 L10.5263158,16 L10.5263158,18 Z M17.8947368,13 L10.5263158,13 L10.5263158,11 L17.8947368,11 L17.8947368,13 Z M22.5410526,18 L16.8421053,23.414 L16.8421053,18 L22.5410526,18 Z M17.8947368,6 L17.8947368,8 L10.5263158,8 L10.5263158,6 L17.8947368,6 Z' />
        </g>
      </g>
    </svg>
  );
}
