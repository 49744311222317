import { useEffect } from 'react';
import styled from 'styled-components';

import { FormMeta, FormValues } from 'components/intake/types';
import PostAcuteNeedsList from 'components/PostAcuteNeedsList';
import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput from 'components/shared/activityInput/useActivityInput';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import FormHeader from 'components/shared/form/FormHeader';
import { useForm } from 'context/form';

import FieldLabel from '../shared/form/FieldLabel';

type PostAcuteNeedsProps = {
  onPreviousStep: () => void;
  onNextStep: () => void;
};

export default function PostAcuteNeeds({ onPreviousStep, onNextStep }: PostAcuteNeedsProps) {
  const { values, meta, setValue, setMeta } = useForm<FormValues, FormMeta>();

  const { note, setNote } = useActivityInput({ ...values.note });

  useEffect(() => {
    setValue('note', note);
  }, [note, setValue]);

  return (
    <FormContainer>
      <FormHeader title='Post Acute Needs' subtitle={`Name: ${values.name}`} />

      <PostAcuteNeedsList value={values.needs ?? []} onChange={(change) => setValue('needs', change)} />

      <ActivityContainer>
        <FieldLabel>Notes (optional)</FieldLabel>
        <ActivityInput
          taggable={false}
          values={note}
          setValues={setNote}
          onUploading={(uploading) => setMeta('isUploadingAttachments', uploading)}
        />
      </ActivityContainer>

      <StyledButtonGroup>
        <Button
          variant='ghost'
          data-cy='clickable'
          type='button'
          disabled={meta.isUploadingAttachments}
          onClick={onPreviousStep}>
          Back
        </Button>
        <Button data-cy='clickable' type='button' onClick={onNextStep} disabled={meta.isUploadingAttachments}>
          Next
        </Button>
      </StyledButtonGroup>
    </FormContainer>
  );
}

const ActivityContainer = styled.div`
  width: 100%;
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 24px;
  justify-content: flex-end;

  & > button {
    font-size: 16px;
    font-weight: 700;
  }
`;

const FormContainer = styled.div`
  margin-bottom: 36px;
`;
