import { create } from 'zustand';

export type FilterValue = {
  id: string;
  name: string;
  type?: string;
};

export type FilterState = Record<string, FilterValue[]>;

type Actions = {
  setFilters: (filters: FilterState) => void;
  removeFilter: (filterKey: string, id: string) => void;
};

type State = {
  filters: FilterState;
  actions: Actions;
};

export const usePatientFiltersStore = create<State>((set) => ({
  filters: {},
  actions: {
    setFilters: (filters) => set({ filters }),
    removeFilter: (filterKey, id) =>
      set((state) => ({
        filters: {
          ...state.filters,
          ...(state.filters[filterKey] ? { [filterKey]: state.filters[filterKey].filter((x) => x.id !== id) } : {}),
        },
      })),
  },
}));

export const usePatientFiltersActions = () => usePatientFiltersStore((state) => state.actions);
