import React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';

import { simpleDate } from 'lib/date';
import { LocationEpisode } from 'models';
import RehabState, { RehabStateName } from 'models/RehabState';
import { Label, LabelSmall } from 'styles/typography';

export function ProgressBarComponent(props: { locationEpisode: LocationEpisode }) {
  const { locationEpisode } = props;
  const extract = (state: RehabStateName) =>
    locationEpisode.rehabStates.find((x) => x.state === state) ?? new RehabState({ state });
  const stageNum = locationEpisode.rehabStates.find((x) => x.current)?.position ?? 0;

  const stages = [
    extract(RehabStateName.Queue),
    extract(RehabStateName.Admission),
    extract(RehabStateName.InTreatment),
    extract(RehabStateName.Discharged),
  ];

  return (
    <ProgressBarContainer id='progress-bar-container'>
      <ProgressBarInner id='progress-bar-inner'>
        {stages.map((stage, i) => (
          <Fragment key={i}>
            {i > 0 && <ProgressBarStageDash $relative={i - stageNum} />}
            <ProgressBarDot $relative={i - stageNum}>
              <AnnotationName $relative={i - stageNum}>
                <Label>{stage.state}</Label>
              </AnnotationName>
              {i <= stageNum && (
                <AnnotationDate $relative={i - stageNum}>
                  <LabelSmall>{simpleDate(stage.enteredAt)}</LabelSmall>
                </AnnotationDate>
              )}
            </ProgressBarDot>
          </Fragment>
        ))}
      </ProgressBarInner>
    </ProgressBarContainer>
  );
}

const ProgressBarContainer = styled.div`
  height: 25px;
  @media ${({ theme }) => theme.devices.desktop} {
    padding: 12px 0 45px;
    max-width: 1072px;
  }
  max-width: 80%;
  margin: 0 auto;
  padding: 16px 0px 40px 0px;
`;

const ProgressBarInner = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0;
  max-width: 600px;
  margin: 0 auto;
  align-items: center;
`;

const ProgressBarStageDash = styled.div<{ $relative: number }>`
  height: 3px;
  flex: 1;

  background-color: ${(props) => (props.$relative <= 0 ? 'var(--accent-green)' : 'var(--black-25)')};
`;

const ProgressBarDot = styled.div<{ $relative: number }>`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border-width: 8px;
  border-style: solid;
  position: relative;

  border-color: ${(props) =>
    props.$relative < 0 ? 'var(--accent-green)' : props.$relative == 0 ? 'var(--primary-blue)' : 'var(--black-25)'};
`;
const Annotation = styled.div<{ $relative: number }>`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: 700;
  white-space: nowrap;
  color: ${(props) => (props.$relative < 0 ? 'var(--black-75)' : 'var(--black-25)')};
`;
const AnnotationName = styled(Annotation)`
  top: 22px;
  color: ${(props) => props.$relative == 0 && 'var(--primary-blue)'};
`;
const AnnotationDate = styled(Annotation)`
  top: 40px;
  color: ${(props) => props.$relative == 0 && 'var(--black)'};
`;
