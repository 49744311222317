import { ActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import { User } from 'models';
import AttrValue from 'models/AttrValue';
import Classification from 'models/Classification';
import Location from 'models/Location';
import RehabFacility from 'models/RehabFacility';
import { RehabStateName } from 'models/RehabState';

export enum PatientFormMode {
  Edit,
  New,
  Connecting,
}

type SelectValue = { label: string; value: string };
export const emptySelectValue = { label: '', value: '' };

export type FormValues = {
  externalId: string;

  // Patient information
  name: string;
  dateOfBirth: {
    month: string;
    day: string;
    year: string;
  };
  sex: SelectValue;

  // Episode information
  caseManager: User;
  hospital: Location;
  planTypeClassification: Classification;
  episodeClassification: Classification;
  physicianTeam: AttrValue;
  admittedOn: string;
  antHospitalDischarge: string;
  owner: Location;

  // Post acute information
  locationType: SelectValue;
  rehabFacility: RehabFacility;

  // Post acute needs
  needs?: string[];
  note?: ActivityInputValues;
};

export type FormMeta = {
  mode: PatientFormMode;
  isSubmitting: boolean;
  isUploadingAttachments: boolean;
  patientHasActiveServiceRefusals: boolean;
  currentRehabState: RehabStateName;
};
