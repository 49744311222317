import React from 'react';

import { useMenuContext } from 'components/shared/menu';
import Slot from 'components/shared/Slot';

export type MenuTriggerProps = React.HTMLAttributes<HTMLElement> & {
  disabled?: boolean;
};

// If you are passing in a React component as the direct child of the Menu.Trigger, it must be wrapped in React.forwardRef
// Any HTML element passed in as the direct child will automatically inhert the ref
export default function MenuTrigger({ disabled = false, ...props }: MenuTriggerProps) {
  const { toggle, triggerRef } = useMenuContext();

  return (
    <Slot
      onClick={() => {
        if (disabled) return;
        toggle();
      }}
      {...props}
      {...(triggerRef ? { ref: triggerRef } : {})}
    />
  );
}
