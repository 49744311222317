import React from 'react';

export default function ActivityAltcsApplicationStatusUpdateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='DEV_MVPBuild' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='ALTCS-Application-Status-Update' transform='translate(-608.000000, -761.000000)' fill='#0F1226'>
          <g id='Group-32' transform='translate(593.000000, 744.000000)'>
            <g id='Group-31' transform='translate(1.000000, -0.000000)'>
              <g id='Group-18' transform='translate(0.000000, 3.000000)'>
                <g id='Group-34' transform='translate(14.000000, 14.000000)'>
                  <rect
                    id='Rectangle-Copy-2'
                    fillOpacity='0.25'
                    opacity='0.25'
                    x='0'
                    y='0'
                    width='24'
                    height='24'
                    rx='3'
                  />
                  <g id='sign' transform='translate(6.000000, 6.000000)' fillOpacity='0.5' fillRule='nonzero'>
                    <path
                      d='M11.5,10.996375 L0.5,10.996375 C0.224,10.996375 0,11.220375 0,11.496375 C0,11.772375 0.224,11.996375 0.5,11.996375 L11.5,11.996375 C11.776,11.996375 12,11.772375 12,11.496375 C12,11.220375 11.776,10.996375 11.5,10.996375 Z'
                      id='Path'
                    />
                    <path
                      d='M1,10.203375 L1.9665,9.236875 C2.253,9.401375 2.575,9.496375 2.9145,9.496375 C3.4255,9.496375 3.9065,9.297375 4.268,8.935375 L9.937,3.266875 C10.6835,2.520375 10.6835,1.306375 9.937,0.559875 C9.191,-0.186625 7.976,-0.186625 7.23,0.559875 L1.561,6.228375 C1.199,6.590375 1,7.070875 1,7.581875 C1,7.920875 1.095,8.242875 1.2595,8.529375 L0.293,9.496375 L1,10.203375 Z'
                      id='Path'
                    />
                    <polygon
                      id='Path'
                      points='10.293 4.996375 7.793 7.496375 8.5 8.203375 11.707 4.996375 10.5 3.789375 9.793 4.496375'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
