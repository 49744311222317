import React, { useCallback, useEffect, useState } from 'react';
import { createContext } from 'react';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';

import { useEnvironment } from 'context/environment';
import { addResponseErrorInterceptor } from 'services/http';

interface EpicJwt extends JwtPayload {
  episode: string;
}

type AuthContextType = {
  token?: string;
  decodedToken?: EpicJwt;
  loading: boolean;
  errorCode?: number;
  logout: (options?: LogoutOptions | undefined) => void;
  renewSession: () => Promise<void>;
};
const AuthContext = createContext<AuthContextType>({
  token: undefined,
  decodedToken: undefined,
  loading: true,
  errorCode: undefined,
  logout: () => {},
  renewSession: () => Promise.resolve(),
});

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();
  const { environment } = useEnvironment();
  const {
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    logout: auth0Logout,
    isLoading: auth0IsLoading,
  } = useAuth0();
  const nav = useNavigate();

  const [urlParams] = useSearchParams();

  const code = urlParams.get('code');
  const state = urlParams.get('state');
  const isAuthError = urlParams.get('error') === 'access_denied';
  const errorDescription = urlParams.get('error_description');

  const [errorCode, setErrorCode] = useState<number>();
  const [loading, setLoading] = useState(true);

  const [token, setToken] = useState<string>();
  const [decodedToken, setDecodedToken] = useState<EpicJwt>();

  const logout = useCallback(
    (options?: LogoutOptions) => {
      auth0Logout({ logoutParams: { returnTo: `${window.location.origin}/logged-out` }, ...options });
    },
    [auth0Logout]
  );

  const renewSession = useCallback(
    async () =>
      getAccessTokenSilently({
        authorizationParams: {
          audience: environment?.AUTH0_AUDIENCE,
        },
      })
        .then((token) => {
          setToken(token);
        })
        .finally(() => {
          setLoading(false);
        }),
    [environment, getAccessTokenSilently]
  );

  useEffect(() => {
    addResponseErrorInterceptor(logout);
  }, [logout]);

  useEffect(() => {
    const handleAuth = async () => {
      if (isAuthError && errorDescription) {
        nav('/error?code=' + errorDescription.split(':')[0]);
        return;
      }

      if (auth0IsLoading || token || !environment) return;

      // authenticated with Auth0, refresh token
      if (isAuthenticated) {
        renewSession();
        return;
      }

      // See if EPIC auth token is in session storage
      if (environment && !code) {
        const token = window.sessionStorage.getItem('token');
        if (token) {
          setToken(token);
          setLoading(false);
          return;
        }
      }

      // If the OLIO_API_DOMAIN is set, we are in the main app
      // and should redirect to the auth0 login page
      if (environment.OLIO_API_DOMAIN) {
        await loginWithRedirect({
          appState: {
            returnTo: pathname == null || pathname == '/' ? '/patients' : pathname,
          },
        });
        return;
      } else if (environment.INTEGRATION_API_DOMAIN) {
        // We are in the EPIC login flow, get a token

        fetch(`${environment!.INTEGRATION_API_DOMAIN}/epic-credentials`, {
          mode: 'cors',
          method: 'POST',
          body: JSON.stringify({ state, code }),
        })
          .then((resp) => {
            if (!resp.ok) {
              setErrorCode(resp.status);
            }
            return resp;
          })
          .then((resp) => resp.json())
          .then((json) => {
            window.sessionStorage.setItem('token', json.token);
            const decoded = jwtDecode<EpicJwt>(json.token);
            setToken(json.token);
            setDecodedToken(decoded);
          })
          .finally(() => {
            const newurl = window.location.href.split('?')[0];
            window.history.pushState({ path: newurl }, '', newurl);
            setLoading(false);
          });
      }
    };

    handleAuth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment, code, state, token, isAuthenticated, auth0IsLoading, isAuthError, errorDescription]);

  return (
    <AuthContext.Provider
      value={{ loading: loading || auth0IsLoading, token, decodedToken, logout, renewSession, errorCode }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
