import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput from 'components/shared/activityInput/useActivityInput';
import Button from 'components/shared/Button';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, ColorThemeName, PatientName } from 'components/shared/StandardModal';
import { ActivityType, LocationEpisode } from 'models';
import { activityQueryKeys, createActivity } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { Label } from 'styles/typography';

export type EscalateModalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
};

export default function EscalateModal(props: EscalateModalType) {
  const { setShow, patientName, locationEpisode } = props;
  const queryClient = useQueryClient();

  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput({ escalated: true });
  const noteEmpty = !note.text.trim() && !note.attachments?.length;

  const { mutate, isPending } = useMutation({
    mutationFn: createActivity,
  });

  const cancel = () => {
    setShow(false);
    resetNote();
  };

  const submit = () => {
    mutate(
      {
        ...getSerializedNoteValues(),
        locationEpisodeId: locationEpisode.id,
        type: ActivityType.NOTE,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
          queryClient.invalidateQueries({ queryKey: attachmentsQueryKeys.count });
          cancel();
        },
      }
    );
  };

  return (
    <StandardModal colorTheme={ColorThemeName.Danger} title='Escalation' onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <InputGroup title='Please provide details surrounding the escalation'>
        <ActivityInput
          placeholder={'Enter details surrounding the escalation here...'}
          showPostButton={false}
          showEscalate={false}
          profile={undefined}
          locationEpisodeId={locationEpisode.id}
          values={note}
          setValues={setNote}
        />
      </InputGroup>
      <Callout>*Sending an escalation will notify all members of the co-management team</Callout>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={noteEmpty} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}

const Callout = styled(Label)`
  background-color: var(--black-05);
  display: flex;
  font-style: italic;
  padding: 10px;
  margin-bottom: 40px;
`;
