export enum ClassificationApiName {
  EPISODE = 'episode',
  PLAN_TYPE = 'plan_type',
}

export enum ClassificationClass {
  EPISODE = 'Classification::Episode',
  PLAN_TYPE = 'Classification::PlanType',
}

export interface ClassificationOptions {
  id: string;
  name: string;
  type: ClassificationClass;
  clientIds: string[];
  clientNames: string[];
  createdAt?: string | Date;
  updatedAt?: string | Date;
}

function getDefaults(): ClassificationOptions {
  return {
    id: '',
    name: '',
    type: ClassificationClass.PLAN_TYPE,
    clientIds: [],
    clientNames: [],
  };
}

/**
 * @class Classification
 * @classdesc Represents a classification in the system
 * @property {string} id - The classification's id
 * @property {string} name - The classification's name
 * @property {any} type - The classification's type
 * @property {string[]} clientIds - The classification's client ids
 * @property {string[]} clientNames - The classification's client names
 * @property {string | Date} createdAt - The classification's creation date
 * @property {string | Date} updatedAt - The classification's update date
 * @param {Partial<ClassificationOptions>} [options={}]
 */
export default class Classification {
  id: string;
  name: string;
  type: ClassificationApiName;
  clientIds: string[];
  clientNames: string[];
  createdAt?: string | Date;
  updatedAt?: string | Date;

  constructor(options: Partial<ClassificationOptions> = {}) {
    const opts = { ...getDefaults(), ...options };
    this.id = opts.id;
    this.name = opts.name;
    this.type = classificationType(opts.type)!;
    this.clientIds = opts.clientIds;
    this.clientNames = opts.clientNames;
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
  }

  get isPlanType() {
    return this.type === ClassificationApiName.PLAN_TYPE;
  }

  get isEpisode() {
    return this.type === ClassificationApiName.EPISODE;
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      type: classificationClass(this.type),
    };
  }
}

export const classificationType = (type: string) => {
  switch (type) {
    case ClassificationClass.PLAN_TYPE:
      return ClassificationApiName.PLAN_TYPE;
    case ClassificationClass.EPISODE:
      return ClassificationApiName.EPISODE;
  }
};

const classificationClass = (type: string) => {
  switch (type) {
    case ClassificationApiName.PLAN_TYPE:
      return ClassificationClass.PLAN_TYPE;
    case ClassificationApiName.EPISODE:
      return ClassificationClass.EPISODE;
  }
};
