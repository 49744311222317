import { NotificationPreferences, Notifications } from 'models/Notificiations';
import { BaseQueryParams } from 'services/api/types';
import http from 'services/http';

export const notificationPreferencesQueryKeys = {
  index: (params: Record<string, any> = {}) => ['notificationPreferences', 'index', params] as const,
};

type IndexParams = {
  id: string;
} & BaseQueryParams;
export async function fetchNotificationPreferences({ id, ...params }: IndexParams) {
  return http
    .get<Notifications>(`/me/notification_preferences?${id}`, { params })
    .then((response) => new Notifications(response.data).data);
}

export async function createNotificationPreferences(params: NotificationPreferences) {
  return http
    .post<Notifications>(`/me/notification_preferences`, params)
    .then((response) => new Notifications(response.data));
}

type UpdateParams = {
  urlExtension: string;
} & NotificationPreferences;
export async function updateNotificationPreferences({ urlExtension, ...params }: UpdateParams) {
  return http
    .put<Notifications>(`/me/notification_preferences${urlExtension}`, params)
    .then((response) => new Notifications(response.data));
}
