import Client, { ClientOptions } from 'models/Client';
import GroupType, { GroupTypeOptions } from 'models/GroupType';

import LocationType, { AcuteLocationType, LocationTypeOptions } from './LocationType';

export interface LocationOptions {
  id: string;
  archived: false;
  name: string;
  address: {
    streetAddress: string;
    streetAddressLine2: string;
    city: string;
    state: string;
    zip: string;
  };
  client: Partial<ClientOptions>;
  clientId: string;
  clientName: string;
  locationType: LocationTypeOptions;
  groupType: Partial<GroupTypeOptions>;
  groupTypeId: string;
  timezone: string;
  type: string;
  subType: string;
  groups: LocationOptions[];
  owners: LocationOptions[];
}

export function getDefaults(): LocationOptions {
  return {
    id: '',
    archived: false,
    name: '',
    address: {
      streetAddress: '',
      streetAddressLine2: '',
      city: '',
      state: '',
      zip: '',
    },
    client: {} as ClientOptions,
    clientId: '',
    clientName: '',
    locationType: new LocationType(),
    groupType: new GroupType(),
    groupTypeId: '',
    timezone: '',
    type: '',
    subType: '',
    groups: [],
    owners: [],
  };
}
/**
 * @class Location
 * @classdesc Represents a location in the system
 * @property {string} id - The location's id
 * @property {boolean} archived - Whether the location is archived
 * @property {string} name - The location's name
 * @property {string} clientName - The name of the client
 * @property {string} type - The type of the location
 * @property {object} address - The location's address
 * @property {string} address.streetAddress - The location's street address
 * @property {string} address.streetAddressLine2 - The location's street address line 2
 * @property {string} address.city - The location's city
 * @property {string} address.state - The location's state
 * @property {string} address.zip - The location's zip code
 * @property {Client} client - The client the location belongs to
 * @property {string} clientId - The client's id
 * @property {LocationType} locationType - The location's type
 * @property {GroupType} groupType - The location's group type
 * @property {string} groupTypeId - The group type's id
 * @property {string} timezone - The location's timezone
 * @property {Location[]} groups - The location's groups
 * @property {Location[]} owners - The location's owners
 * @method serialize - Serializes the location
 * @method isHospital - Whether the location is a hospital
 * @method isPayer - Whether the location is a payer
 * @method isPhysicianGroup - Whether the location is a physician group
 * @method isAffiliate - Whether the location is an affiliate
 * @method toFormValues - Converts the location to form values
 * @static acronymFor - Returns the acronym for a location type
 * @param {Partial<LocationOptions>} [opts={}] - The options for the location
 * @example const location = new Location({ id: '123', name: 'Location' });
 * @example location.serialize();
 *
 */
export default class Location {
  id: string;
  archived: false;
  name: string;
  clientName: string;
  address: {
    streetAddress: string;
    streetAddressLine2: string;
    city: string;
    state: string;
    zip: string;
  };
  client: Client;
  clientId: string;
  locationType: LocationTypeOptions;
  groupType: Partial<GroupTypeOptions>;
  groupTypeId: string;
  subType: string;
  timezone: string;
  type: string;
  groups: Location[];
  owners: Location[];

  constructor(opts: Partial<LocationOptions> = {}) {
    const options = { ...getDefaults(), ...opts };
    this.id = options.id;
    this.archived = options.archived;
    this.name = options.name;
    this.clientName = options.clientName;
    this.type = options.type;
    this.subType = options.subType;
    this.address = options.address;
    this.locationType = options.locationType;
    this.groupType = options.groupType ? new GroupType(options.groupType) : options.groupType;
    this.groupTypeId = options.groupTypeId;
    this.timezone = options.timezone;
    this.client = new Client(options.client);
    this.clientId = options.clientId;
    this.groups = options.groups.map((group) => new Location(group));
    this.owners = options.owners.map((owner) => new Location(owner));
  }

  serialize() {
    const { ...propsToSerialize } = this;
    return {
      ...propsToSerialize,
      groupIds: this.groups.map((group) => group.id),
      ownerIds: this.owners.map((group) => group.id),
    };
  }

  get isHospital() {
    return this.locationType.kind === AcuteLocationType.HOSPITAL;
  }

  get isPayer() {
    return this.locationType.kind === AcuteLocationType.PAYER;
  }

  get isPhysicianGroup() {
    return this.locationType.kind === AcuteLocationType.PHYSICIAN_GROUP;
  }

  get isAffiliate() {
    return this.locationType.kind === AcuteLocationType.AFFILIATE;
  }

  toFormValues() {
    return {
      ...this,
      address: {
        ...this.address,
        state: this.address?.state ? { label: this.address.state, value: this.address.state } : '',
      },
      client: this.client.id ? this.client : '',
      groups: this.groups,
      owners: this.owners,
      type: this.locationType.kind ? this.locationType : undefined,
      groupType: this.groupType?.id ? this.groupType : undefined,
    };
  }
}
