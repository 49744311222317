import GroupType from './GroupType';

export enum NotificationChannel {
  EMAIL = 'email',
}

export type NotificationChannels = {
  [key in NotificationChannel]: boolean;
};

export interface NotificationsSelections {
  activity: Record<string, NotificationChannels>;
  criticalAlerts: Record<string, NotificationChannels>;
  patientStatusChanges: Record<string, NotificationChannels>;
}

export interface NotificationPreferences {
  id: string;
  userId: string;
  clientId: string;
  active: boolean;
  groupTypeApiName: string;
  notificationSelections: NotificationsSelections;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  groupType: GroupType;
  scopes: Partial<{
    episodes: any[];
    locations: any[];
    planTypes: any[];
    caseManagers: any[];
  }>;
}

export interface NotificationsOptions {
  data: NotificationPreferences[];
}

export class Notifications {
  data: NotificationPreferences[];

  constructor(options: NotificationsOptions) {
    this.data = options.data;
  }
}
