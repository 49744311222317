import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import Page from 'components/layouts/page/Page';
import FilterChips from 'components/shared/FilterChips';
import { ClearFilterButton } from 'components/shared/filters/FiltersFoldout';
import FiltersCountButton from 'components/shared/FiltersCountButton';
import Foldout from 'components/shared/Foldout';
import MobilePageHeader from 'components/shared/MobilePageHeader';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly';
import MobileOnly from 'components/shared/responsiveness/MobileOnly';
import Search from 'components/shared/Search';
import { useProfile } from 'context/profile';
import { useDebounce } from 'hooks/useDebounce';
import useIsMobile from 'hooks/useIsMobile';
import usePagination from 'hooks/usePagination';
import { Profile } from 'models';
import { indexAllPatients } from 'services/api/locationEpisode/allPatients';
import { usePatientFiltersActions, usePatientFiltersStore } from 'stores/patientFiltersStore';
import { colors } from 'styles/theme';
import { BodySmall } from 'styles/typography';
import FilterIconAlt from 'svg/FilterIconAlt';

import { ControlledTable } from '../shared/table';

import AllPatientsFilters from './AllPatientsFilters';
import allPatientsTableColumns from './allPatientsTableColumns';

// Order does matter here. This dictates the joins in the backend
// query and it was slower when ordered differently.
const INCLUDES = [
  'episode',
  'current_rehab_state',
  'hospital',
  'patient',
  'episode_classification',
  'plan_type_classification',
  'owner',
  'physician_team',
  'rehab_information',
  'progress_templates',
];

function AllPatients() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);
  const [filterFoldoutOpen, setFilterFoldoutOpen] = useState(false);
  const pagination = usePagination({ sortBy: 'patientName asc' });
  const patientCount = useRef(0);
  const filters = usePatientFiltersStore((state) => state.filters);
  const { removeFilter, setFilters } = usePatientFiltersActions();

  const queryParams = useMemo(
    () => ({
      ...pagination.filters,
      search: debouncedSearch,
      filters,
      include: INCLUDES.join(','),
    }),
    [debouncedSearch, filters, pagination.filters]
  );

  useEffect(() => {
    pagination.setPageIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, filters, pagination.filters.sortBy]);

  // Clear filters on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setFilters({}), []);

  const { data: patients, isLoading } = useQuery({
    queryKey: ['allPatients', { ...queryParams }],
    queryFn: ({ signal }) => indexAllPatients(queryParams, signal),
    select: (data) => {
      patientCount.current = data.meta.totalRecords;
      return data;
    },
  });

  const onSortChange = useCallback(
    (sortBy) => {
      const newSortBy = sortBy
        .map(({ id, desc }) => {
          let sortTerm = `${id} ${desc ? 'desc' : 'asc'}`;

          if (id === 'lengthOfStay') {
            sortTerm = 'trackLos desc,' + sortTerm;
          }

          return sortTerm;
        })
        .join(',');

      pagination.setSortBy(newSortBy);
    },
    [pagination]
  );

  const columns: any = useMemo(
    () =>
      allPatientsTableColumns({
        profile: profile ?? new Profile(),
        onClickPatient: (id: string) => navigate(`/patients/${id}`),
      }),
    [navigate, profile]
  );

  const handleRemoveFilter = useCallback(
    (filterKey: string, id: string) => {
      removeFilter(filterKey, id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filtersCount = Object.values(filters).reduce((acc, val) => acc + val.length, 0);

  return (
    <Page>
      <DesktopOnly>
        <StyledPageHeader>
          <ContentRow>
            <InnerContentRow>
              <Page.Title>All Patients</Page.Title>
              <SearchWrapper>
                <Search onChange={setSearch} value={search} placeholder={'Search Patients'} />
              </SearchWrapper>
              <FilterButton onClick={() => setFilterFoldoutOpen(true)}>
                <FilterIconAlt color={colors.primaryBlue} />
                <span>Filters {filtersCount ? '(' + filtersCount + ')' : ''}</span>
              </FilterButton>
              {filtersCount > 0 && (
                <ClearFilterButton onClick={() => setFilters({})}>
                  <span>Clear Filters</span>
                </ClearFilterButton>
              )}
            </InnerContentRow>
          </ContentRow>
        </StyledPageHeader>
      </DesktopOnly>
      <MobileOnly>
        <MobilePageHeader
          search={search}
          setSearch={setSearch}
          title='All Patients'
          actionElement={<FiltersCountButton filtersCount={filtersCount} onClick={() => setFilterFoldoutOpen(true)} />}
        />
      </MobileOnly>
      <StyledPageSubHeader>
        <DesktopOnly>
          <FilterChips filters={filters} onRemoveFilter={handleRemoveFilter} />
        </DesktopOnly>
        <BodySmall>{patientCount.current} patients found</BodySmall>
      </StyledPageSubHeader>
      <Page.Content>
        <ControlledTable
          data={patients?.data ?? []}
          filters={filters}
          columns={columns}
          loading={isLoading}
          onSortingChange={onSortChange}
          pageCount={patients?.meta.totalPages ?? 1}
          pagination={pagination}
          state={{
            columnVisibility: {
              ownerName: !isMobile,
              hospitalName: !isMobile,
              rehabFacility: !isMobile,
              physicianTeamName: !isMobile,
              planTypeClassification: !isMobile,
              episodeClassification: !isMobile,
              admittedOn: !isMobile,
              currentRehabState: !isMobile,
              lengthOfStay: !isMobile,
            },
          }}
        />
      </Page.Content>
      <Foldout open={filterFoldoutOpen} position='right'>
        {profile && <AllPatientsFilters profile={profile} closeModal={() => setFilterFoldoutOpen(false)} />}
      </Foldout>
    </Page>
  );
}

export default AllPatients;

const StyledPageHeader = styled(Page.Header)`
  padding: 24px;
`;

const StyledPageSubHeader = styled(Page.SubHeader)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.devices.desktop} {
    gap: 18px;
  }
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow-x: auto;
  flex-wrap: wrap;

  .icon {
    flex-shrink: 0;
  }
`;
const InnerContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  overflow-x: auto;
  flex: 1 1 0%;
`;

const SearchWrapper = styled.div`
  max-width: 200px;
`;

const FilterButton = styled.button`
  border: none;
  background: none;
  color: ${colors.primaryBlue};
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
`;
