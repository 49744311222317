import React from 'react';
import { addYears } from 'date-fns/addYears';

import { FormMeta, FormValues } from 'components/intake/types';
import FormSection from 'components/shared/form/FormSection';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import Input from 'components/shared/form/Input';
import InputGroup, { InlineInputGroups } from 'components/shared/form/InputGroup';
import SectionHeader from 'components/shared/form/SectionHeader';
import { useForm } from 'context/form';

export const GENDERS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Unknown', value: 'unknown' },
];

export type PatientInfoSectionProps = {
  disabled: boolean;
};

function PatientInfoSection({ disabled }: PatientInfoSectionProps) {
  const { values, errors, setValue } = useForm<FormValues, FormMeta>();

  return (
    <FormSection>
      <SectionHeader id='PatientInfo'>Patient Information</SectionHeader>
      <InputGroup title='Patient Name' error={errors.name}>
        <Input
          placeholder='Patient Name'
          name='name'
          value={values.name ?? ''}
          onChange={(event) => setValue('name', event.target.value)}
          $hasError={!!errors.name}
          disabled={disabled}
        />
      </InputGroup>
      <InlineInputGroups>
        <InputGroup error={errors.dateOfBirth?.month} title='Date of Birth'>
          <Input
            placeholder='MM'
            value={values.dateOfBirth?.month ?? ''}
            onChange={(event) => {
              setValue('dateOfBirth.month', event.target.value);
            }}
            $hasError={!!errors.dateOfBirth?.month}
            disabled={disabled}
            min='1'
            type='number'
            max='12'
          />
        </InputGroup>
        <InputGroup error={errors.dateOfBirth?.day}>
          <Input
            placeholder='DD'
            name='dateOfBirth.day'
            value={values.dateOfBirth?.day ?? ''}
            onChange={(event) => {
              setValue('dateOfBirth.day', event.target.value);
            }}
            $hasError={!!errors.dateOfBirth?.day}
            disabled={disabled}
            min='1'
            type='number'
            max='31'
          />
        </InputGroup>
        <InputGroup error={errors.dateOfBirth?.year}>
          <Input
            placeholder='YYYY'
            name='dateOfBirth.year'
            value={values.dateOfBirth?.year ?? ''}
            onChange={(event) => {
              setValue('dateOfBirth.year', event.target.value);
            }}
            $hasError={!!errors.dateOfBirth?.year}
            disabled={disabled}
            min='1900'
            type='number'
            max={addYears(new Date(), 2).toString()}
          />
        </InputGroup>
      </InlineInputGroups>
      <InputGroup title='Gender' error={errors.sex} data-cy='gender'>
        <Select
          inputId='gender-select'
          placeholder='Select Gender'
          name='sex'
          value={values.sex}
          onChange={(value) => setValue('sex', value)}
          hasError={!!errors.sex}
          options={GENDERS}
          disabled={disabled}
          getOptionValue={(prop) => prop.value}
          getOptionLabel={(prop) => prop.label}
        />
      </InputGroup>
    </FormSection>
  );
}

export default PatientInfoSection;
