import { useEffect } from 'react';
import styled from 'styled-components';

import { Toast, useToastActions } from 'stores/toastStore';

const Bread = ({ text, id }: Toast) => {
  const { removeToast } = useToastActions();

  useEffect(() => {
    const timeout = setTimeout(() => {
      removeToast(id);
    }, 7000);

    return () => clearTimeout(timeout);
    // id and removeToast not needed in deps array as they will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Crust onClick={() => removeToast(id)} key={id}>
      {text.split('\n').map((fragment, i) => (
        <Fragment key={i}>{fragment}</Fragment>
      ))}
    </Crust>
  );
};

export default Bread;

const Crust = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Fragment = styled.span`
  display: block;
`;
