import { useEffect } from 'react';
import styled from 'styled-components';

import ActivityInput from 'components/shared/activityInput/ActivityInput';
import { ActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import Checkbox from 'components/shared/form/Checkbox';
import FieldLabel from 'components/shared/form/FieldLabel';
import { LocationEpisode } from 'models';
import Profile from 'models/Profile';
import { colors } from 'styles/theme';
import { BodySmallBold, H3 } from 'styles/typography';
import InfoIconOutline from 'svg/InfoIconOutline';
import ThumbsDownIcon from 'svg/ThumbsDownIcon';
import ThumbsUpIcon from 'svg/ThumbsUpIcon';
import UnsureIcon from 'svg/UnsureIcon';

const QuestionContainer = styled.div<{ $isFirst: boolean }>`
  padding: 16px 0;
  ${({ $isFirst }) => !$isFirst && 'border-top: 1px solid var(--black-25);'}
`;
const StatusContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px 0 24px 0;
  height: 50px;
`;
const StatusButton = styled.div<{ $selected: boolean }>`
  flex: 1;
  display: flex;
  height: 100%;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  box-sizing: content-box;
  border-style: solid;
  border-color: ${({ $selected }) => ($selected ? 'var(--primary-blue)' : 'transparent')};
  background-color: ${({ $selected }) => ($selected ? 'var(--primary-blue-10)' : 'var(--black-05)')};
  &:hover {
    cursor: pointer;
    background-color: var(--primary-blue-10);
  }
`;
const DeclineMessage = styled.div`
  margin-top: 16px;
  background-color: var(--black-05);
  padding: 12px;
  gap: 16px;
  align-items: center;
  display: flex;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  margin-top: 24px;
`;

export function worseThan(b: string, a: string): boolean {
  return (
    ((
      {
        Yes: { Unsure: true, No: true },
        Unsure: { No: true },
      } as { [a: string]: { [b: string]: boolean } }
    )[a] ?? {})[b] ?? false
  );
}

type StatusQuestionProps = {
  value: string;
  setValue: (v: string) => void;
  text: string;
  index: number;
  profile: Profile;
  latestStatus: string;
  locationEpisode: LocationEpisode;
  setInadequateExplanation?: (b: boolean) => void;
  noteValues?: ActivityInputValues;
  setNoteValues?: React.Dispatch<React.SetStateAction<ActivityInputValues>>;
};

export default function StatusQuestion(props: StatusQuestionProps) {
  const {
    text,
    value,
    index,
    noteValues,
    setNoteValues,
    setValue,
    profile,
    latestStatus,
    locationEpisode,
    setInadequateExplanation,
  } = props;

  const statusWorse = worseThan(value, latestStatus);
  const userCanEscalate = profile.permissions.escalationCreate;

  useEffect(() => {
    const noteEmpty = !noteValues?.text && !noteValues?.attachments.length;
    setInadequateExplanation?.(noteEmpty && statusWorse);
  }, [setInadequateExplanation, noteValues, statusWorse]);

  return (
    <QuestionContainer $isFirst={index == 1}>
      <div>{text}</div>
      <StatusContainer>
        <StatusButton data-cy='clickable' onClick={() => setValue('Yes')} $selected={value == 'Yes'}>
          <ThumbsUpIcon color={colors.accentGreen} size={16} />
          <H3>Yes</H3>
        </StatusButton>
        <StatusButton data-cy='clickable' onClick={() => setValue('Unsure')} $selected={value == 'Unsure'}>
          <UnsureIcon color={colors.accentYellow} size={16} />
          <H3>Unsure</H3>
        </StatusButton>
        <StatusButton onClick={() => setValue('No')} $selected={value == 'No'} data-cy='clickable'>
          <ThumbsDownIcon color={colors.accentRed} size={16} />
          <H3>No</H3>
        </StatusButton>
      </StatusContainer>
      <FieldLabel>Please provide details regarding the patient&apos;s status</FieldLabel>
      <ActivityInput
        escalationType={noteValues?.escalationType}
        values={noteValues}
        setValues={setNoteValues || (() => {})}
        locationEpisodeId={locationEpisode.id}
      />
      {statusWorse && (
        <DeclineMessage>
          <InfoIconOutline />
          <BodySmallBold>A detailed description is required when there is a decline in status.</BodySmallBold>
        </DeclineMessage>
      )}
      {userCanEscalate && value == 'No' && (
        <CheckboxContainer data-cy='clickable'>
          <Checkbox
            checked={noteValues?.escalated || false}
            checkedColor={colors.accentRed}
            size={14}
            labelSize='16px'
            label={'Create an escalation for the patient'}
            onChange={() => setNoteValues?.((prev) => ({ ...prev, escalated: !prev.escalated }))}
          />
        </CheckboxContainer>
      )}
    </QuestionContainer>
  );
}
