import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function CalendarIcon2({ size = 24, ...props }: Props) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='calendar-icon-black100' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g fill='currentColor' fillRule='nonzero' id='Mask'>
          <path d='M22.5,3 L19.5,3 L19.5,0 L16.5,0 L16.5,3 L13.5,3 L13.5,0 L10.5,0 L10.5,3 L7.5,3 L7.5,0 L4.5,0 L4.5,3 L1.5,3 C0.6705,3 0,3.6705 0,4.5 L0,22.5 C0,23.3295 0.6705,24 1.5,24 L22.5,24 C23.3295,24 24,23.3295 24,22.5 L24,4.5 C24,3.6705 23.3295,3 22.5,3 Z M21,7.5 L21,21 L3,21 L3,7.5 L21,7.5 Z M9,15 L6,15 L6,18 L9,18 L9,15 Z M13.5,15 L10.5,15 L10.5,18 L13.5,18 L13.5,15 Z M18,15 L15,15 L15,18 L18,18 L18,15 Z M9,10.5 L6,10.5 L6,13.5 L9,13.5 L9,10.5 Z M13.5,10.5 L10.5,10.5 L10.5,13.5 L13.5,13.5 L13.5,10.5 Z M18,10.5 L15,10.5 L15,13.5 L18,13.5 L18,10.5 Z' />
        </g>
      </g>
    </svg>
  );
}
