import React from 'react';
import styled from 'styled-components';

import { createColumnHelper } from '@tanstack/react-table';

import { simpleDate } from 'lib/date';
import { LocationEpisode, Profile } from 'models';
import { ClientType } from 'models/Client';
import colors from 'styles/theme/colors';
import { BodySmall } from 'styles/typography';

type Props = {
  profile: Profile;
  onClickPatient: (id: string) => void;
};

const columnHelper = createColumnHelper<LocationEpisode>();

export default function allPatientsTableColumns({ profile, onClickPatient }: Props) {
  let ownerColumn = {} as ReturnType<typeof columnHelper.accessor>;

  switch (profile?.clientType) {
    case ClientType.PHYSICIAN_GROUP:
      ownerColumn = columnHelper.accessor((row) => row.owner.name, {
        id: 'ownerName',
        header: 'Physician Group',
        size: 100,
      });
      break;
    case ClientType.PAYOR:
      ownerColumn = columnHelper.accessor((row) => row.owner.name, {
        id: 'ownerName',
        header: 'Payer',
        size: 100,
      });
      break;
    default:
      ownerColumn = columnHelper.accessor((row) => row.hospital.name, {
        id: 'hospitalName',
        header: 'Hospital',
        size: 100,
      });
  }

  return [
    columnHelper.accessor((row) => row.patient.name, {
      id: 'patientName',
      header: 'Name',
      cell: (info) => (
        <Link
          fontWeight='700'
          onClick={() => onClickPatient(info.row.original.episodeId)}
          data-dd-action-name='Patient name'>
          {info.getValue()}
        </Link>
      ),
      size: 100,
    }),
    ownerColumn,
    columnHelper.accessor((row) => row.rehabInformation.latestRehabFacility.name, {
      id: 'rehabFacility',
      header: 'Post Acute',
      size: 100,
    }),
    columnHelper.accessor((row) => row.physicianTeam.name, {
      id: 'physicianTeamName',
      header: 'Physician',
      cell: (info) => info.getValue() || '—',
      size: 100,
    }),
    columnHelper.accessor((row) => row.planTypeClassification.name, {
      id: 'planTypeClassification',
      header: 'Plan Type',
      cell: (info) => info.getValue() || '—',
      size: 100,
    }),
    columnHelper.accessor((row) => row.episodeClassification.name, {
      id: 'episodeClassification',
      header: 'Episode Type',
      cell: (info) => info.getValue() || '—',
      size: 100,
    }),
    columnHelper.accessor((row) => row.admittedOn, {
      id: 'admittedOn',
      header: 'Hospital Admission',
      cell: (info) => simpleDate(info.getValue()) || '—',
      size: 100,
    }),
    columnHelper.accessor((row) => ({ archived: row.archived, currentRehabState: row.currentRehabState.state }), {
      id: 'currentRehabState',
      header: 'Stage',
      cell: (info) => (info.row.original.archived ? 'Canceled' : info.row.original.currentRehabState.state),
      size: 100,
    }),
    columnHelper.accessor((row) => row.lengthOfStay, {
      id: 'lengthOfStay',
      header: 'Days',
      cell: (info) => {
        const trackLos = true; //info.row.original.trackLos;

        return trackLos ? info.getValue() : '—';
      },
      size: 100,
    }),
  ];
}

const Link = styled(BodySmall)`
  cursor: pointer;
  color: ${() => colors.primaryBlue};
`;
