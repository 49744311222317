import { MenuPosition } from 'components/shared/menu/menu-content';

const marginFromContent = 4;

export function calculateMenuPosition(triggerRect: DOMRect, menuRect: DOMRect, position: MenuPosition) {
  const { left, top, height, width } = triggerRect;
  const { width: menuWidth } = menuRect;

  switch (position) {
    case 'left':
      return { x: left, y: top + height + marginFromContent };
    case 'right':
      return { x: left + width - menuWidth, y: top + height + marginFromContent };
    default:
      throw Error(`${position} must be implemented`);
  }
}
