import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function XIcon({ size = 12, ...props }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 12 12'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <polygon
          id='path-1'
          points='11.1508333 1.81633333 9.5 0.166666667 5.65933333 4.0085 1.8175 0.166666667 0.166666667 1.81633333 4.0085 5.65816667 0.166666667 9.5 1.8175 11.1496667 5.65933333 7.309 9.5 11.1496667 11.1508333 9.5 7.309 5.65816667'
        />
      </defs>
      <g id='remove-patient-via-edit' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='remove-button' transform='translate(-1031.000000, -95.000000)'>
          <g id='Group-13' transform='translate(1009.000000, 80.000000)'>
            <g id='Group-7-Copy-2' transform='translate(14.000000, 7.000000)'>
              <g id='Icon/Report' transform='translate(8.000000, 8.000000)'>
                <mask id='mask-2' fill='white'>
                  <use xlinkHref='#path-1' />
                </mask>
                <g id='Mask' />
                <g id='Group' mask='url(#mask-2)'>
                  <g transform='translate(-8.000000, -8.000000)' id='↳-🎨Color'>
                    <rect id='Rectangle' fill='currentColor' x='0' y='0' width='28' height='28' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
