import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { useEpisodeId } from 'hooks/useEpisodeId';
import { Profile } from 'models';
import { escalationsQueryKeys, EscalationType, indexEscalations, showEscalation } from 'services/api/escalation';
import { colors } from 'styles/theme';
import { H3, Label } from 'styles/typography';
import PriorityFlag from 'svg/PriorityFlag';
import WarnIcon from 'svg/WarnIcon';

import TaskModalBody from './TaskModalBody';
import TaskModalList from './TaskModalList';

type TaskModalType = {
  patientName: string;
  global: boolean;
  setShow: (b: boolean) => void;
  profile: Profile;
  taskCount: number;
};

export default function TaskModal(props: TaskModalType) {
  const { patientName, setShow, profile, global, taskCount } = props;
  const episodeId = useEpisodeId();
  const [showAllEscalations, setShowAllEscalations] = useState(false);
  const [selectedEscalationId, setSelectedEscalationId] = useState('');

  const queryParams = useMemo(
    () => ({
      episode: global || showAllEscalations ? undefined : episodeId,
      type: profile?.isAcute ? EscalationType.Default : EscalationType.Priority,
      acknowledged: false,
      sortBy: 'createdAt desc',
    }),
    [global, showAllEscalations, episodeId, profile?.isAcute]
  );

  const escalationsQuery = useInfiniteQuery({
    queryKey: escalationsQueryKeys.index(queryParams),
    queryFn: ({ pageParam, signal }) => indexEscalations({ ...queryParams, page: pageParam }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
  });

  const { data: selectedEscalation, isLoading: loadingSelectedEscalation } = useQuery({
    queryKey: escalationsQueryKeys.show(selectedEscalationId),
    queryFn: () => showEscalation({ id: selectedEscalationId, include: 'blob' }),
    enabled: !!selectedEscalationId,
  });

  useEffect(() => {
    if (selectedEscalationId) return;
    setSelectedEscalationId(escalationsQuery.data?.pages?.[0]?.data[0]?.id ?? '');
  }, [escalationsQuery.data, selectedEscalationId]);

  const escalationTypeLabel = profile.isAcute ? 'Escalations' : 'Priorities';

  return (
    <Backdrop>
      <StyledModal>
        <HeaderContainer>
          {profile.isAcute ? (
            <WarnIcon size={16} color={colors.accentRed} />
          ) : (
            <PriorityFlag size={16} color={colors.accentRed} />
          )}
          <H3>
            {!global && !showAllEscalations && patientName ? patientName + ' - ' : ''}
            {escalationTypeLabel}
            {taskCount ? ' (' + taskCount + ')' : ''}
          </H3>
          {!props.global && !showAllEscalations && taskCount > 0 && (
            <VisibilityFilter onClick={() => setShowAllEscalations(true)}>
              View all {escalationTypeLabel.toLowerCase()}
            </VisibilityFilter>
          )}
          <Spacer />
          <CloseButton onClick={() => setShow(false)}>&times;</CloseButton>
        </HeaderContainer>
        <ContentContainer data-cy='taskModalContentContainer'>
          <TaskModalList
            escalationsQuery={escalationsQuery}
            selectedEscalationId={selectedEscalationId}
            setSelectedEscalationId={setSelectedEscalationId}
            profile={profile}
          />
          <TaskModalBody
            escalation={selectedEscalation}
            loading={loadingSelectedEscalation || (escalationsQuery.isLoading && !escalationsQuery.isFetchingNextPage)}
            profile={profile}
            closeModal={() => setShow(false)}
          />
        </ContentContainer>
      </StyledModal>
    </Backdrop>
  );
}

const CloseButton = styled.div`
  color: var(--black-75);
  font-size: 24px;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Spacer = styled.div`
  flex: 1;
`;

const StyledModal = styled.div<{ overflow?: string }>`
  flex-grow: 1;
  z-index: 2;

  height: 600px;
  background-color: ${colors.white};
  flex-basis: 480px;
  max-width: 960px;
  border: 1px solid ${colors.black25};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  margin-top: 80px;

  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black25};
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Backdrop = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.black50};
  overflow: auto;
`;

const VisibilityFilter = styled(Label)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  margin-left: 16px;
  cursor: pointer;
`;
