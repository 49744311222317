import { getDefaults as getReviewDefaults, Review, ReviewOptions, ReviewType } from 'models/Review';

const defaults = {
  ...getReviewDefaults(),
  type: ReviewType.ALTCS_APPLICATION,
};

export default class AltcsApplication extends Review {
  constructor(options: Partial<ReviewOptions> = {}) {
    const opts = { ...defaults, ...options };

    super(opts);
  }

  static get type() {
    return ReviewType.ALTCS_APPLICATION;
  }
}
