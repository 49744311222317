import { camelCase } from 'lodash';

import { PortfolioTabFilter } from 'components/portfolio/Portfolio';
import { PatientState } from 'constants/filterKeysConstants';
import { FilterValue } from 'stores/portfolioStore';

export function applicableTabFilters(
  filters: { [key: string]: FilterValue[] },
  tab: PortfolioTabFilter
): { [key: string]: FilterValue[] } {
  if (!tab) return {};

  return Object.entries(filters).reduce((acc, [filterKey, values]) => {
    if (!values.length) return acc;

    if (values[0]?.type !== 'Location::RehabFacility') {
      acc[filterKey] = values;
      return acc;
    }

    const filterKeyIsCurrentProviderTab = filterKey === camelCase(tab?.value ?? '');

    switch (tab?.patientState) {
      case PatientState.CURRENT:
      case undefined:
        if (filterKeyIsCurrentProviderTab) {
          acc[filterKey] = values;
        }
        return acc;
      case PatientState.LATEST:
      case PatientState.CONTINUED:
        return acc;
      default:
        return acc;
    }
  }, {});
}
