import { useEffect, useState } from 'react';
import { GenericAbortSignal } from 'axios';

import { useInfiniteQuery } from '@tanstack/react-query';

import AttrValue, { AttrValueOptions } from 'models/AttrValue';
import { Paginated } from 'models/Paginated';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';

export const attrValuesQueryKeys = {
  index: ['attrValues'] as const,
};

type IndexParams = {
  active?: boolean;
  distinctDisplayName?: boolean;
  type?: string;
} & BaseIndexQueryParams;

export async function indexAttrValues(params: IndexParams, signal: GenericAbortSignal) {
  return http.get<Paginated<AttrValueOptions>>(`attr_values`, { params, signal }).then((res) => ({
    ...res.data,
    data: res.data.data.map((x) => new AttrValue(x)),
  }));
}

export const useInfiniteAttrValues = (params: IndexParams) => {
  const [search, setSearch] = useState<string>();

  const options = useInfiniteQuery({
    queryKey: [attrValuesQueryKeys.index, { ...params, search }],
    queryFn: ({ signal }) => indexAttrValues({ ...params, search }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    enabled: false,
  });

  useEffect(() => {
    if (search !== undefined) options.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return { options, onDebouncedSearch: setSearch };
};
