import { useCallback, useEffect, useMemo, useState } from 'react';

import { SortingState } from '@tanstack/react-table';

export const parseSortBy = (sortBy: string | string[]): SortingState => {
  if (Array.isArray(sortBy)) {
    return [{ id: sortBy[0], desc: sortBy[1] === 'desc' }];
  }

  return sortBy.split(',').map((sort) => {
    const [column, direction] = sort.trim().split(' ');

    return { id: column, desc: direction === 'desc' };
  });
};

type PaginationOptions = {
  page?: number;
  pageSize?: number;
  sortBy?: string;
};

export const usePagination = (defaultOptions: PaginationOptions = {}) => {
  const defaults = {
    page: 1,
    pageSize: 10,
    sortBy: '',
    ...defaultOptions,
  };

  const [filters, setFilters] = useState(defaults);
  const [pageIndex, setPageIndex] = useState(defaults.page - 1);
  const [pageSize, setPageSize] = useState(defaults.pageSize);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultSortBy = useMemo(() => parseSortBy(defaults.sortBy), []);

  const setSortBy = useCallback(
    (sortBy: SortingState) => {
      const newSortBy = sortBy.map((o) => `${o.id} ${o.desc ? 'desc' : 'asc'}`).join(',');

      setFilters({ ...filters, sortBy: newSortBy });
      setPageIndex(0);
    },
    [filters]
  );
  useEffect(() => {
    setPageIndex(0);
  }, [filters]);

  return {
    defaultSortBy,
    filters: { ...filters, page: pageIndex + 1, pageSize },
    setPageIndex,
    setPageSize,
    setSortBy,
  };
};

export default usePagination;
