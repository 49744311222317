import { useState } from 'react';
import styled from 'styled-components';

import { useQueryClient } from '@tanstack/react-query';

import AltcsApprovalModal from 'components/modals/altcs/AltcsApprovalModal';
import AltcsDenialModal from 'components/modals/altcs/AltcsDenialModal';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import { LocationEpisode } from 'models';
import AltcsApplication from 'models/reviews/AltcsApplication';
import { activityQueryKeys } from 'services/api/activity';
import { episodeQueryKeys } from 'services/api/episode';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';
import { portfolioQueryKeys } from 'services/api/portfolio';
import { BodySmall } from 'styles/typography';

type AltcsDecisionProps = {
  altcsApplication: AltcsApplication;
  locationEpisode: LocationEpisode;
  patientName: string;
};

export default function AltcsDecision({ altcsApplication, locationEpisode, patientName }: AltcsDecisionProps) {
  const queryClient = useQueryClient();
  const [showApprovedModal, setShowApprovedModal] = useState(false);
  const [showDenialModal, setShowDenialModal] = useState(false);

  const invalidateData = () => {
    queryClient.invalidateQueries({
      queryKey: episodeQueryKeys.show({ id: locationEpisode.episodeId }),
    });

    queryClient.invalidateQueries({
      queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }),
    });

    queryClient.invalidateQueries({
      queryKey: activityQueryKeys.base,
    });

    queryClient.invalidateQueries({
      queryKey: portfolioQueryKeys.index,
    });
  };

  return (
    <>
      <div>
        <Header>
          <BodySmall>
            Indicate below once a <strong>final determination</strong> for ALTCS has been made for the patient
          </BodySmall>
        </Header>
        <Actions>
          <ButtonGroup fullWidth>
            <StyledButton
              variant='outline-danger'
              fullWidth
              onClick={() => setShowDenialModal(true)}
              data-cy='altcsDenyButton'>
              Denied
            </StyledButton>
            <StyledButton
              variant='outline-primary'
              fullWidth
              onClick={() => setShowApprovedModal(true)}
              data-cy='altcsApproveButton'>
              Approved
            </StyledButton>
          </ButtonGroup>
        </Actions>
      </div>
      {showDenialModal && (
        <AltcsDenialModal
          setShow={setShowDenialModal}
          locationEpisode={locationEpisode}
          patientName={patientName}
          altcsApplication={altcsApplication}
          onSuccess={invalidateData}
        />
      )}
      {showApprovedModal && (
        <AltcsApprovalModal
          setShow={setShowApprovedModal}
          locationEpisode={locationEpisode}
          patientName={patientName}
          altcsApplication={altcsApplication}
          onSuccess={invalidateData}
        />
      )}
    </>
  );
}

const Header = styled.div`
  display: flex;
  padding: 24px 24px 0;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  border-width: 1px;
`;

const Actions = styled.div`
  padding: 0 24px 24px;
  display: flex;
`;
