import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { DischargeSteps } from 'components/dischargeForm/helpers';
import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput, { ActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import DatePicker from 'components/shared/form/DatePicker';
import FieldLabel from 'components/shared/form/FieldLabel';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import Input from 'components/shared/form/Input';
import InputGroup from 'components/shared/form/InputGroup';
import { useForm } from 'context/form';
import { LocationEpisode } from 'models';
import DischargeOption from 'models/DischargeOption';
import Location, { LocationOptions } from 'models/Location';
import { dischargeOptionsQueryKeys, indexDischargeOptions } from 'services/api/dischargeOptions';
import { useInfiniteLocations } from 'services/api/location';

type Props = {
  locationEpisode: LocationEpisode;
  showActualDischargeDate?: boolean;
};

export type DischargeFormValues = {
  dischargedReason: DischargeOption;
  dischargedLocation: Location;
  dischargedLocationOther: string;
  actualDischargeDate: Date;
  note: ActivityInputValues;
  questions: Record<string, string>;
  needs: string[];
};

export type DischargeFormMeta = {
  step: DischargeSteps;
  isSubmitting: boolean;
  isUploadingAttachments: boolean;
};

const readable_map: { [key: string]: string } = {
  hospital: 'Hospital',
  'assisted living': 'Assisted Living',
};

export default function DischargeInformation({ locationEpisode, showActualDischargeDate = false }: Props) {
  const { values, setValue, setMeta, errors } = useForm<DischargeFormValues, DischargeFormMeta>();
  const { note, setNote } = useActivityInput({ ...values.note });

  const { data: dischargeOptions } = useQuery({
    queryKey: dischargeOptionsQueryKeys.index(locationEpisode.episodeId),
    queryFn: ({ signal }) => indexDischargeOptions({ episodeId: locationEpisode.episodeId }, signal),
  });

  const asyncLocationOptions = useInfiniteLocations({
    ownerId: values.dischargedReason?.value !== 'hospital' ? locationEpisode.owner.id : undefined,
    type: ['hospital', 'hospice'].includes(values.dischargedReason?.value ?? '') ? values.dischargedReason!.value : '',
    groupType: values.dischargedReason?.groupTypeId ?? '',
    'id.not': locationEpisode.rehabInformation?.latestRehabFacility?.id,
    search: '',
    sortBy: 'name asc',
    viewOnly: true,
  });

  const locationLabel =
    values.dischargedReason?.displayName || readable_map[values.dischargedReason?.value ?? ''] || '';

  const isOtherLocation = values.dischargedLocation?.name === 'Other';

  const asyncLocationOptionsCondition = Boolean(values.dischargedReason?.groupTypeId) || Boolean(locationLabel);
  const enteredAt = locationEpisode.currentRehabState.enteredAt;

  useEffect(() => {
    setValue('note', note);
  }, [note, setValue]);

  return (
    <>
      {showActualDischargeDate && (
        <InputGroup title='Actual Discharge Date' error={errors.actualDischargeDate}>
          <DatePicker
            minDate={enteredAt ?? new Date()}
            maxDate={new Date()}
            selected={values.actualDischargeDate ?? null}
            onChange={(z) => {
              setValue('actualDischargeDate', z);
            }}
            $hasError={!!errors.actualDischargeDate}
          />
        </InputGroup>
      )}
      <InputGroup title={`Select discharge disposition`} error={errors.dischargedReason}>
        <Select<DischargeOption>
          placeholder='Select discharge disposition'
          options={dischargeOptions ?? []}
          onChange={(change) => {
            setValue('questions', null, { validate: false });
            setValue('needs', null, { validate: false });
            setValue('dischargedLocation', null, { validate: false });
            setValue('dischargedLocationOther', null, { validate: false });
            setValue('dischargedReason', change);
          }}
          getOptionValue={(prop) => prop.value}
          getOptionLabel={(prop) => prop.label}
          value={values.dischargedReason}
        />
      </InputGroup>
      <InputGroup title={locationLabel} visible={asyncLocationOptionsCondition} error={errors.dischargedLocation}>
        <Select
          {...asyncLocationOptions}
          appendedOptions={[{ name: 'Other' } as LocationOptions]}
          placeholder='Select a Location'
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.name}
          onChange={(change) => {
            setValue('questions', null, { validate: false });
            setValue('needs', null, { validate: false });
            setValue('dischargedLocationOther', null, { validate: false });
            setValue('dischargedLocation', change);
          }}
          value={values.dischargedLocation}
        />
      </InputGroup>
      <InputGroup title='Other' visible={isOtherLocation} error={errors.dischargedLocationOther}>
        <Input
          value={values.dischargedLocationOther ?? ''}
          onChange={(event) => setValue('dischargedLocationOther', event.target.value)}
          $hasError={false}
          disabled={false}
        />
      </InputGroup>
      <FieldLabel>Notes (optional)</FieldLabel>
      <ActivityInput
        showPostButton={false}
        showEscalate={false}
        locationEpisodeId={locationEpisode.id}
        values={note}
        setValues={setNote}
        onUploading={(uploading) => setMeta('isUploadingAttachments', uploading)}
      />
    </>
  );
}
