import { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { attachmentsQueryKeys, showAttachment } from 'services/api/attachments';

type DocumentLinkInput = {
  backgroundColor?: string;
  entry?: {
    id?: string;
  };
  children: Iterable<ReactNode> | ReactNode;
  disabled?: boolean;
};

export default function DocumentLink(props: DocumentLinkInput) {
  const { backgroundColor, entry = {}, children, disabled = false } = props;

  const [attachmentToFetch, setAttachmentToFetch] = useState<string>();
  const url = useRef<string>();

  const { data: attachment } = useQuery({
    queryKey: attachmentsQueryKeys.show(attachmentToFetch ?? ''),
    queryFn: () => showAttachment({ id: attachmentToFetch ?? '', include: 'url' }),
    enabled: !!attachmentToFetch,
  });

  const openAttachment = (id: string) => {
    if (url.current) {
      window.open(url.current, '_blank');
      return;
    }

    setAttachmentToFetch(id);
  };

  useEffect(() => {
    if (attachment?.url) {
      url.current = attachment.url ?? '';
      window.open(attachment.url, '_blank');
    }
  }, [attachment]);

  return (
    <Link
      backgroundColor={backgroundColor}
      onClick={() => openAttachment(entry?.id ?? '')}
      disabled={disabled}
      entry={entry}>
      {children}
    </Link>
  );
}

const Link = styled.a<{
  backgroundColor?: string;
  entry: {
    id?: string;
  };
  disabled: boolean;
}>`
  display: block;
  cursor: ${(props) => (props.entry.id ? 'pointer' : 'default')};
  cursor: ${(props) => props.disabled && 'not-allowed'};
  opacity: ${(props) => props.disabled && 0.25};
`;
