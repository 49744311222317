import styled from 'styled-components';

import Button from 'components/shared/Button';
import { colors } from 'styles/theme';
import FilterIcon from 'svg/FilterIcon';

export default function FiltersCountButton({
  filtersCount = 0,
  onClick,
}: {
  filtersCount: number;
  onClick: () => void;
}) {
  return (
    <Button variant='icon' onClick={onClick} data-cy='filterCountButton'>
      {!!filtersCount && <Circle>{filtersCount}</Circle>}
      <FilterIcon size={16} color={filtersCount ? colors.primaryBlue : colors.black} />
    </Button>
  );
}

export const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  background-color: ${colors.primaryBlue};
  color: ${colors.white};
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
