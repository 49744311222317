import React, { useState } from 'react';
import styled from 'styled-components';

import { Blurb } from 'components/Containers';
import ProjectedDischargeModal from 'components/modals/ProjectedDischargeModal';
import Tooltip from 'components/shared/Tooltip';
import { simpleDate } from 'lib/date';
import { Episode, LocationEpisode } from 'models';
import Profile from 'models/Profile';
import { colors } from 'styles/theme';
import { BodySmallBold, Label, LabelBold } from 'styles/typography';
import InfoIcon from 'svg/InfoIcon';
import PencilIcon from 'svg/PencilIcon';

type ProjectedDischargeType = {
  locationEpisode: LocationEpisode;
  episode: Episode;
  patientName: string;
  profile: Profile;
};

export function ProjectedDischarge(props: ProjectedDischargeType) {
  const { locationEpisode, episode, patientName, profile } = props;
  const projectedDischargeReview = locationEpisode.projectedDischargeReview;

  const isOwnerUser = profile.actingClientId === locationEpisode.owner.clientId;
  const showProjectedDischarge = !!projectedDischargeReview && (isOwnerUser || !projectedDischargeReview?.pending);
  const canEditProjectedDischarge = profile.permissions.reviewProjectedDischargeEdit && isOwnerUser;

  const date = projectedDischargeReview?.date;
  const [showModal, setShowModal] = useState(false);

  return !showProjectedDischarge ? (
    <></>
  ) : (
    <Blurb data-cy='projectedDischargeDate'>
      <DischargeHeader>
        <Label>Projected Discharge</Label>
        <Tooltip text='This date is provided by the risk-bearing entity' position='right'>
          <StyledInfoIcon />
        </Tooltip>
        <Spacer />
        {canEditProjectedDischarge && locationEpisode.latest && !episode.archived && (
          <ActionContainer data-cy='clickable' onClick={() => setShowModal(true)}>
            <PencilIcon size={12} color={colors.primaryBlue} />
            <StyledLabel>Update</StyledLabel>
          </ActionContainer>
        )}
      </DischargeHeader>
      <DischargeDate $isOverdue={locationEpisode.dischargeOverdue}>
        <BodySmallBold>{date ? simpleDate(date) : '—'}</BodySmallBold>
      </DischargeDate>
      {showModal && (
        <ProjectedDischargeModal
          review={projectedDischargeReview}
          setShow={setShowModal}
          patientName={patientName}
          locationEpisode={locationEpisode}
        />
      )}
    </Blurb>
  );
}

const DischargeHeader = styled.div`
  display: flex;
  padding: 24px 24px 8px;
  align-items: center;
`;

const StyledInfoIcon = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  font-size: 14px;
  margin-left: 8px;
  color: lightgray;
`;

const DischargeDate = styled.div<{ $isOverdue: boolean }>`
  color: ${(props) => props.$isOverdue && 'var(--accent-red)'};
  padding: 0 24px 24px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const StyledLabel = styled(LabelBold)`
  margin-left: 8px;
  cursor: pointer;
  color: var(--primary-blue);
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
