import React from 'react';

export default function PhysicianIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g
        id='info-sections-with-icons'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <g id='long-values-wrapping' transform='translate(-224.000000, -441.000000)' stroke='#3253EF'>
          <g id='circle-10' transform='translate(225.000000, 442.000000)'>
            <rect id='Rectangle' x='4' y='2.4' width='4' height='4.8' rx='2' />
            <path d='M10.3432,10.1312 C10.197191,9.16371855 9.73201639,8.27279085 9.0216,7.6' id='Path' />
            <path d='M2.98,7.6 C2.2685431,8.27265111 1.80249041,9.16392408 1.656,10.132' id='Path' />
            <circle id='Oval' cx='6' cy='6' r='6' />
          </g>
        </g>
      </g>
    </svg>
  );
}
