import { useState } from 'react';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import FiltersFoldout, { ClearFilterButton } from 'components/shared/filters/FiltersFoldout';
import { MultiSelect } from 'components/shared/form/InfiniteScrollDropdown';
import {
  HOSPITAL as HOSPITAL_FILTER,
  PAYER as PAYER_FILTER,
  PHYSICIAN_GROUP as PHYSICIAN_GROUP_FILTER,
} from 'constants/filterKeysConstants';
import { HOSPITAL, PAYER, PHYSICIAN_GROUP } from 'constants/locationTypes';
import { Profile } from 'models';
import { ClientType } from 'models/Client';
import Location from 'models/Location';
import { importedPatientsIndex } from 'services/api/importedPatients';
import { useInfiniteLocations } from 'services/api/location';
import { FilterState, usePatientFiltersActions, usePatientFiltersStore } from 'stores/patientFiltersStore';
import { colors } from 'styles/theme';
import { Label } from 'styles/typography';

type ConnectPatientsFiltersType = {
  profile: Profile;
  closeModal: () => void;
};

export default function ConnectPatientsFilters(props: ConnectPatientsFiltersType) {
  const filters = usePatientFiltersStore((state) => state.filters);
  const { setFilters } = usePatientFiltersActions();

  const { closeModal, profile } = props;

  const [tempFilters, setTempFilters] = useState<FilterState>(filters);

  const asyncHospitalOptions = useInfiniteLocations({
    sortBy: 'name asc',
    type: HOSPITAL,
  });

  const asyncPayerOptions = useInfiniteLocations({
    sortBy: 'name asc',
    type: PAYER,
  });

  const asyncPhysicianGroupOptions = useInfiniteLocations({
    sortBy: 'name asc',
    type: PHYSICIAN_GROUP,
  });

  const applyFilters = () => {
    setFilters(tempFilters);
    closeModal();
  };

  const handleClear = () => {
    setTempFilters({});
  };

  const handleChangeFilters = (key: string, value: any[]) => {
    setTempFilters((prev) => ({ ...prev, [key]: value }));
  };

  const { data } = useQuery({
    queryKey: ['importedPatients', 'count', { filters: tempFilters }],
    queryFn: ({ signal }) => importedPatientsIndex({ filters: tempFilters, page: 1, pageSize: 1 }, signal),
  });

  const tempFilterCount = Object.keys(tempFilters).reduce((acc, key) => acc + tempFilters[key].length, 0);
  const resultsCount = data?.meta.totalRecords;

  return (
    <FiltersFoldout closeModal={closeModal}>
      {{
        content: (
          <>
            <Section>
              <SectionTitle>Co-Management Team</SectionTitle>
              <DropdownContainer $visible={profile?.clientType === ClientType.HEALTH_SYSTEM} data-cy='hospitalFilter'>
                <MultiSelect
                  {...asyncHospitalOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Hospital'
                  value={tempFilters[HOSPITAL_FILTER] as Location[]}
                  onChange={(changes) => handleChangeFilters(HOSPITAL_FILTER, changes)}
                />
              </DropdownContainer>
              <DropdownContainer $visible={profile?.clientType === ClientType.PAYOR} data-cy='payerFilter'>
                <MultiSelect
                  {...asyncPayerOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Payer'
                  value={tempFilters[PAYER_FILTER] as Location[]}
                  onChange={(changes) => handleChangeFilters(PAYER_FILTER, changes)}
                />
              </DropdownContainer>
              <DropdownContainer
                $visible={profile?.clientType === ClientType.PHYSICIAN_GROUP}
                data-cy='physicianGroupFilter'>
                <MultiSelect
                  {...asyncPhysicianGroupOptions}
                  getOptionValue={(prop) => prop.id}
                  getOptionLabel={(prop) => prop.name}
                  placeholder='Physician Group'
                  value={tempFilters[PHYSICIAN_GROUP_FILTER] as Location[]}
                  onChange={(changes) => handleChangeFilters(PHYSICIAN_GROUP_FILTER, changes)}
                />
              </DropdownContainer>
            </Section>
          </>
        ),
        footer: (
          <>
            <Results>{resultsCount} patients found</Results>
            <ClearFilters>
              <ClearFilterButton disabled={!tempFilterCount} onClick={handleClear}>
                Clear Filters
              </ClearFilterButton>
              <ButtonGroup>
                <Button variant='ghost' onClick={closeModal}>
                  Cancel
                </Button>
                <Button onClick={applyFilters}>Apply</Button>
              </ButtonGroup>
            </ClearFilters>
          </>
        ),
      }}
    </FiltersFoldout>
  );
}

const DropdownContainer = styled.div<{ $visible?: boolean }>`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const Results = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.black10};
  font-size: 12px;
  font-weight: bold;
  color: var(--black);
`;

const ClearFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SectionTitle = styled(Label)`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.black};
  margin: 0;
`;
