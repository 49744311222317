import { useMutation, useQuery } from '@tanstack/react-query';

import AltcsApprovalForm, { ApprovalFormMeta, ApprovalFormValues } from 'components/modals/altcs/AltcsApprovalForm';
import altcsApprovalFormValidation from 'components/modals/altcs/altcsApprovalValidation';
import StandardModal, { PatientName } from 'components/shared/StandardModal';
import { LONG_TERM_CARE } from 'constants/locationTypes';
import { FormProvider } from 'context/form';
import { FormHelpers, FormMeta, FormValues } from 'context/form/types';
import { LocationEpisode } from 'models';
import { ReviewStatus } from 'models/Review';
import AltcsApplication from 'models/reviews/AltcsApplication';
import { indexLocations, locationsQueryKeys } from 'services/api/location';
import { updateReview } from 'services/api/reviews';
import { useToastActions } from 'stores/toastStore';

type Props = {
  setShow: (b: boolean) => void;
  onSuccess: () => void;
  patientName: string;
  locationEpisode: LocationEpisode;
  altcsApplication: AltcsApplication;
};

export default function AltcsApprovalModal(props: Props) {
  const { altcsApplication, setShow, patientName, locationEpisode, onSuccess } = props;
  const { addToast } = useToastActions();

  const { mutate } = useMutation({
    mutationFn: updateReview,
  });

  const cancel = () => {
    setShow(false);
  };

  const queryParams = {
    name: locationEpisode.rehabInformation.latestRehabFacility.name,
    ownerId: locationEpisode.owner?.id,
    type: LONG_TERM_CARE,
    viewOnly: true,
  };

  const { data: matchingLtcLocations } = useQuery({
    queryKey: [...locationsQueryKeys.index, queryParams],
    queryFn: ({ signal }) => indexLocations(queryParams, signal),
  });

  const handleSubmit = async (
    values: FormValues<ApprovalFormValues>,
    _meta: FormMeta<ApprovalFormMeta>,
    helpers: FormHelpers<ApprovalFormValues, ApprovalFormMeta>
  ) => {
    helpers.setMeta('isSubmitting', true);

    delete values.note?.escalationType;

    mutate(
      {
        id: altcsApplication.id,
        status: ReviewStatus.ACCEPTED,
        ltcGroupId: values.ltcGroup?.id,
        providerId: values.provider?.id,
        note: values.note
          ? {
              ...values.note,
              attachments: values.note.attachments?.map((upload) => upload.serialize()),
            }
          : undefined,
      },
      {
        onSuccess: () => {
          onSuccess();
          cancel();
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again' });
        },
        onSettled: () => {
          helpers.setMeta('isSubmitting', false);
        },
      }
    );
  };

  return (
    <StandardModal title='Confirm ALTCS Approval' onCancel={cancel} data-cy='altcsApprovalConfirmationModal'>
      <PatientName>Patient: {patientName}</PatientName>
      <FormProvider<ApprovalFormValues, ApprovalFormMeta>
        onSubmit={handleSubmit}
        resetOnInitialValuesChange
        initialValues={{ ltcGroup: matchingLtcLocations?.data?.[0] }}
        yupSchema={altcsApprovalFormValidation}>
        <AltcsApprovalForm locationEpisode={locationEpisode} altcsApplication={altcsApplication} onCancel={cancel} />
      </FormProvider>
    </StandardModal>
  );
}
