import styled from 'styled-components';

import { colors } from 'styles/theme';
import { H3 } from 'styles/typography';

type FilterFoldoutType = {
  children: {
    content: React.ReactNode;
    footer: React.ReactNode;
  };
  closeModal: () => void;
};

export default function FiltersFoldout(props: FilterFoldoutType) {
  const { closeModal } = props;
  const { content, footer } = props.children;

  return (
    <Container>
      <FilterFoldoutHeader>
        <H3>Filters</H3>
        <FilterFoldoutCloseIcon onClick={closeModal}>&times;</FilterFoldoutCloseIcon>
      </FilterFoldoutHeader>
      <Content>{content}</Content>
      <Footer>{footer}</Footer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const FilterFoldoutCloseIcon = styled.div`
  margin-left: auto;
  color: ${colors.black75};
  cursor: pointer;
  font-size: 24px;
`;
const FilterFoldoutHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${colors.black10};
  padding: 24px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
  padding: 24px;
  overflow-y: auto;
`;
const Footer = styled.div`
  margin-top: auto;
  border-top: 1px solid ${colors.black10};
`;

export const ClearFilterButton = styled.button`
  border: none;
  background: none;
  color: ${(buttonProps) => (buttonProps.disabled ? colors.accentRed25 : colors.accentRed)};
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: underline;
  &:hover {
    cursor: ${(buttonProps) => (buttonProps.disabled ? 'not-allowed' : 'pointer')};
  }
  &:focus {
    outline-color: ${() => colors.accentRed};
  }
`;
