import React, { createContext, useContext, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import MenuContent from 'components/shared/menu/menu-content';
import MenuItem from 'components/shared/menu/menu-item';
import MenuTrigger from 'components/shared/menu/menu-trigger';

export type MenuSize = 'default' | 'small';

type MenuContextType = {
  open: boolean;
  toggle: (value?: boolean) => void;
  triggerRef: React.RefObject<HTMLElement>;
  menuRef: React.RefObject<HTMLDivElement>;
  $size: MenuSize;
};

type MenuProps = {
  children?: React.ReactNode;
  $size?: MenuSize;
};

export const MenuContext = createContext<MenuContextType>({
  open: false,
  toggle: () => ({}),
  triggerRef: { current: null },
  menuRef: { current: null },
  $size: 'default',
});

export const useMenuContext = () => useContext(MenuContext);

function Menu({ children, $size = 'default' }: MenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggle = (value?: boolean) => {
    if (value !== undefined) {
      setIsOpen(value);
      return;
    }

    setIsOpen((prev) => !prev);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (!triggerRef.current || !menuRef.current) return;

    if (triggerRef.current.contains(event.target as Node) || menuRef.current.contains(event.target as Node)) return;

    toggle();
  };

  return (
    <MenuContext.Provider value={{ open: isOpen, toggle, triggerRef, menuRef, $size }}>
      <OutsideClickHandler disabled={!isOpen} onOutsideClick={handleOutsideClick}>
        {/* Future Improvement: is it possible to validate that only a Menu.Content and Menu.Trigger can be the children?  */}
        {children}
      </OutsideClickHandler>
    </MenuContext.Provider>
  );
}

Menu.Item = MenuItem;
Menu.Trigger = MenuTrigger;
Menu.Content = MenuContent;
export default Menu;
