import { useMemo } from 'react';

import { useQueries } from '@tanstack/react-query';

import { PortfolioTab, PortfolioTabFilter } from 'components/portfolio/Portfolio';
import { PatientState } from 'constants/filterKeysConstants';
import { useProfile } from 'context/profile';
import { getPatientCounts, portfolioQueryKeys } from 'services/api/portfolio';
import { usePortfolioStore } from 'stores/portfolioStore';

export default function usePortfolioTabs(debouncedSearch = '') {
  const { profile } = useProfile();
  const filters = usePortfolioStore((state) => state.filters);

  const tabsWithoutLabel = useMemo(() => {
    if (profile?.isAffiliateUser) {
      return [
        {
          value: PatientState.LATEST,
          displayName: PatientState.LATEST,
          patientState: PatientState.LATEST,
          locationType: undefined,
        },
      ];
    }

    const options: PortfolioTabFilter[] =
      profile?.enabledProviderTypes.map((x) => {
        return {
          value: x.apiName,
          displayName: x.displayName,
          groupType: x.id,
          locationType: x.apiName,
          patientState: profile?.isPostAcute ? PatientState.CURRENT : undefined,
        };
      }) ?? [];

    if (profile?.isPostAcute) {
      options.push({
        value: PatientState.CONTINUED,
        displayName: 'Continued Care',
        patientState: PatientState.CONTINUED,
        locationType: undefined,
      });
    }

    return options;
  }, [profile]);

  const tabCountQueryParams = useMemo(() => {
    return {
      search: debouncedSearch,
      filters: filters,
    };
  }, [filters, debouncedSearch]);

  const tabCounts = useQueries({
    queries: tabsWithoutLabel.map((tab) => {
      return {
        queryKey: [...portfolioQueryKeys.count(tab.value), profile?.actingClientId, tabCountQueryParams],
        queryFn: ({ signal }) => getPatientCounts({ tab, filters, search: debouncedSearch }, signal),
      };
    }),
    combine: (results) => {
      return results.map((result) => result.data?.data.meta.totalRecords ?? 0);
    },
  });

  const tabs: PortfolioTab[] = useMemo(() => {
    return tabsWithoutLabel.map((x, i) => {
      return {
        ...x,
        label: tabCounts?.[i] == undefined ? x.displayName : `${x.displayName} (${tabCounts[i]})`,
        count: tabCounts?.[i] ?? 0,
      };
    });
  }, [tabsWithoutLabel, tabCounts]);

  return tabs;
}
