import { capitalize } from 'lodash';
import styled from 'styled-components';

import { filterKeysToWords } from 'constants/filterKeysConstants';
import { humanize } from 'lib/util';
import { FilterValue } from 'stores/patientFiltersStore';
import { colors } from 'styles/theme';
import XIcon from 'svg/XIcon';

type Props = {
  filters: { [key: string]: FilterValue[] };
  onRemoveFilter: (filterKey: string, id: string) => void;
};

function getChipLabel(filterKey: string, value: FilterValue) {
  if (value.id === 'null') {
    return `No ${filterKeysToWords[filterKey] ?? capitalize(humanize(filterKey))} Assigned`;
  }

  return value.name;
}

export default function FilterChips(props: Props) {
  const { filters, onRemoveFilter } = props;

  const totalFilters = Object.values(filters).reduce((acc, values) => acc + values.length, 0);

  if (!totalFilters) {
    return <></>;
  }

  return (
    <ChipBag>
      {Object.entries(filters).map(([filterKey, values]) =>
        values.map((value) => (
          <Chip key={value.id}>
            {getChipLabel(filterKey, value)}
            <div onClick={() => onRemoveFilter(filterKey, value.id)}>
              <XIcon color={colors.black} size={8} />
            </div>
          </Chip>
        ))
      )}
    </ChipBag>
  );
}

const ChipBag = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const Chip = styled.div`
  background-color: var(--black-10);
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  padding: 4px 12px;

  font-size: 12px;
  font-weight: bold;
  color: var(--black);
  line-height: 16px;
  div {
    cursor: pointer;
  }
`;
