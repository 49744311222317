import Attr from './Attr';
import Classification from './Classification';
import Client from './Client';
import Location from './Location';

export interface AttrValueOptions {
  id: string;
  createdAt: string | null;
  createdBy: string | null;
  name: string;
  displayName: string | null;
  visible: boolean;
  client: Client | null;
  attr: Attr | null;
  active: boolean;
  associatedClassifications: Classification[];
  associatedGroups: Location[];
}

function getDefaults(): AttrValueOptions {
  return {
    id: '',
    createdAt: null,
    createdBy: null,
    name: '',
    displayName: '',
    visible: false,
    client: null,
    attr: null,
    active: true,
    associatedClassifications: [],
    associatedGroups: [],
  };
}

export default class AttrValue {
  id: string;
  createdAt: string | null;
  createdBy: string | null;
  name: string;
  displayName: string | null;
  visible: boolean;
  client: Client | null;
  attr: Attr | null;
  active: boolean;
  associatedClassifications: Classification[];
  associatedGroups: Location[];

  constructor(options: Partial<AttrValueOptions>) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.client = opts.client ? new Client(opts.client) : opts.client;
    this.attr = opts.attr ? new Attr(opts.attr) : opts.attr;
    this.createdAt = opts.createdAt;
    this.createdBy = opts.createdBy;
    this.name = opts.name;
    this.displayName = opts.displayName;
    this.visible = opts.visible;
    this.active = opts.active;
    this.associatedClassifications = opts.associatedClassifications;
    this.associatedGroups = opts.associatedGroups;
  }
}
