import styled from 'styled-components';

import useDeepEffect from 'hooks/useDeepEffect';

export type ToggleOption = { label: string; value: string };

export type ToggleGroupProps = {
  autoSelectFirstOption?: boolean;
  visible: boolean;
  options: ToggleOption[];
  disabled: boolean;
  selectedOption: ToggleOption;
  getOptionLabel: (option: ToggleOption) => string;
  getOptionValue: (option: ToggleOption) => string;
  onChange: (option: ToggleOption) => void;
};

function ToggleGroup({
  autoSelectFirstOption = false,
  visible,
  options,
  disabled,
  selectedOption,
  onChange,
  getOptionLabel,
  getOptionValue,
}: ToggleGroupProps) {
  const handleChange = (option: ToggleOption) => {
    if (!disabled) onChange(option);
  };

  useDeepEffect(() => {
    if (autoSelectFirstOption && !selectedOption.value && !!options.length && !disabled) {
      handleChange(options[0]);
    }
  }, [disabled, options, autoSelectFirstOption]);

  const renderButton = (option: ToggleOption) => {
    const optionValue = getOptionValue(option);
    const handleOnClick = () => handleChange(option);

    return (
      <Button
        key={optionValue}
        className={!disabled && option.value === selectedOption.value ? 'active' : ''}
        disabled={disabled}
        onClick={handleOnClick}>
        {getOptionLabel(option)}
      </Button>
    );
  };
  return <Container>{visible && options.map((option) => renderButton(option))}</Container>;
}

export default ToggleGroup;

const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
`;

const Button = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.black25 : theme.colors.black)};
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.black10 : theme.colors.white)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  font-weight: bold;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ disabled, theme }) => (disabled ? theme.colors.black10 : theme.colors.black25)};
  &:first-child {
    border-left: 1px solid ${({ disabled, theme }) => (disabled ? theme.colors.black10 : theme.colors.black25)};
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    border-color: ${({ theme }) => theme.colors.primaryBlue};
    color: ${({ theme }) => theme.colors.white};
  }
`;
