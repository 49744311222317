import { GENDERS } from 'components/intake/PatientInfoSection';
import { FormValues } from 'components/intake/types';
import { dateAsObj, dateFromObj, parseDate, simpleDate } from 'lib/date';
import { Episode, User } from 'models';
import AttrValue, { AttrValueOptions } from 'models/AttrValue';
import RehabFacility from 'models/RehabFacility';
import { UserOptions } from 'models/User';

import { RehabStateName } from './RehabState';

export const PATIENT = 'patient';

export interface PatientOptions {
  id: string;
  name: string;
  dateOfBirth: string | Date;
  sex: string;
  owner: any;
  hospital: any;
  rehabFacility: any;
  payer: any;
  payor: any;
  planTypeClassification: any;
  physicianGroup: any;
  physicianTeam: Partial<AttrValueOptions> | null;
  episodeClassification: any;
  episodeId: string;
  locationEpisodeId: string;
  admittedOn: null | Date | string;
  externalId: any;
  note: any;
  needs: any[];
  antHospitalDischarge: null | Date | string;
  medicare: any;
  currentRehabState: any;
  caseManager: Partial<UserOptions> | null;
}

function getDefaults(): PatientOptions {
  return {
    id: '',
    name: '',
    dateOfBirth: '',
    sex: '',
    owner: null,
    hospital: null,
    rehabFacility: null,
    payer: null,
    planTypeClassification: null,
    physicianGroup: null,
    physicianTeam: null,
    episodeClassification: null,
    episodeId: '',
    locationEpisodeId: '',
    admittedOn: null,
    externalId: null,
    note: null,
    needs: [],
    antHospitalDischarge: null,
    payor: null,
    medicare: null,
    currentRehabState: {},
    caseManager: null,
  };
}

/**
 * @class Patient
 * @classdesc Represents a patient in the system
 * @property {string} id - The patient's id
 * @property {string} name - The patient's name
 * @property {string | Date} dateOfBirth - The patient's date of birth
 * @property {string} sex - The patient's sex
 * @property {any} owner - The patient's owner
 * @property {any} hospital - The patient's hospital
 * @property {any} rehabFacility - The patient's rehab facility
 * @property {any} payer - The patient's payer
 * @property {any} planTypeClassification - The patient's plan type classification
 * @property {any} physicianGroup - The patient's physician group
 * @property {AttrValue | null} physicianTeam - The patient's physician team
 * @property {any} episodeClassification - The patient's episode classification
 * @property {string} episodeId - The patient's episode id
 * @property {string} locationEpisodeId - The patient's location episode id
 * @property {null | Date} admittedOn - The patient's admission date
 * @property {any} externalId - The patient's external id
 * @property {any} note - The patient's note
 * @property {any[]} needs - The patient's needs
 * @property {null | Date} antHospitalDischarge - The patient's ant hospital discharge date
 * @property {any} payor - The patient's payor
 * @property {any} medicare - The patient's medicare
 * @property {any} currentRehabState - The patient's current rehab state
 * @param {Partial<PatientOptions>} [options={}]
 * @example const patient = new Patient({ id: '123' });
 *
 */
export default class Patient {
  id: string;
  name: string;
  dateOfBirth: Date | string;
  sex: string;
  owner: any;
  hospital: any;
  rehabFacility: RehabFacility;
  payer: any;
  planTypeClassification: any;
  physicianGroup: any;
  physicianTeam: AttrValue | null;
  episodeClassification: any;
  episodeId: string;
  locationEpisodeId: string;
  admittedOn: null | Date;
  externalId: any;
  note: any;
  needs: any[];
  antHospitalDischarge: null | Date;
  payor: any;
  medicare: any;
  currentRehabState: any;
  caseManager: User | null;

  constructor(opts: Partial<PatientOptions> = {}) {
    const options = { ...getDefaults(), ...opts };
    this.id = options.id;
    this.name = options.name;
    this.dateOfBirth = parseDate(options.dateOfBirth) ?? '';
    this.sex = options.sex;
    this.owner = options.owner;
    this.hospital = options.hospital;
    this.rehabFacility = options.rehabFacility;
    this.physicianTeam = options.physicianTeam ? new AttrValue(options.physicianTeam) : null;
    this.physicianGroup = options.physicianGroup;
    this.payer = options.payer;
    this.locationEpisodeId = options.locationEpisodeId;
    this.episodeId = options.episodeId;
    this.admittedOn = parseDate(options.admittedOn);
    this.externalId = options.externalId;
    this.note = options.note;
    this.needs = options.needs;
    this.antHospitalDischarge = parseDate(options.antHospitalDischarge);
    this.payor = options.payor;
    this.medicare = typeof options.medicare === 'boolean' ? options.medicare : null;
    this.episodeClassification = options.episodeClassification;
    this.planTypeClassification = options.planTypeClassification;
    this.currentRehabState = options.currentRehabState;
    this.caseManager = options.caseManager ? new User(options.caseManager) : null;
  }

  static get type() {
    return PATIENT;
  }

  static fromEpisodeData(episode: Episode) {
    const patient = episode.patient;
    const rehabFacility = { ...episode.rehabInformation?.latestRehabFacility };

    rehabFacility.groupType = {
      ...rehabFacility.groupType,
      displayName: episode.rehabInformation?.latestRehabFacilityType,
    };

    if (!patient) return new Patient();

    return new Patient({
      ...patient,
      ...episode,
      rehabFacility: rehabFacility?.id ? rehabFacility : null,
      physicianTeam: episode.physicianTeam?.id ? episode.physicianTeam : null,
      physicianGroup: episode.physicianGroup?.id ? episode.physicianGroup : null,
      caseManager: episode.latestLocationEpisode?.caseManager || null,
      planTypeClassification: episode.latestLocationEpisode?.planTypeClassification || null,
      episodeClassification: episode.latestLocationEpisode?.episodeClassification || null,
    });
  }

  static fromFormValues(values: Partial<FormValues>) {
    return new Patient({
      ...values,
      sex: values.sex?.value,
      dateOfBirth: dateFromObj(values.dateOfBirth) ?? undefined,
    });
  }

  get discharged() {
    return this.currentRehabState.state === RehabStateName.Discharged;
  }

  intakeFormValues(): Partial<FormValues> {
    return {
      externalId: this.externalId,

      // Patient details
      name: this.name,
      dateOfBirth: dateAsObj(this.dateOfBirth),
      sex: GENDERS.find((g) => g.value === this.sex),

      // Episode information
      caseManager: this.caseManager ?? undefined,
      hospital: this.hospital,
      planTypeClassification: this.planTypeClassification,
      episodeClassification: this.episodeClassification ?? undefined,
      physicianTeam: this.physicianTeam ?? undefined,
      admittedOn: this.admittedOn ? this.admittedOn.toISOString() : undefined,
      antHospitalDischarge: this.antHospitalDischarge ? this.antHospitalDischarge.toISOString() : undefined,
      owner: this.owner,

      // Post acute information
      rehabFacility: this.rehabFacility,
      locationType: this.rehabFacility
        ? {
            label: this.rehabFacility?.groupType?.displayName ?? '',
            value: this.rehabFacility?.subType ?? this.rehabFacility?.locationType?.kind ?? '',
          }
        : undefined,
    };
  }

  formatNeedsNote() {
    if (!this.externalId) return null; // currently only applies to patients created via Connect
    const needsString = this.needs.length ? `Patient Needs:\n${this.needs.join('\n')}\n\n` : '';
    const noteString = this.note?.text ? `Note:\n${this.note.text}\n\n` : '';
    const dischargeString = this.antHospitalDischarge
      ? `Anticipated Hospital Discharge:\n${simpleDate(this.antHospitalDischarge)}`
      : '';

    return needsString + noteString + dischargeString;
  }

  serialize() {
    const note = this.formatNeedsNote();

    const values = {
      id: this.id,
      name: this.name,
      dateOfBirth: typeof this.dateOfBirth === 'string' ? this.dateOfBirth : this.dateOfBirth.toISOString(),
      sex: this.sex,
      admittedOn: this.admittedOn?.toISOString() || null,
      ownerId: this.owner?.id,
      hospitalId: this.hospital?.id,
      locationEpisodeId: this.locationEpisodeId,
      planTypeClassificationId: this.planTypeClassification?.id || null,
      physicianGroupId: this.physicianGroup?.id,
      rehabFacilityId: this.rehabFacility?.id,
      payerId: this.payer?.id,
      episodeClassificationId: this.episodeClassification?.id || null,
      externalId: this.externalId,
      antHospitalDischarge: this.antHospitalDischarge?.toISOString() || null,
      note: note?.length ? note : null,
      attachments: (this.note?.attachments ?? []).map((attachment) => attachment.serialize()),
      caseManagerId: this.caseManager?.id || null,
      attrValueIds: [] as string[],
    };

    if (this.owner?.locationType?.kind) {
      values[`${this.owner.locationType.kind}Id`] = this.owner.id;
    }

    const physicianTeamId = this.physicianTeam?.id;

    if (physicianTeamId) {
      values.attrValueIds.push(physicianTeamId);
    }

    return values;
  }
}
