import { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import DatePicker from 'components/shared/form/DatePicker';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { simpleDashDate } from 'lib/date';
import { LocationEpisode } from 'models';
import { Review, ReviewStatus } from 'models/Review';
import { activityQueryKeys } from 'services/api/activity';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';
import { updateReview } from 'services/api/reviews';

const FormContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export type DischargeModalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  review: Review;

  locationEpisode: LocationEpisode;
};
export default function ProjectedDischargeModal(props: DischargeModalType) {
  const { setShow, patientName, locationEpisode, review } = props;
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: updateReview,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }) });
      queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
      cancel();
    },
  });

  const projectedDischargeReview = locationEpisode.projectedDischargeReview;
  const date = projectedDischargeReview?.date;

  const [startDate, setStartDate] = useState<Date | null>(date ?? null);
  const [dateError, setDateError] = useState('');
  const convdate = simpleDashDate(startDate);

  const cancel = () => {
    setShow(false);
    setStartDate(date ?? null);
    setDateError('');
  };
  const submit = () => {
    if (!convdate) return setDateError('This field is required');

    mutate({
      id: review.id,
      projectedDischargeDate: convdate,
      status: ReviewStatus.ACCEPTED,
      include: 'activities',
    });
  };

  return (
    <StandardModal title='Update Projected Discharge' onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        <InputGroup title='Projected Discharge Date' error={dateError} $containerWidth='auto'>
          <DatePicker
            $hasError={!!dateError}
            minDate={new Date()}
            selected={startDate}
            onChange={(z) => {
              setDateError(z ? '' : 'This field is required');
              setStartDate(z);
            }}
          />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={!convdate} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}
