import { PaginatedItems } from 'models/Paginated';
import RehabState, { RehabStateOptions } from 'models/RehabState';
import http from 'services/http';

export const rehabStatesQueryKeys = {
  index: ['rehabStates'] as const,
};

export async function indexRehabStates() {
  return http.get<PaginatedItems<RehabStateOptions>>('rehab_states').then((res) => ({
    ...res.data,
    data: res.data.items
      .map((rehabState) => new RehabState(rehabState))
      .sort((a, b) => {
        return a.state < b.state ? -1 : 1;
      }),
  }));
}
