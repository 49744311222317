import { useState } from 'react';

export enum AddEditSteps {
  Information,
  Confirm,
}

export enum ConnectSteps {
  Information,
  Needs,
  Confirm,
}

// this will need to change to support 3 steps on the connect patient page
export default function useIntakeSteps(connecting = false) {
  const steps = connecting ? ConnectSteps : AddEditSteps;

  const [step, setStep] = useState<AddEditSteps | ConnectSteps>(steps.Information);

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const previousStep = () => {
    setStep((prev) => prev - 1);
  };

  return { step, nextStep, previousStep };
}
