import { GenericAbortSignal } from 'axios';

import { Attachment } from 'models';
import { AttachmentOptions } from 'models/Attachment';
import { Paginated } from 'models/Paginated';
import { BaseIndexQueryParams, BaseQueryParams } from 'services/api/types';
import http from 'services/http';

export const attachmentsQueryKeys = {
  index: (params: Record<string, any> = {}) => ['attachments', 'index', params] as const,
  count: ['attachments', 'count'] as const,
  show: (id: string) => ['attachments', id] as const,
};

type IndexParams = {
  locationEpisodeId: string;
  activityId?: string;
  docType?: string;
} & BaseIndexQueryParams;

function serializeIndexParams(params: Partial<IndexParams>) {
  if (params.docType === 'All') {
    delete params.docType;
  }

  if (params.docType === 'None') {
    params.docType = 'null';
  }
  return params;
}

export async function indexAttachments({ locationEpisodeId, ...params }: IndexParams, signal: GenericAbortSignal) {
  const serializedParams = serializeIndexParams(params);

  return http
    .get<
      Paginated<AttachmentOptions>
    >(`/location_episodes/${locationEpisodeId}/attachments`, { params: serializedParams, signal })
    .then((res) => ({
      ...res.data,
      data: res.data.data.map((x) => new Attachment(x)),
    }));
}

type ShowParams = {
  id: string;
} & BaseQueryParams;
export async function showAttachment({ id, ...params }: ShowParams) {
  return http.get<AttachmentOptions>(`attachments/${id}`, { params }).then((res) => new Attachment(res.data));
}

type UpdateParams = {
  id: string;
  docType: string | null;
};

export async function updateAttachment({ id, ...params }: UpdateParams) {
  return http.put<AttachmentOptions>(`attachments/${id}`, params).then((res) => new Attachment(res.data));
}
