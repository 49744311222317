import GroupType from './GroupType';
export type ClientGroupTypeConfig = {
  projectedDischarge: {
    enabled: boolean;
  };
  utilizationManagement: {
    enabled: boolean;
    planTypeClassification: string[];
  };
};
export interface ClientGroupTypeOptions {
  id: string;
  config: ClientGroupTypeConfig;
  groupType: GroupType;
}
function getDefaults(): ClientGroupTypeOptions {
  return {
    id: '',
    config: {
      projectedDischarge: {
        enabled: false,
      },
      utilizationManagement: {
        enabled: false,
        planTypeClassification: [],
      },
    },
    groupType: new GroupType(),
  };
}
/**
 * @class ClientGroupType
 * @classdesc Represents a client group type in the system
 * @property {string} id - The client group type's id
 * @property {GroupType} groupType - The client group type's group type
 * @property {ClientGroupTypeConfig} config - The client group type's configuration
 * @param {Partial<ClientGroupTypeOptions>} [options={}]
 */
export default class ClientGroupType {
  id: string;
  groupType: GroupType;
  config: ClientGroupTypeConfig;
  constructor(options = {}) {
    const opts = { ...getDefaults(), ...options };
    this.id = opts.id;
    this.config = opts.config;
    this.groupType = new GroupType(opts.groupType);
  }
}
