import { useMediaQuery } from 'react-responsive';

import { devices } from 'styles/theme';

/**
 * Determines if the screen width is <= 800px
 * @returns boolean
 */
export default function useIsMobile() {
  return !useMediaQuery({ query: devices.desktop });
}
