import colors from "./colors";

export const FONT_WEIGHT = 400;
export const FONT_WEIGHT_BOLD = 700;
export const FONT_WEIGHT_EXTRA_BOLD = 900;

export default {
  base: {
    color: colors.black,
    fontFamily: "Lato",
    fontWeight: FONT_WEIGHT,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: ".15px",
    display: "inline-block",
    margin: 0,
  },
  h1: {
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: "36px",
    lineHeight: "40px",
  },
  h2: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
  },
  h3: {
    fontWeight: FONT_WEIGHT_BOLD,
  },
  body: {},
  bodySmall: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  label: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  labelSmall: {
    fontSize: "10px",
    lineHeight: "14px",
  },
};
