import { useMemo, useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput from 'components/shared/activityInput/useActivityInput';
import Button from 'components/shared/Button';
import DatePicker from 'components/shared/form/DatePicker';
import Input from 'components/shared/form/Input';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { parseDate } from 'lib/date';
import { Escalation, LocationEpisode } from 'models';
import Profile from 'models/Profile';
import { ReviewStatus } from 'models/Review';
import { activityQueryKeys } from 'services/api/activity';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';
import { updateReview } from 'services/api/reviews';
import { colors } from 'styles/theme';
import { Label } from 'styles/typography';

export type UpdateAuthorizationModalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
  profile: Profile;
};

export default function UpdateAuthorizationModal(props: UpdateAuthorizationModalType) {
  const { setShow, patientName, locationEpisode, profile } = props;
  const queryClient = useQueryClient();

  const authReview = locationEpisode.activeAuthorizationReview!;

  const { mutate, isPending } = useMutation({
    mutationFn: updateReview,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }) });
      queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
      cancel();
    },
  });

  const escalationType = useMemo(() => {
    return Escalation.typeForProfile(profile ?? new Profile());
  }, [profile]);

  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput({ escalationType });

  const [authNumber, setAuthNumber] = useState(authReview.data.authorizationNumber ?? '');
  const [authError, setAuthError] = useState('');

  const parsedDate = parseDate(authReview.data.approvedThroughDate);

  const [startDate, setStartDate] = useState<Date | null>(parsedDate);
  const [dateError, setDateError] = useState('');

  const isAdmittedOrInTreatment = locationEpisode.inAdmission || locationEpisode.inTreatment;

  const submitDisabled = (isAdmittedOrInTreatment && !startDate) || !authNumber;

  const cancel = () => {
    resetNote();
    setStartDate(parsedDate);
    setShow(false);
  };

  const submit = () => {
    const noteValues = getSerializedNoteValues();

    mutate({
      id: authReview.id,
      status: ReviewStatus.ACCEPTED,
      include: 'activities.attachments',
      note: noteValues.attachments.length || !!noteValues.plaintext ? getSerializedNoteValues() : undefined,
      authorizationNumber: authNumber,
      approvedThroughDate: isAdmittedOrInTreatment ? startDate!.toISOString() : undefined,
    });
  };

  const remainingApprovedDays = startDate ? Math.max(0, differenceInCalendarDays(startDate, new Date())) : <>&mdash;</>;

  return (
    <StandardModal title='Update Authorization' onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        <InputGroup title='Authorization Number' error={authError}>
          <Input
            $hasError={!!authError}
            value={authNumber}
            placeholder={'Authorization Number'}
            onChange={(event) => {
              setAuthError(event.target.value ? '' : 'Authorization Number is a required field');
              setAuthNumber(event.target.value);
            }}
            disabled={false}
          />
        </InputGroup>
        {isAdmittedOrInTreatment && (
          <InputGroup title='Approved Through Date' error={dateError}>
            <DatePicker
              minDate={new Date()}
              $hasError={!!dateError}
              selected={startDate}
              onChange={(event) => {
                setDateError(event ? '' : 'Approved Through Date is a required field');
                setStartDate(event);
              }}
            />
            <ApprovedDaysText>Approved days remaining: {remainingApprovedDays}</ApprovedDaysText>
          </InputGroup>
        )}
        <InputGroup title='Notes (optional)'>
          <ActivityInput values={note} setValues={setNote} locationEpisodeId={locationEpisode.id} />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button data-cy='clickable' onClick={submit} disabled={submitDisabled} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  padding-bottom: 20px;
`;

const ApprovedDaysText = styled(Label)`
  color: ${colors.black75};
  font-style: italic;
  margin: 8px 0 20px;
`;
