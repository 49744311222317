import React, { MutableRefObject, ReactNode, useCallback, useState } from 'react';
import { Mention, MentionItem, MentionsInput, SuggestionDataItem } from 'react-mentions';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import { indexTaggableUsers } from 'services/api/locationEpisode/taggableUsers';
import { colors } from 'styles/theme';
import { BodySmall, BodySmallBold } from 'styles/typography';

function UserTagSuggestion(
  sug: SuggestionDataItem,
  _search: string,
  _highlightedDisplay: ReactNode,
  _index: number,
  focused: boolean
) {
  const { display } = sug;
  const userName = display!.split(' - ')[0];
  const locationName = display!.split(' - ')[1];
  return (
    <UserTagContainer $focused={focused}>
      <BodySmallBold>{userName}</BodySmallBold>
      <BodySmall>&nbsp;- {locationName}</BodySmall>
    </UserTagContainer>
  );
}

type TaggableTextAreaType = {
  disabled: boolean;
  inputRef: MutableRefObject<any>;
  locationEpisodeId?: string;
  placeholder?: string;
  values: { text: string; plaintext: string; mentions: MentionItem[] };
  onChange: (value: { text: string; plaintext: string; mentions: MentionItem[] }) => void;
};

export function TaggableTextArea(props: TaggableTextAreaType) {
  const { values, onChange, disabled, inputRef, locationEpisodeId, placeholder } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const [typedSearchQuery, setTypedSearchQuery] = useState('');

  useDebouncedEffect(() => setSearchQuery(typedSearchQuery), [typedSearchQuery], 250);

  const { data: taggableUsers } = useQuery({
    queryKey: ['taggableUsers', { locationEpisodeId: locationEpisodeId ?? '', search: searchQuery }],
    queryFn: ({ signal }) =>
      indexTaggableUsers({ locationEpisodeId: locationEpisodeId ?? '', search: searchQuery }, signal),
    select: (data) => data?.data,
  });

  const displayTransform = useCallback((_: any, display: any) => {
    const userName = display.split(' - ')[0];

    return `@${userName}`;
  }, []);

  const handleChange = (
    event: { target: { value: string } },
    text: string,
    plaintext: string,
    mentions: MentionItem[]
  ) => {
    onChange({ text, plaintext, mentions });
  };

  const text = values.text ?? '';

  return (
    <TextAreaWrapper>
      <TextArea
        disabled={disabled}
        value={text}
        placeholder={placeholder ?? 'Start typing here to leave a note...'}
        inputRef={inputRef}
        onSelect={(e: any) => {
          const s = (e.target as any).selectionStart;
          if (s != (e.target as any).selectionEnd || s == 0) return;
          let ps = s - 1;
          while (!'{}@-'.includes(text[ps]) && ps > 0) {
            ps--;
          }
          if (text[ps] == '@') {
            setTypedSearchQuery(text.slice(ps + 1, s).trim());
          }
        }}
        onChange={handleChange}>
        <Mention
          appendSpaceOnAdd
          trigger='@'
          data={
            taggableUsers?.data.map((x) => ({
              id: x.id,
              display: x.name + ' - ' + x.locationName,
            })) ?? []
          }
          markup='@{{__display__||__id__}}'
          displayTransform={displayTransform}
          renderSuggestion={UserTagSuggestion}
        />
      </TextArea>
    </TextAreaWrapper>
  );
}

const TextArea = styled(MentionsInput)`
  width: 100%;
  min-height: 100%;
  word-break: break-all;
  textarea {
    &::placeholder {
      color: ${colors.black25};
    }
    vertical-align: top;
    font-size: 14px !important;
    line-height: 24px !important;
    padding: 0;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    background-color: transparent;
    color: transparent;
    caret-color: ${colors.black};
  }
`;

const UserTagContainer = styled.div<{ $focused: boolean }>`
  display: flex;
  padding: 6px 7px;
  background-color: ${({ $focused }) => ($focused ? colors.primaryBlue10 : colors.white)};
`;

const TextAreaWrapper = styled.div`
  height: 100%;

  ${TextArea} {
    &__highlighter {
      font-size: 14px !important;
      line-height: 24px !important;

      border: none !important;
      outline: none;
    }

    &__highlighter strong {
      color: ${colors.primaryBlue};
      background-color: ${colors.primaryBlue10};
      border-radius: ${({ theme }) => theme.dimensions.borderRadius};
      font-weight: 700 !important;
    }

    &__highlighter__substring {
      visibility: inherit !important;
      color: ${colors.black};
    }

    &__suggestions {
      box-shadow: 0 8px 16px 0 ${colors.boxShadow};
      border: 1px solid ${colors.black05};
      border-radius: ${({ theme }) => theme.dimensions.borderRadius};
      padding: 8px 5px !important;
      max-height: 168px;
      overflow-y: auto;
    }
  }
`;
