import styled, { css } from 'styled-components';

import { BodySmallBold } from 'styles/typography';

const QuestionContainer = styled.div<{ $isFirst: boolean }>`
  padding: 16px 16px 16px 0;
  ${({ $isFirst }) => !$isFirst && 'border-top: 1px solid var(--black-25);'}
`;

const AnswerContainer = styled.div`
  display: flex;
  margin: 16px 0px 0px 24px;

  & > * {
    margin-right: 8px;
  }
`;

const Answer = styled.button<{ $selected: boolean }>`
  background-color: white;
  border: 2px solid rgba(15, 18, 38, 0.5);
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  ${({ $selected }) =>
    $selected &&
    css`
      background-color: rgb(50, 83, 239);
      border-color: rgb(50, 83, 239);
      color: white;
    `}
`;

const StyledQuestion = styled(BodySmallBold)`
  display: flex;
  gap: 8px;
`;

type RangeQuestionType = {
  text: string;
  max: number;
  min?: number;
  step?: number;
  value: string;
  setValue: (v: string) => void;
  index?: number;
};
export default function RangeQuestion(props: RangeQuestionType) {
  const { text, min = 1, max, step = 1, index = -1, value, setValue } = props;

  return (
    <QuestionContainer $isFirst={index == 1}>
      <StyledQuestion>
        {index >= 0 && <span>{index}.</span>}
        <span>{text}</span>
      </StyledQuestion>
      <AnswerContainer>
        {Array.from({ length: max }, (_, item) => {
          const rangeValue = (item + min) * step;
          return (
            <Answer
              type='button'
              key={item}
              value={rangeValue}
              $selected={value === String(rangeValue)}
              onClick={(event) => setValue((event.target as unknown as { value: string }).value)}>
              {rangeValue}
            </Answer>
          );
        })}
      </AnswerContainer>
    </QuestionContainer>
  );
}
