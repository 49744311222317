import { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { LocationEpisode } from 'models';
import User from 'models/User';
import { activityQueryKeys } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { locationEpisodeQueryKeys, updateLocationEpisode } from 'services/api/locationEpisodes';
import { useInfiniteUsers } from 'services/api/user';
import { useToastActions } from 'stores/toastStore';

export type CaseManagerModalProps = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
  caseManager: User | null;
};

export default function CaseManagerModal(props: CaseManagerModalProps) {
  const { setShow, patientName, locationEpisode } = props;
  const queryClient = useQueryClient();

  const [caseManager, setCaseManager] = useState<User | null>(props.caseManager);
  const { addToast } = useToastActions();

  const { mutate, isPending } = useMutation({
    mutationFn: updateLocationEpisode,
  });

  const asyncUserOptions = useInfiniteUsers({
    include: 'credential',
    sortBy: 'name',
    active: true,
    group: locationEpisode.owner.id,
  });

  const cancel = () => {
    setShow(false);
    setCaseManager(null);
  };

  const submit = async () => {
    mutate(
      {
        id: locationEpisode.id,
        caseManagerId: caseManager?.id || null,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }) });
          queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
          queryClient.invalidateQueries({ queryKey: attachmentsQueryKeys.count });
          cancel();
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again.' });
        },
      }
    );
  };

  return (
    <StandardModal title='Update Case Manager' onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        <InputGroup title='Select a case manager to assign:'>
          <Select
            {...asyncUserOptions}
            placeholder='Case Manager'
            getOptionLabel={(option) => option.fullName}
            getOptionValue={(option) => option.id}
            onChange={(change) => setCaseManager(change)}
            value={caseManager}
            isClearable
          />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;
