import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Page from 'components/layouts/page/Page.tsx';
import ButtonGroup from 'components/shared/ButtonGroup.tsx';
import { Form, FormProvider } from 'context/form/index.tsx';
import { FormHelpers, FormMeta, FormValues } from 'context/form/types.ts';
import { useProfile } from 'context/profile.tsx';
import isDeepEqual from 'lib/isDeepEqual.ts';
import { Flags } from 'models/Flag.ts';
import { NotificationPreferences as NotificationPreferencesModel } from 'models/Notificiations.ts';
import {
  createNotificationPreferences,
  fetchNotificationPreferences,
  notificationPreferencesQueryKeys,
  updateNotificationPreferences,
} from 'services/api/notificationPreferences.ts';
import { useToastActions } from 'stores/toastStore.ts';
import { H2 } from 'styles/typography.tsx';

import Button from '../shared/Button.tsx';
import Tabs, { TabType } from '../Tabs.tsx';

import NotificationPreferencesContainer from './NotificationPreferencesContainer.tsx';

export type PreferencesFormValues = {
  [groupTypeId: string]: NotificationPreferencesModel;
};

export type PreferencesFormMeta = {
  isSubmitting: boolean;
};

export default function NotificationPreferences() {
  const queryClient = useQueryClient();
  const { profile } = useProfile();
  const { addToast } = useToastActions();
  const [selectedTab, setSelectedTab] = useState<TabType>();

  const queryParams = useMemo(() => ({ id: profile?.id ?? '', include: 'groupType,scopes' }), [profile?.id]);

  const { data: notificationPreferences } = useQuery({
    queryKey: notificationPreferencesQueryKeys.index(queryParams),
    queryFn: () => fetchNotificationPreferences(queryParams),
  });

  const { mutate: updatePreferences } = useMutation({
    mutationFn: updateNotificationPreferences,
  });

  const { mutate: createPreferences } = useMutation({
    mutationFn: createNotificationPreferences,
  });

  const initialValues = useMemo(() => {
    return notificationPreferences?.reduce((acc, preference) => {
      acc[preference.groupType.id] = preference;
      return acc;
    }, {}) as PreferencesFormValues;
  }, [notificationPreferences]);

  const handleSubmit = async (
    values: FormValues<PreferencesFormValues>,
    meta: FormMeta<PreferencesFormMeta>,
    helpers: FormHelpers<PreferencesFormValues, PreferencesFormMeta>
  ) => {
    helpers.setMeta('isSubmitting', true);

    for (const key in values) {
      // ignore tabs that have not been changed
      if (isDeepEqual(values[key], initialValues[key])) {
        continue;
      }

      const prefs = {
        ...values[key],
        scopes: {
          locations: values[key]?.scopes.locations?.map((s) => s.id) ?? [],
          planTypes: values[key]?.scopes.planTypes?.map((s) => s.id) ?? [],
          episodes: values[key]?.scopes.episodes?.map((s) => s.id) ?? [],
          caseManagers: values[key]?.scopes.caseManagers?.map((s) => s.id) ?? [],
        },
      } as NotificationPreferencesModel;

      if (prefs.id) {
        updatePreferences({ ...prefs, urlExtension: `/${prefs.id}` });
      } else {
        createPreferences({ ...prefs, clientId: profile?.actingClientId ?? '' });
      }
    }

    // updates the cached values so that when the form resets,
    // the values read from the notificationPreferences useQuery are up to date
    queryClient.setQueryData<NotificationPreferencesModel[]>(
      notificationPreferencesQueryKeys.index(queryParams),
      Object.values(values) as NotificationPreferencesModel[]
    );

    helpers.resetForm();

    addToast({ text: 'Your changes have been saved!' });
  };

  const tabs = useMemo(() => {
    const vals =
      notificationPreferences?.map((preference) => ({
        label: preference.groupType.displayName,
        value: preference.groupType.id,
      })) ?? [];

    if (!selectedTab) setSelectedTab(vals[0]);
    return vals;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPreferences]);

  return (
    <FormProvider<PreferencesFormValues, PreferencesFormMeta>
      initialValues={initialValues}
      resetOnInitialValuesChange
      initialMeta={{ isSubmitting: false }}
      onSubmit={handleSubmit}>
      {({ dirty, resetForm, meta }) => (
        <Form>
          <Page>
            <Page.Header>
              <TopSection>
                <TitleSection>
                  <Page.Title>Notification Preferences</Page.Title>
                  <Subtitle>{profile?.actingClient?.name}</Subtitle>
                </TitleSection>
                <StyledButtonGroup>
                  <Button variant='ghost' data-cy='clickable' disabled={!dirty} onClick={() => resetForm()}>
                    Discard Changes
                  </Button>
                  <Button data-cy='clickable' disabled={!dirty} loading={meta.isSubmitting} type='submit'>
                    Save Changes
                  </Button>
                </StyledButtonGroup>
              </TopSection>
              <TabContainer>
                <Tabs
                  tabs={tabs}
                  onTabClick={setSelectedTab}
                  selectedTab={tabs.find((tab) => tab.value === selectedTab?.value) || tabs[0]}
                />
              </TabContainer>
            </Page.Header>
            <StyledPageContent>
              {selectedTab && (
                <NotificationPreferencesContainer
                  actingClientId={profile?.actingClientId || ''}
                  hasCaseManagerAssignmentFlag={!!profile?.hasFlag(Flags.CaseManagerAssignment)}
                  selectedGroupType={selectedTab}
                />
              )}
            </StyledPageContent>
          </Page>
        </Form>
      )}
    </FormProvider>
  );
}

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 24px;
`;

const TabContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black10};
  padding: 0 24px;
`;

const TitleSection = styled.div``;

const StyledButtonGroup = styled(ButtonGroup)`
  & > button {
    font-size: 16px;
    font-weight: 700;
  }
`;

const Subtitle = styled(H2)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-size: 16px;
  margin-top: 12px;
`;

const StyledPageContent = styled(Page.Content)`
  margin-top: 0px;
`;
