import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function LocationIcon({ size = 14, ...props }: Props) {
  return (
    <svg width={size} height={size} viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='location-icon' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <g id='pin-3' transform='translate(8.000000, 6.000000)' fill='currentColor' fillRule='nonzero'>
            <path d='M7.82608696,0 C4,0 0,2.95652174 0,7.82608696 C0,12.4347826 6.95652174,19.4782609 7.2173913,19.7391304 C7.39130435,19.9130435 7.56521739,20 7.82608696,20 C8.08695652,20 8.26086957,19.9130435 8.43478261,19.7391304 C8.69565217,19.4782609 15.6521739,12.4347826 15.6521739,7.82608696 C15.6521739,2.95652174 11.6521739,0 7.82608696,0 Z M7.82608696,10.4347826 C6.34782609,10.4347826 5.2173913,9.30434783 5.2173913,7.82608696 C5.2173913,6.34782609 6.34782609,5.2173913 7.82608696,5.2173913 C9.30434783,5.2173913 10.4347826,6.34782609 10.4347826,7.82608696 C10.4347826,9.30434783 9.30434783,10.4347826 7.82608696,10.4347826 Z' />
          </g>
        </g>
      </g>
    </svg>
  );
}
