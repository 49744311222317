import { MultiSelect } from 'components/shared/form/InfiniteScrollDropdown';
import GroupType from 'models/GroupType';
import Location from 'models/Location';
import { IndexParams, useInfiniteLocations } from 'services/api/location';

type Props = {
  provider: GroupType;
  value: Location[];
  onChange: (changes: Location[]) => void;
  queryParams: IndexParams;
};

export default function ProviderFilter({ provider, value, onChange, queryParams }: Props) {
  const asyncProviderOptions = useInfiniteLocations(queryParams);

  return (
    <MultiSelect
      {...asyncProviderOptions}
      getOptionValue={(prop) => prop.id}
      getOptionLabel={(prop) => prop.name}
      placeholder={provider.displayName}
      value={value}
      onChange={onChange}
    />
  );
}
