import { useMemo } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import AsyncModal from 'components/modals/AsyncModal';
import ConfirmEscalationModal from 'components/modals/ConfirmEscalationModal';
import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput from 'components/shared/activityInput/useActivityInput';
import { useProfile } from 'context/profile';
import { ActivityType, Escalation, LocationEpisode, Profile } from 'models';
import { EscalationTypes } from 'models/Escalation';
import { activityQueryKeys, createActivity } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { useToastActions } from 'stores/toastStore';

type Props = {
  locationEpisode: LocationEpisode;
};

export default function NewActivity({ locationEpisode }: Props) {
  const queryClient = useQueryClient();
  const { addToast } = useToastActions();
  const { profile } = useProfile();

  const { mutate, isPending: creatingActivity } = useMutation({
    mutationFn: createActivity,
  });

  const escalationType = useMemo(() => {
    return Escalation.typeForProfile(profile ?? new Profile());
  }, [profile]);

  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput({ escalationType });

  if (!profile) return null;

  const handleSubmit = async () => {
    if (note.escalated && escalationType === EscalationTypes.Escalation) {
      const confirmed = await new AsyncModal(ConfirmEscalationModal).render();
      if (!confirmed) return;
    }

    mutate(
      { ...getSerializedNoteValues(), type: ActivityType.NOTE, locationEpisodeId: locationEpisode?.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
          queryClient.invalidateQueries({ queryKey: attachmentsQueryKeys.count });
          resetNote();
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again.' });
        },
      }
    );
  };

  return (
    <ActivityInput
      escalationType={escalationType}
      taggable
      attachable
      showEscalate
      showPostButton
      submitting={creatingActivity}
      locationEpisodeId={locationEpisode?.id}
      onSubmit={handleSubmit}
      values={note}
      setValues={setNote}
      darkBorder={false}
      heightSettings={{ minHeight: '140px' }}
    />
  );
}
