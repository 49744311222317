export default {
  marginBigger: '48px',
  marginBig: '24px',
  marginMedium: '20px',
  marginSmallMedium: '16px',
  marginSmall: '12px',
  marginTiny: '5px',
  mainHeaderHeight: '48px',
  sideNavWidth: '200px',
  borderRadius: '4px',
};
