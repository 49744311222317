import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function DischargedIcon({ size = 24, ...props }: Props) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <defs>
        <path
          id='a'
          d='M21.636 10.364l-4.909 4.09v-3.272h-3.272v4.909a.818.818 0 0 1-.819.818H11V7.922c0-.788-.383-1.534-1.026-1.996L5.887 3h6.75c.451 0 .818.366.818.818v5.727h3.272V6.273l4.91 4.09zM8.546 21a.809.809 0 0 1-.476-.153l-5.727-4.09A.816.816 0 0 1 2 16.09V3.818C2 3.366 2.367 3 2.818 3c.318 0 .97.51 1.077.587l5.127 3.67a.821.821 0 0 1 .342.665v12.26a.82.82 0 0 1-.819.818z'
        />
      </defs>

      <g fill='none' fillRule='evenodd'>
        <mask id='b' fill={'#fff'}>
          <use xlinkHref='#a' />
        </mask>
        <g fill='currentColor' mask='url(#b)'>
          <path d='M0 0h24v24H0z' />
        </g>
      </g>
    </svg>
  );
}
