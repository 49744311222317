import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function UnsureIcon({ size = 16, ...props }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <g id='update-progress-(stoplight)' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Form' transform='translate(-496.000000, -287.000000)' fill='currentColor' fillRule='nonzero'>
          <g id='Group-18' transform='translate(290.000000, 108.000000)'>
            <g id='Group-31' transform='translate(52.000000, 132.000000)'>
              <g id='Group-33' transform='translate(0.500000, 35.000000)'>
                <path
                  d='M161.5,12 C157.1,12 153.5,15.6 153.5,20 C153.5,24.4 157.1,28 161.5,28 C165.9,28 169.5,24.4 169.5,20 C169.5,15.6 165.9,12 161.5,12 Z M161.5,25 C160.9,25 160.5,24.6 160.5,24 C160.5,23.4 160.9,23 161.5,23 C162.1,23 162.5,23.4 162.5,24 C162.5,24.6 162.1,25 161.5,25 Z M163,20.4 C162.5,20.7 162.5,20.8 162.5,21 L162.5,22 L160.5,22 L160.5,21 C160.5,19.7 161.3,19.1 161.9,18.7 C162.4,18.4 162.5,18.3 162.5,18 C162.5,17.4 162.1,17 161.5,17 C161.1,17 160.8,17.2 160.6,17.5 L160.1,18.4 L158.4,17.4 L158.9,16.5 C159.4,15.6 160.4,15 161.5,15 C163.2,15 164.5,16.3 164.5,18 C164.5,19.4 163.6,20 163,20.4 Z'
                  id='Unsure'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
