import UserPreference, {
  getDefaults as getUserPreferenceDefaults,
  UserPreferenceOptions,
  UserPreferenceType,
} from 'models/UserPreference';

export interface NotificationOptions extends UserPreferenceOptions {
  type: UserPreferenceType.NOTIFICATION;
  value: Record<string, any>;
}

export function getDefaults(): NotificationOptions {
  return {
    ...getUserPreferenceDefaults(),
    type: UserPreferenceType.NOTIFICATION,
    value: {},
  };
}

export default class Notification extends UserPreference {
  value: Record<string, any>;

  constructor(options: Partial<NotificationOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);

    this.value = opts.value;
  }
}
