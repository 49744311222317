import { Episode } from 'models';
import { EpisodeOptions } from 'models/Episode';
import JobProcess from 'models/JobProcess';
import { BaseQueryParams } from 'services/api/types';
import http from 'services/http';

export const episodeQueryKeys = {
  base: 'episodes',
  show: (params: Record<string, any> = {}) => ['episodes', 'show', params],
};

type ShowParams = {
  id: string;
} & BaseQueryParams;
export async function showEpisode({ id, ...params }: ShowParams) {
  return http.get<EpisodeOptions>(`/episodes/${id}`, { params }).then((res) => new Episode(res.data));
}

export async function deleteEpisode({ id }: { id: string }) {
  return http.delete<void>(`/episodes/${id}`);
}

export async function createEpisodeExport(id: string) {
  return http.post<JobProcess>(`/episodes/${id}/exports`).then((res) => new JobProcess(res.data));
}
