import { useEffect } from 'react';
import styled from 'styled-components';

import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';

import CircularProgress from 'components/shared/CircularProgress';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { colors } from 'styles/theme';

type MoreContentRequesterType = {
  resource: UseInfiniteQueryResult<InfiniteData<any, unknown>, Error>;
};

export default function MoreContentRequester({ resource }: MoreContentRequesterType) {
  const [intersecting, customRef] = useIntersectionObserver();

  useEffect(() => {
    if (!resource.isLoading && intersecting && resource.hasNextPage) {
      resource.fetchNextPage();
    }
  }, [intersecting, resource]);

  return (
    <>
      <MoreContentWidget ref={customRef}>
        {resource.isFetchingNextPage && <CircularProgress color={colors.primaryBlue} />}
      </MoreContentWidget>
    </>
  );
}

const MoreContentWidget = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
