import axios from 'axios';

import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const http = axios.create();

export const addResponseErrorInterceptor = (logout: () => any) => {
  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logout();
      } else {
        throw error;
      }
      return Promise.reject(error);
    }
  );
};

export const setBearerToken = (token: string) => {
  http.interceptors.request.use((request) => {
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  });
};

export const addBaseUrl = (baseUrl: string) => {
  http.defaults.baseURL = baseUrl;
};

http.defaults.headers.common.Accept = 'application/json';
http.defaults.headers.common['Content-Type'] = 'application/json';

export default http;
