import React from 'react';

import { SortingState, TableOptions } from '@tanstack/react-table';

import { usePagination } from 'hooks/usePagination';

import { Table } from '.';

type ControlledTableProps<T> = Partial<TableOptions<T>> &
  Required<Pick<TableOptions<T>, 'columns' | 'data'>> & {
    loading: boolean;
    filters: unknown;
    pageCount: number;
    pageSize?: number;
    onInitialize?: ({ setSorting }: { setSorting: React.Dispatch<React.SetStateAction<SortingState>> }) => void;
    pagination: ReturnType<typeof usePagination>;
  };

function ControlledTable<T>(props: ControlledTableProps<T>) {
  const { pageCount, pagination, state, ...tableProps } = props;

  return (
    <Table<T>
      initialState={{ pagination: { pageIndex: pagination.filters.page - 1 || 0 }, sorting: pagination.defaultSortBy }}
      state={{
        ...(state ? state : {}),
        pagination: { pageIndex: pagination.filters.page - 1 || 0, pageSize: pagination.filters.pageSize },
      }}
      onPageChange={pagination.setPageIndex}
      onSortChange={pagination.setSortBy}
      manualPagination
      manualSorting
      pageCount={pageCount}
      {...tableProps}
    />
  );
}

export default ControlledTable;
