import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Button from 'components/shared/Button';
import StandardModal, { Actions } from 'components/shared/StandardModal';
import { useAuth } from 'context/auth';
import { useEnvironment } from 'context/environment';

function SessionTimeoutModal({ onClose, showModal }: { onClose: () => void; showModal: boolean }) {
  const { environment } = useEnvironment();
  const { renewSession, logout } = useAuth();
  const [timeRemaining, setTimeRemaining] = useState('--:--');
  const [idleTime, setIdleTime] = useState<number>();
  const countdown = useRef<ReturnType<typeof setInterval>>();

  const reset = () => {
    clearInterval(countdown.current);
    countdown.current = undefined;
    setIdleTime(undefined);
    setTimeRemaining('--:--');
  };

  const handleLogout = useCallback(() => {
    reset();
    logout();
  }, [logout]);

  const handleRenewSession = async () => {
    try {
      await renewSession();
      reset();
      onClose();
    } catch (e) {
      handleLogout();
    }
  };

  const updateTimeRemaining = useCallback(() => {
    const remaining = (idleTime || 0) + (environment?.SESSION_LOGOUT_AFTER_IDLE || 0) - new Date().getTime();

    setTimeRemaining(
      new Date(remaining).toLocaleTimeString([], {
        minute: '2-digit',
        second: '2-digit',
      })
    );

    if (remaining <= 0) {
      handleLogout();
    }
  }, [environment?.SESSION_LOGOUT_AFTER_IDLE, handleLogout, idleTime]);

  useEffect(() => {
    if (!countdown.current && showModal) {
      setIdleTime(new Date().getTime());
    }
  }, [showModal]);

  useEffect(() => {
    if (idleTime) {
      countdown.current = setInterval(() => updateTimeRemaining(), 1000);
    }
  }, [idleTime, updateTimeRemaining]);

  useEffect(() => reset, []);

  return (
    showModal && (
      <StandardModal title={'Session Expiring Soon'} showCloseButton={false}>
        <Content>
          <Message>Your session is about to expire. You will be automatically logged out in</Message>
          <Countdown>{timeRemaining}</Countdown>
          <Message>
            To continue your session, select <b>Stay Signed In</b>
          </Message>
        </Content>
        <Actions>
          <Button variant='ghost' onClick={() => handleLogout()}>
            Logout
          </Button>
          <Button onClick={handleRenewSession}>Stay Signed In</Button>
        </Actions>
      </StandardModal>
    )
  );
}

export default SessionTimeoutModal;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 30px 0 50px;
  font-size: 16px;
  line-height: 1.5;

  & > * {
    margin-bottom: 8px;
  }
`;

const Message = styled.div`
  flex: 1;
`;

const Countdown = styled.div`
  color: rgb(50, 83, 239);
  font-size: 20px;
`;
