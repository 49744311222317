import * as yup from 'yup';

import { getOwnerText } from 'components/intake/EpisodeInfoSection';
import { PAYER, PHYSICIAN_GROUP } from 'constants/locationTypes';
import { ClientType } from 'models/Client';

export const clientTypesToRequireHospitalField = [PHYSICIAN_GROUP, PAYER];

export function intakeFormValidation(clientType: ClientType) {
  return yup.object().shape({
    name: yup.string().required('Patient name is required'),
    dateOfBirth: yup.object().shape({
      day: yup.string().typeError('Day Required').required('Day is required'),
      month: yup.string().typeError('Month Required').required('Month is required'),
      year: yup.string().typeError('Year Required').required('Year is required'),
    }),
    sex: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable()
      .test('sex', 'Gender is required', (sex) => !!sex?.value),
    physicianTeam: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test('physicianTeam', 'Physician is required', (obj) => Boolean(obj?.id)),
    owner: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test('owner', `${getOwnerText(clientType)} is required`, (obj) => Boolean(obj?.id)),
    hospital: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test('hospital', `Hospital is required`, (obj) =>
        clientTypesToRequireHospitalField.includes(clientType) ? Boolean(obj?.id) : true
      ),
    rehabFacility: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test('rehabFacility', 'Post Acute is required', (obj) => Boolean(obj?.id)),
  });
}
