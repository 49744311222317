import React from 'react';
import styled from 'styled-components';

import Page from 'components/layouts/page/Page';
import Button from 'components/shared/Button';
import ThinSearch from 'components/shared/ThinSearch';
import { useMobileNavigationActions } from 'stores/mobileNavigationStore';
import { colors, devices } from 'styles/theme';
import { H3 } from 'styles/typography';
import HamburgerMenuIcon from 'svg/HamburgerMenuIcon';

type Props = {
  search: string;
  setSearch: (search: string) => void;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  actionElement: React.ReactNode;
};

export default function MobilePageHeader({ className = '', search, setSearch, title, children, actionElement }: Props) {
  const { setNavigationOpen } = useMobileNavigationActions();

  const showSearchBar = typeof search === 'string' && !!setSearch;

  return (
    <StyledPageHeader className={className} data-cy='mobilePageHeader'>
      <Heading>
        <LeftAction>
          <Button variant='icon' onClick={() => setNavigationOpen(true)} data-cy='hamburgerIconButton'>
            <HamburgerMenuIcon size={16} color={colors.black} />
          </Button>
        </LeftAction>
        {React.isValidElement(title) ? title : <Title>{title}</Title>}
        <RightAction>{actionElement}</RightAction>
      </Heading>
      {showSearchBar && <ThinSearch onChange={setSearch} value={search} placeholder={'Search Patients'} />}
      {children}
    </StyledPageHeader>
  );
}

const LeftAction = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

const RightAction = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: flex-end;
`;

const StyledPageHeader = styled(Page.Header)`
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  flex-direction: column;

  @media ${devices.desktop} {
    flex-direction: row;
  }
`;

const Title = styled(H3)`
  font-weight: var(--font-weight-bold);
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
