import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function PencilIcon({ size = 24, ...props }: Props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size} height={size} {...props}>
      <g fill='currentColor'>
        <path
          data-color='color-2'
          d='M22.707,5.293l-4-4a1,1,0,0,0-1.414,0L14.5,4.086,19.914,9.5l2.793-2.793A1,1,0,0,0,22.707,5.293Z'
        />
        <path
          d='M13.086,5.5,3.293,15.293a1,1,0,0,0-.241.391l-2,6A1,1,0,0,0,2,23a.987.987,0,0,0,.316-.052l6-2a1,1,0,0,0,.391-.241L18.5,10.914Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
