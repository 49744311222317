import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function CircleCheckIcon({ size = 24, ...props }: Props) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <defs>
        <path
          d='M12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 C18.627417,24 24,18.627417 24,12 C23.9807773,5.38056166 18.6194383,0.0192227178 12,0 Z M10,17.414 L4.586,12 L6,10.586 L10,14.586 L18,6.586 L19.414,8 L10,17.414 Z'
          id='circle-check-path-1'
        />
      </defs>
      <g id='circle-check-icon-blue' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <mask id='mask-2' fill='#ffffff'>
            <use xlinkHref='#circle-check-path-1' />
          </mask>
          <use id='Mask' fill='currentColor' xlinkHref='#circle-check-path-1' />
        </g>
      </g>
    </svg>
  );
}
