import React from 'react';
import styled from 'styled-components';

import { BodySmall } from 'styles/typography';

function ParentLabel() {
  const parentLabel = '(Parent)';

  return <StyledBodySmall as='span'>&nbsp;{parentLabel}</StyledBodySmall>;
}

export default ParentLabel;

const StyledBodySmall = styled(BodySmall)`
  font-style: italic;
`;
