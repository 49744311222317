import React from 'react';
import styled from 'styled-components';

import { colors } from 'styles/theme';
import CircleXIcon from 'svg/CircleXIcon';
import SearchIcon from 'svg/SearchIcon';

import { InputStyles } from './form/Input';

type ThinSearchType = {
  onChange: (e: string) => void;
  placeholder: string;
  value: string;
};

export default function ThinSearch(props: ThinSearchType) {
  const { onChange, placeholder, value } = props;

  return (
    <SearchContainer>
      <SearchIconContainer>
        <SearchIcon size={12} color={colors.black25} />
      </SearchIconContainer>
      <Input
        $hasError={false}
        disabled={false}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      {value && (
        <ClearSearchContainer onClick={() => onChange('')}>
          <CircleXIcon size={15} color={colors.black50} />
        </ClearSearchContainer>
      )}
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
`;

const Input = styled.input<{ $hasError: boolean; disabled: boolean }>`
  ${InputStyles}
  padding: 8px 8px 8px 32px;
  width: 100%;
`;

const ClearSearchContainer = styled.div`
  position: absolute;
  right: 12px;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
