import styled from 'styled-components';

import { SectionContainer } from 'components/Containers';
import { Activity, LocationEpisode } from 'models';
import { colors } from 'styles/theme';
import { LabelBold } from 'styles/typography';
import ThumbsDownIcon from 'svg/ThumbsDownIcon';
import ThumbsUpIcon from 'svg/ThumbsUpIcon';
import UnsureIcon from 'svg/UnsureIcon';

import { NoteInnerContent } from './NoteInnerContent';

const StatusLabelBold = styled(LabelBold)<{ color: string }>`
  color: ${(props) => props.color};
  margin: 10px 6px;
`;

enum StatusResponse {
  YES = 'Yes',
  NO = 'No',
  UNSURE = 'Unsure',
}

export function StatusContent(props: { activity: Activity; locationEpisode: LocationEpisode }) {
  const { activity, locationEpisode } = props;
  const status = activity.data.questions[0].answer.text as StatusResponse;

  const STATUS_MAP = {
    [StatusResponse.YES]: {
      icon: ThumbsUpIcon,
      message: 'on track',
      color: colors.accentGreen,
      iconColor: undefined,
    },
    [StatusResponse.NO]: {
      icon: ThumbsDownIcon,
      message: 'off track',
      color: colors.accentRed,
      iconColor: undefined,
    },
    [StatusResponse.UNSURE]: {
      icon: UnsureIcon,
      message: 'unsure',
      color: colors.black,
      iconColor: colors.accentYellow,
    },
  };

  const Icon = STATUS_MAP[status].icon;
  const color = STATUS_MAP[status].color;
  const iconColor = STATUS_MAP[status].iconColor ?? color;

  return (
    <>
      <Icon size={12} color={iconColor} />
      <StatusLabelBold color={color}>
        {`Patient progress has been updated to ${STATUS_MAP[status].message}.`}
      </StatusLabelBold>
      <SectionContainer>
        <NoteInnerContent activity={activity} locationEpisode={locationEpisode} />
      </SectionContainer>
    </>
  );
}
