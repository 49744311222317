import React from 'react';
interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function CheckboxOffIcon({ size = 24, ...props }: Props) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='checkbox-off-icon-black100' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g fill='currentColor' id='Combined-Shape'>
          <path d='M21.6110458,0 C22.9304288,0 24,1.07785486 24,2.38895416 L24,21.6110458 C24,22.9304288 22.9221451,24 21.6110458,24 L2.38895416,24 C1.06957121,24 0,22.9221451 0,21.6110458 L0,2.38895416 C0,1.06957121 1.07785486,0 2.38895416,0 L21.6110458,0 Z M21.6,2.4 L2.4,2.4 L2.4,21.6 L21.6,21.6 L21.6,2.4 Z' />
        </g>
      </g>
    </svg>
  );
}
