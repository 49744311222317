import React from 'react';
import styled from 'styled-components';

import { Attachment } from 'models';
import { colors } from 'styles/theme';
import { BodySmall } from 'styles/typography';
import RemovedImageIcon from 'svg/RemovedImageIcon';

import LazyImage from './LazyImage';

type ThumbnailInput = {
  attachment: Attachment;
  className?: string;
  onClick?: () => void;
  removed: boolean;
};

export default function Thumbnail(props: ThumbnailInput) {
  const { attachment, className, onClick, removed } = props;

  return (
    <React.Fragment>
      {removed ? (
        <RemovedImage className={className}>
          <RemovedImageIcon />
          <StyledBodySmall>Image has been removed</StyledBodySmall>
        </RemovedImage>
      ) : (
        <LazyThumbnail
          className={className}
          customStyles={{
            maxHeight: '80px',
            maxWidth: '80px',
          }}
          src={attachment.thumbnailUrl}
          alt='attached image'
          onClick={onClick}
        />
      )}
    </React.Fragment>
  );
}

const RemovedImage = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBodySmall = styled(BodySmall)`
  margin-left: 6px;
  font-style: italic;
  color: ${colors.black50};
`;

const LazyThumbnail = styled(LazyImage)`
  cursor: pointer;
`;
