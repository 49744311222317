import React from 'react';

export default function AffiliateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='14px' height='13px' viewBox='0 0 14 13' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g
        id='short-term'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <g id='dispatch-discharge-visit-summary-copy' transform='translate(-224.000000, -441.000000)' stroke='#3253EF'>
          <g id='Group-20' transform='translate(207.000000, 194.000000)'>
            <g id='users-wm' transform='translate(18.000000, 248.000000)'>
              <path
                d='M9.6,11 L12,11 L12,8.37721519 C12,8.00349126 11.7503448,7.67769109 11.3944,7.58687618 L8.8,6.92608758 L8.5976,5.48066346 C9.45129621,5.08329906 9.99920167,4.21553579 10,3.25956641 L10,2.54744652 C10.0226359,1.34834339 9.21034918,0.299979975 8.06,0.0436199482 C7.63901613,-0.0379137735 7.20435569,-0.00474699694 6.8,0.139764281'
                id='Path'
              />
              <path
                d='M7.42,8.79438382 L5.2,8.14826131 L4.9976,6.70283718 C5.78071035,6.82919912 6.57991123,6.61454558 7.2,6.1113051 C6.60653043,5.28901975 6.32264412,4.27705777 6.4,3.25956641 C6.4,1.90959059 5.3254834,0.81521896 4,0.81521896 C2.6745166,0.81521896 1.6,1.90959059 1.6,3.25956641 C1.67735588,4.27705777 1.39346957,5.28901975 0.8,6.1113051 C1.42008877,6.61454558 2.21928965,6.82919912 3.0024,6.70283718 L2.8,8.14826131 L0.58,8.79438382 C0.23679655,8.8943635 0.000158583958,9.21382719 0,9.57738978 L0,11 L8,11 L8,9.57738978 C7.99984142,9.21382719 7.76320345,8.8943635 7.42,8.79438382 Z'
                id='Path'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
