import { NoteAttachment } from 'models/Notes';
import { PresignedResponse } from 'services/api/uploads';

enum UploadState {
  pending = 'pending',
  uploading = 'uploading',
  complete = 'complete',
  error = 'error',
}

export interface UploadData {
  file: File;
  docType?: string;
  id: string;
  signedId?: string;
  hasError?: boolean;
  status?: UploadState;
  progress?: number;
  error?: any;
}

export default class Upload {
  file: File;
  docType?: string;
  id: string;
  signedId?: string;
  hasError: boolean;
  status: UploadState;
  progress?: number;
  error?: any;

  constructor(props: UploadData) {
    this.file = props.file;
    this.docType = props.docType;
    this.id = props.id;
    this.signedId = props.signedId;
    this.hasError = props.hasError ?? false;
    this.status = props.status ?? UploadState.pending;
    this.progress = props.progress;
    this.error = props.error;
  }

  get filename() {
    return this.file.name;
  }

  start(presign: (f: File) => Promise<PresignedResponse>, update: (u: Upload) => void): Promise<void> {
    return presign(this.file).then((presigned_url: PresignedResponse) => {
      this.signedId = presigned_url.signedId;
      return new Promise((resolve, reject) => {
        // var formdata = new FormData();
        // formdata.append("file", this.file);
        const ajax = new XMLHttpRequest();

        ajax.upload.addEventListener(
          'progress',
          ({ loaded, total }) => {
            this.status = UploadState.uploading;
            this.progress = (loaded / total) * 100;
            update(this);
          },
          false
        );
        ajax.addEventListener(
          'readystatechange',
          () => {
            if (ajax.readyState == 4) {
              if (ajax.status >= 200 && ajax.status < 300) {
                this.status = UploadState.complete;
                this.progress = undefined;
                update(this);
                resolve();
              } else {
                alert('Error\n');
                this.status = UploadState.error;
                this.error = ajax.responseText;
                this.progress = undefined;
                update(this);
                reject();
              }
            }
          },
          false
        );
        ajax.addEventListener(
          'error',
          (err) => {
            this.status = UploadState.error;
            this.error = err;
            this.progress = undefined;
            update(this);
            reject();
          },
          false
        );
        ajax.open('PUT', presigned_url.direct_upload.url, true);
        for (const [k, v] of Object.entries(presigned_url.direct_upload.headers)) {
          ajax.setRequestHeader(k, v);
        }
        ajax.send(this.file.slice());
      });
    });
  }

  serialize(): NoteAttachment {
    return {
      id: this.id,
      signedId: this.signedId!,
      docType: this.docType,
      filename: this.filename,
    };
  }
}
