import React from 'react';
import styled from 'styled-components';

import OlioFooter from 'components/shared/OlioFooter';
import { H1 } from 'styles/typography';

type PageProps = {
  children: React.ReactNode;
};

const Page = ({ children }: PageProps) => {
  return (
    <PageWrapper>
      {children}
      <FooterContainer>
        <OlioFooter />
      </FooterContainer>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const PageHeader = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black10};
  margin-bottom: 24px;
`;

const PageSubHeader = styled.div`
  padding: 0 24px;
`;

const PageContent = styled.div`
  margin-top: 24px;
  padding: 0 24px;
`;

const FooterContainer = styled.div`
  margin-top: auto;
  width: 100%;
`;

const PageTitle = styled(H1)`
  display: none;
  font-size: 20px;

  @media (min-width: 640px) {
    display: block;
  }
`;

Page.Header = PageHeader;
Page.SubHeader = PageSubHeader;
Page.Content = PageContent;
Page.Title = PageTitle;

export default Page;
