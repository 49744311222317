import { UserPreferenceType } from 'models/UserPreference';
import { PortfolioFilterValue } from 'models/userPreferences/PortfolioFilter';
import http from 'services/http';

type UpsertParams = {
  value: PortfolioFilterValue;
  clientId: string;
};

export async function upsertPortfolioFilter({ clientId, ...params }: UpsertParams) {
  return http.put<void>(`me/preferences/portfolio_filter${clientId ? `/${clientId}` : ''}`, {
    ...params,
    type: UserPreferenceType.PORTFOLIO_FILTER,
  });
}
