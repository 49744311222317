import { useEffect, useMemo, useRef, useState } from 'react';
import { camelCase } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation, useQuery } from '@tanstack/react-query';

import { applicableTabFilters } from 'components/portfolio/helpers';
import usePortfolioTabs from 'components/portfolio/usePortfolioTabs';
import Button from 'components/shared/Button';
import FilterChips from 'components/shared/FilterChips';
import { ClearFilterButton } from 'components/shared/filters/FiltersFoldout';
import FiltersCountButton from 'components/shared/FiltersCountButton';
import Foldout from 'components/shared/Foldout';
import MobilePageHeader from 'components/shared/MobilePageHeader';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly';
import MobileOnly from 'components/shared/responsiveness/MobileOnly';
import { PatientState } from 'constants/filterKeysConstants';
import { useProfile } from 'context/profile';
import { useDebounce } from 'hooks/useDebounce';
import { capitalize } from 'lib/util';
import Location from 'models/Location';
import { RehabStateName } from 'models/RehabState';
import { useInfiniteLocations } from 'services/api/location';
import { indexPortfolio } from 'services/api/portfolio';
import { upsertPortfolioFilter } from 'services/api/preferences';
import { indexRehabStates, rehabStatesQueryKeys } from 'services/api/rehabStates';
import { usePortfolioActions, usePortfolioStore } from 'stores/portfolioStore';
import { colors } from 'styles/theme';
import { H2 } from 'styles/typography';
import FilterIconAlt from 'svg/FilterIconAlt';
import PlusIcon from 'svg/PlusIcon';

import { MultiSelect } from '../shared/form/InfiniteScrollDropdown';
import OlioFooter from '../shared/OlioFooter';
import Search from '../shared/Search';
import Tabs, { TabType } from '../Tabs';

import FilterFoldoutContent from './PortfolioFilters';
import { PortfolioLane } from './PortfolioLane';

export type PortfolioTabFilter = {
  value: string;
  displayName: string;
  locationType?: string;
  groupType?: string;
  patientState?: PatientState;
  count?: number;
};

export type PortfolioTab = PortfolioTabFilter & {
  value: string;
  label: string;
};

export default function PortfolioPage() {
  const { profile } = useProfile();
  const navigate = useNavigate();
  const [scrolledTab, setScrolledTab] = useState<TabType>();
  const [filterFoldoutOpen, setFilterFoldoutOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<PortfolioTab>();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsTab = searchParams.get('tab');

  const filters = usePortfolioStore((state) => state.filters);
  const persistedSearch = usePortfolioStore((state) => state.persistedSearch);
  const sorts = usePortfolioStore((state) => state.sorts);
  const { setPersistedSearch, setFilters, setFilter, removeFilter } = usePortfolioActions();

  const [search, setSearch] = useState(persistedSearch);
  const debouncedSearch = useDebounce(search);

  const tabs = usePortfolioTabs(debouncedSearch);
  const prevTab = useRef<string>();

  const lanesWrapperRef = useRef<HTMLDivElement>(null);

  const laneRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  const applicableFilters = useMemo(() => {
    if (!selectedTab) return {};

    return applicableTabFilters(filters, selectedTab);
  }, [filters, selectedTab]);

  const filtersCount = Object.values(applicableFilters).reduce((acc, val) => acc + val.length, 0);
  const enabledProviderFilterName = camelCase(selectedTab?.value ?? '');

  const selectedProviders = useMemo(
    () => applicableFilters[enabledProviderFilterName] ?? [],
    [applicableFilters, enabledProviderFilterName]
  );

  const currentTabFilter = useMemo(
    () => ({
      locationType: (selectedTab || tabs[0])?.locationType,
      patientState: (selectedTab || tabs[0])?.patientState,
    }),
    [selectedTab, tabs]
  );

  const portfolioParams = useMemo(() => {
    return {
      locationType: currentTabFilter.locationType,
      patientState: currentTabFilter.patientState,
      filters: applicableFilters,
      currentRehabState: 'All',
      active: true,
      search: debouncedSearch,
      sortBy: Object.values(sorts)
        .map((lane) => `${lane.key}.${lane.attributeName} ${lane.direction}`)
        .join(','),
    };

    // Do not include `sorts` in dependency array as when the initial sort changes, the PortfolioLane handles the query
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabFilter.locationType, currentTabFilter.patientState, applicableFilters, debouncedSearch]);

  const { data: portfolio, isLoading: loadingPortfolio } = useQuery({
    queryKey: ['portfolio', profile?.actingClientId, { ...portfolioParams }],
    queryFn: ({ signal }) => {
      return indexPortfolio(portfolioParams, signal);
    },
    enabled: !!profile,
  });

  const asyncProviderOptions = useInfiniteLocations({
    groupType: selectedTab?.groupType,
    sortBy: 'name asc',
    include: 'groupType',
  });

  const { data: rehabStates } = useQuery({
    queryKey: rehabStatesQueryKeys.index,
    queryFn: indexRehabStates,
  });

  const mobileTabs = useMemo(() => {
    return [
      { label: 'Queue', value: 'queue' } as TabType,
      { label: 'Admission', value: 'admission' } as TabType,
      { label: 'In Treatment', value: 'in-treatment' } as TabType,
      { label: 'Discharged', value: 'discharged' } as TabType,
    ].map((x) => {
      const laneCount = portfolio?.[x.label.toLowerCase()]?.meta?.totalRecords ?? 0;
      return {
        ...x,
        label: laneCount !== undefined ? `${x.label} (${laneCount})` : x.label,
      };
    });
  }, [portfolio]);

  const { mutate } = useMutation({
    mutationFn: upsertPortfolioFilter,
  });

  const handleChangeTab = (tab: PortfolioTab) => {
    setSelectedTab(tab);
    setSearchParams({ tab: tab.value });
  };

  const handleChangeProviders = async (changes: Location[]) => {
    setFilter(enabledProviderFilterName, changes);

    const updatedFilters = { ...filters };
    updatedFilters[enabledProviderFilterName] = changes;

    mutate({ clientId: profile?.actingClient.id as string, value: updatedFilters });
  };

  const handleClearFilters = async () => {
    setFilters({});
    mutate({ clientId: profile?.actingClient.id as string, value: {} });
  };

  const handleRemoveFilter = async (filterKey: string, id: string) => {
    removeFilter(filterKey, id);

    const updatedFilters = { ...filters };
    updatedFilters[filterKey] = updatedFilters[filterKey].filter((x) => x.id !== id);

    mutate({ clientId: profile?.actingClient.id as string, value: updatedFilters });
  };

  // Select a tab on initial load OR when the tab in the URL changes
  useEffect(() => {
    if ((!selectedTab && tabs[0]) || prevTab.current !== searchParamsTab) {
      const tabFromSearchParams = tabs.find((x) => x.value === searchParamsTab);

      handleChangeTab(tabFromSearchParams ?? tabs[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, tabs, searchParamsTab]);

  useEffect(() => {
    setPersistedSearch(debouncedSearch);
  }, [debouncedSearch, setPersistedSearch]);
  return (
    <Portfolio>
      <Foldout open={filterFoldoutOpen}>
        {profile && (
          <FilterFoldoutContent
            profile={profile}
            closeModal={() => setFilterFoldoutOpen(false)}
            actingClientType={profile?.actingClient?.clientType}
            enabledProviderTypeID={(selectedTab ?? tabs[0])?.groupType ?? ''}
            enabledProviderTypeName={(selectedTab ?? tabs[0])?.displayName}
            enabledProviderApiName={(selectedTab ?? tabs[0])?.value}
            tabFilter={currentTabFilter}
          />
        )}
      </Foldout>
      <MobileOnly>
        <StyledMobilePageHeader
          title={`${capitalize(selectedTab?.displayName ?? '')} Patients`}
          search={search}
          setSearch={setSearch}
          actionElement={<FiltersCountButton filtersCount={filtersCount} onClick={() => setFilterFoldoutOpen(true)} />}>
          <Tabs
            tabs={mobileTabs}
            onTabClick={(tab) => {
              const laneRef = laneRefs[mobileTabs.indexOf(tab)].current;
              if (!laneRef || !lanesWrapperRef.current) return;
              lanesWrapperRef.current.scrollTo({ left: laneRef?.offsetLeft, behavior: 'smooth' });
            }}
            selectedTab={scrolledTab ?? mobileTabs[0]}
            hideIfSingleTab
          />
        </StyledMobilePageHeader>
      </MobileOnly>
      <DesktopOnly>
        <DesktopPageHeader>
          <ContentRow>
            <InnerContentRow>
              <StyledPortfolioHeader>Patient Portfolio</StyledPortfolioHeader>
              <SearchContainer>
                <Search onChange={setSearch} value={search} placeholder={'Search Patients'} />
              </SearchContainer>
              {selectedTab?.locationType && (
                <FiltersContainer data-cy={`${camelCase(selectedTab.value)}Filter`}>
                  <MultiSelect
                    {...asyncProviderOptions}
                    getOptionValue={(prop) => prop.id}
                    getOptionLabel={(prop) => prop.name}
                    placeholder={selectedTab?.displayName}
                    onChange={handleChangeProviders}
                    value={selectedProviders}
                  />
                </FiltersContainer>
              )}
              <FilterButton onClick={() => setFilterFoldoutOpen(true)}>
                <FilterIconAlt color={colors.primaryBlue} />
                <span>Filters {filtersCount ? '(' + filtersCount + ')' : ''}</span>
              </FilterButton>
              {filtersCount > 0 && (
                <ClearFilterButton onClick={handleClearFilters}>
                  <span>Clear Filters</span>
                </ClearFilterButton>
              )}
            </InnerContentRow>
            {profile?.canCreatePatient && (
              <RightContent data-cy='rightContent'>
                <AddPatientButton onClick={() => navigate('/patients/new')}>
                  <PlusIcon color={colors.white} size={14} />
                  Add Patient
                </AddPatientButton>
              </RightContent>
            )}
          </ContentRow>
        </DesktopPageHeader>
        <TabFiltersWrapper>
          <Tabs
            tabs={(tabs ?? []) as TabType[]}
            onTabClick={handleChangeTab as (tab: TabType) => void}
            selectedTab={(selectedTab ?? tabs[0]) as TabType}
            hideIfSingleTab
          />
        </TabFiltersWrapper>
      </DesktopOnly>
      <DndProvider backend={HTML5Backend}>
        <BodyTable>
          <DesktopOnly>
            <FilterChips filters={applicableFilters} onRemoveFilter={handleRemoveFilter} />
          </DesktopOnly>
          <div className='responsive-container'>
            <SwimlaneColumns
              ref={lanesWrapperRef}
              onScroll={(x: any) => {
                const gapsize = 20;
                const scrollunit = (x.target.scrollWidth - gapsize * 5) / 4 + gapsize;
                const index = Math.round(x.target.scrollLeft / scrollunit);
                if (scrolledTab != mobileTabs[index]) setScrolledTab(mobileTabs[index]);
              }}>
              <PortfolioLane
                loadingPortfolio={loadingPortfolio}
                initialColumnData={portfolio?.queue}
                filters={applicableFilters}
                ref={laneRefs[0]}
                patientState={currentTabFilter.patientState}
                locationType={currentTabFilter.locationType}
                debouncedSearch={debouncedSearch}
                currentRehabState={RehabStateName.Queue}
                profile={profile}
                rehabStates={rehabStates?.data ?? []}
              />
              <PortfolioLane
                loadingPortfolio={loadingPortfolio}
                initialColumnData={portfolio?.admission}
                filters={applicableFilters}
                ref={laneRefs[1]}
                patientState={currentTabFilter.patientState}
                locationType={currentTabFilter.locationType}
                debouncedSearch={debouncedSearch}
                currentRehabState={RehabStateName.Admission}
                profile={profile}
                rehabStates={rehabStates?.data ?? []}
              />
              <PortfolioLane
                loadingPortfolio={loadingPortfolio}
                initialColumnData={portfolio?.['in treatment']}
                filters={applicableFilters}
                ref={laneRefs[2]}
                patientState={currentTabFilter.patientState}
                locationType={currentTabFilter.locationType}
                debouncedSearch={debouncedSearch}
                currentRehabState={RehabStateName.InTreatment}
                profile={profile}
                rehabStates={rehabStates?.data ?? []}
              />
              <PortfolioLane
                loadingPortfolio={loadingPortfolio}
                initialColumnData={portfolio?.discharged}
                filters={applicableFilters}
                ref={laneRefs[3]}
                patientState={currentTabFilter.patientState}
                locationType={currentTabFilter.locationType}
                debouncedSearch={debouncedSearch}
                currentRehabState={RehabStateName.Discharged}
                profile={profile}
                rehabStates={rehabStates?.data ?? []}
              />
            </SwimlaneColumns>
          </div>
          <OlioFooter />
        </BodyTable>
      </DndProvider>
    </Portfolio>
  );
}

const StyledMobilePageHeader = styled(MobilePageHeader)`
  padding-bottom: 0;
`;

const TabFiltersWrapper = styled.div`
  padding: 0 24px;
  background-color: white;
  border-bottom: 1px solid var(--black-10);
`;

const SearchContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 200px;
`;

const FiltersContainer = styled.div`
  flex: 1;
  max-width: 216px;
`;

const Portfolio = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DesktopPageHeader = styled.div`
  background-color: white;
  width: 100%;
  padding: 24px 24px 12px;

    @media ${({ theme }) => theme.devices.desktop} {
      padding-bottom: 24px;
    }
  }
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;

  .icon {
    flex-shrink: 0;
  }
`;

const InnerContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 1 0%;
`;

const RightContent = styled.div`
  > button > svg {
    &:first-child {
      margin-right: 6px;
    }
  }
`;

const StyledPortfolioHeader = styled(H2)`
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
`;

const BodyTable = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  padding: 0 24px 24px 24px;
  gap: 24px;

  @media ${({ theme }) => theme.devices.desktop} {
    min-height: auto;
    padding: 24px;
    overflow-y: auto;
  }

  .responsive-container {
    flex: 1;
    display: flex;
    justify-content: center;
    max-width: 1184px;
    max-height: 100%;
    min-height: 0;

    @media ${({ theme }) => theme.devices.desktop} {
      min-height: auto;
    }
  }
`;

const SwimlaneColumns = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  gap: 20px;

  @media ${({ theme }) => theme.devices.desktop} {
    gap: 10px;
  }
`;

const FilterButton = styled.button`
  border: none;
  background: none;
  color: ${() => colors.primaryBlue};
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
`;

const AddPatientButton = styled(Button)`
  font-size: 16px;
  line-height: 24px;
`;
