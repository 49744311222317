import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function ConnectPatientsIcon({ size = 12, ...props }: Props) {
  return (
    <svg width={size} height={size} viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='connect-patients-icon-white' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Connect---Empty-State'
          transform='translate(-14.000000, -155.000000)'
          fill='currentColor'
          fillRule='nonzero'>
          <g id='Group-2' transform='translate(14.000000, 153.000000)'>
            <g id='networking' transform='translate(0.000000, 2.000000)'>
              <path d='M6,0 C7.275,0 8.25,0.975 8.25,2.25 C8.25,3.225 7.65,4.05 6.75,4.35 L6.75,4.35 L6.75,6.375 L8.55,7.875 C8.925,7.65 9.3,7.5 9.75,7.5 C11.025,7.5 12,8.475 12,9.75 C12,11.025 11.025,12 9.75,12 C8.475,12 7.5,11.025 7.5,9.75 C7.5,9.45 7.575,9.225 7.65,9 L7.65,9 L6,7.725 L4.35,9 C4.425,9.225 4.5,9.525 4.5,9.75 C4.5,11.025 3.525,12 2.25,12 C0.975,12 0,11.025 0,9.75 C0,8.475 0.975,7.5 2.25,7.5 C2.7,7.5 3.075,7.65 3.45,7.8 L3.45,7.8 L5.25,6.375 L5.25,4.35 C4.35,4.05 3.75,3.225 3.75,2.25 C3.75,0.975 4.725,0 6,0 Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
