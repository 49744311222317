export interface LocationEpisodeSummaryOptions {
  id: string;
  archived: boolean;
  latest: boolean;
  hasActiveServiceRefusals: boolean | null;
  rehabInformation: any;
}

function getDefaults(): LocationEpisodeSummaryOptions {
  return {
    id: '',
    archived: false,
    latest: false,
    hasActiveServiceRefusals: false,
    rehabInformation: {
      eligibleForProgressUpdate: false,
      latestRehabFacility: {
        id: '',
        name: '',
      },
    },
  };
}
export default class LocationEpisodeSummary {
  id: string;
  archived: boolean;
  hasActiveServiceRefusals: boolean;
  latest: boolean;
  rehabInformation: any;

  constructor(options: Partial<LocationEpisodeSummaryOptions> = {}) {
    const opts = { ...getDefaults(), ...options };
    this.id = opts.id;
    this.archived = opts.archived;
    this.latest = opts.latest;
    this.hasActiveServiceRefusals = opts.hasActiveServiceRefusals ?? false;
    this.rehabInformation = opts.rehabInformation;
  }

  get stayDescriptor() {
    if (this.archived) return 'Canceled';
    return this.latest ? 'Current' : 'Past';
  }
}
