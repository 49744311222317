import React from 'react';
import styled from 'styled-components';

import { BodySmallBold } from 'styles/typography';

import Toggle from './Toggle';

type SectionToggleProps = {
  active: boolean;
  disabled: boolean;
  onChange: (active: boolean) => void;
  label: string;
};

export default function SectionToggle({ active, disabled, onChange, label }: SectionToggleProps) {
  return (
    <Container active={active} disabled={disabled}>
      <Toggle active={active} onChange={onChange} disabled={disabled} />
      <BodySmallBold data-cy='clickable'>{label}</BodySmallBold>
    </Container>
  );
}

const Container = styled.div<{ active: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  padding: 1.5rem;
  border: 1px solid ${({ active, theme }) => (active ? theme.colors.primaryBlue : theme.colors.black10)};
  background-color: ${({ active, theme }) => (active ? theme.colors.primaryBlue05 : theme.colors.white)};
  transition: 0.2s;
  cursor: ${({ disabled = true }) => (disabled ? 'not-allowed' : 'pointer')};
  gap: 1rem;
`;
