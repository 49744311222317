import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export default function DeactivatedUserIcon({ size = 80, ...props }: Props) {
  return (
    <svg width={size} height={size} viewBox='0 0 86 86' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='global-auth0-updates' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='inactive-or-deactivated-user' transform='translate(-677.000000, -258.000000)' strokeWidth='5'>
          <g id='a-remove' transform='translate(680.000000, 261.000000)'>
            <path
              d='M36.3636364,43.6363636 L36.3636364,43.6363636 C26.3218182,43.6363636 18.1818182,31.86 18.1818182,21.8181818 L18.1818182,18.1818182 C18.1818182,8.14 26.3218182,0 36.3636364,0 L36.3636364,0 C46.4054545,0 54.5454545,8.14 54.5454545,18.1818182 L54.5454545,21.8181818 C54.5454545,31.86 46.4054545,43.6363636 36.3636364,43.6363636 Z'
              id='Path'
              stroke='#3253EF'
              strokeLinecap='square'
            />
            <path
              d='M50.5836364,53.7654545 C46.3181818,53.14 41.5472727,52.7272727 36.3636364,52.7272727 C24.9309091,52.7272727 15.4963636,54.7236364 9.10363636,56.6254545 C3.69818182,58.2327273 0,63.1909091 0,68.8290909 L0,76.3636364 L40,76.3636364'
              id='Path'
              stroke='#3253EF'
            />
            <circle id='Oval' stroke='#D10D44' strokeLinecap='square' cx='63.6363636' cy='63.6363636' r='16.3636364' />
            <line
              x1='58.1818182'
              y1='63.6363636'
              x2='69.0909091'
              y2='63.6363636'
              id='Path'
              stroke='#D10D44'
              strokeLinecap='square'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
