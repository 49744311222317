import React, { useEffect, useState } from 'react';
import { noop } from 'lodash';
import Icon from 'react-fontawesome';
import styled from 'styled-components';

import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';

import { TablePaginatorProps } from './TablePaginator';
import { EmptyRow, LoadingOverlay, TablePaginator } from '.';

type TableProps<T> = Partial<TableOptions<T>> &
  Required<Pick<TableOptions<T>, 'columns' | 'data'>> & {
    paginated?: boolean;
    loading?: boolean;
    onSortChange?: (sorting: SortingState) => void;
    onPageChange?: (pageIndex: number) => void;
    onInitialize?: ({ setSorting }: { setSorting: React.Dispatch<React.SetStateAction<SortingState>> }) => void;
  };

export default function Table<T>({
  paginated = true,
  loading = false,
  onSortChange,
  onPageChange,
  onInitialize,
  ...reactTableProps
}: TableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>(reactTableProps.initialState?.sorting || []);

  const table = useReactTable({
    ...reactTableProps,
    state: {
      ...reactTableProps.state,
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const paginatorProps: TablePaginatorProps = {
    canNextPage: table.getCanNextPage(),
    canPreviousPage: table.getCanPreviousPage(),
    gotoPage: table.setPageIndex,
    nextPage: table.nextPage,
    onPageChange: onPageChange || noop,
    previousPage: table.previousPage,
    pageCount: reactTableProps.pageCount || 0,
    pageIndex: table.getState().pagination.pageIndex,
  };

  const handlePageChange = () => {
    onPageChange?.(table.getState().pagination.pageIndex);
  };

  const handleSortChange = () => {
    onSortChange?.(table.getState().sorting);
  };

  useEffect(() => {
    onInitialize?.({ setSorting });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handlePageChange, [table.getState().pagination.pageIndex]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleSortChange, [table.getState().sorting]);

  return (
    <React.Fragment>
      <StyledTable>
        <StyledTableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <StyledTableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <StyledTableHeaderCell
                  data-cy={`${header.column.columnDef.header?.toString()?.toLowerCase()}-header-column`}
                  width={header.column.getSize()}
                  key={header.id}
                  isSorted={!!header.column.getIsSorted()}
                  onClick={header.column.getToggleSortingHandler()}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {{
                    asc: <StyledIcon name={'caret-up'} size={'lg'} />,
                    desc: <StyledIcon name={'caret-down'} size={'lg'} />,
                  }[header.column.getIsSorted() as string] ?? null}
                </StyledTableHeaderCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTableHeader>
        <StyledTableBody>
          {loading && <LoadingOverlay />}

          {!table.getRowModel().rows.length && <EmptyRow />}

          {!!table.getRowModel().rows.length &&
            table.getRowModel().rows.map((row) => (
              <StyledTableRow key={row.id} data-cy='tableRow'>
                {row.getVisibleCells().map((cell) => (
                  <StyledTableCell key={cell.id} {...cell.column.columnDef.meta} width={cell.column.getSize()}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
        </StyledTableBody>
      </StyledTable>
      {paginated && <TablePaginator {...paginatorProps} />}
    </React.Fragment>
  );
}

const StyledTable = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-bottom: 30px;
  /* Set relative position so any absolute position elements (flyout menu) are relative to the table. */
  position: relative;

  .span-all-columns {
    display: flex;
    justify-content: center;
  }
`;

const StyledTableHeader = styled.div`
  display: contents;
`;

const StyledIcon = styled(Icon)`
  padding-left: 8px;
  padding-top: 1px;
`;

const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTableRow = styled.div`
  display: flex;

  & > *:first-child {
    padding-left: ${({ theme }) => theme.dimensions.marginBig};
  }

  & > *:last-child {
    padding-right: ${({ theme }) => theme.dimensions.marginBig};
  }
`;

export const StyledTableCell = styled.div<{ width?: number }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black25};
  display: flex;
  flex: ${({ width }) => (width ? width : 150)} 0 auto;
  padding: 15px 5px;
  text-overflow: ellipsis;
  white-space: initial;
  width: ${({ width }) => (width ? width : 150)}px;
`;

const StyledTableHeaderCell = styled(StyledTableCell)<{ isSorted: boolean }>`
  align-items: normal;
  background-color: transparent;
  color: ${(props) => (props.isSorted ? props.theme.colors.black : props.theme.colors.black50)};
  font-weight: bold;
`;
