import React from 'react';

export default function RestoreIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20px' height='17px' viewBox='0 0 20 17' version='1.1' {...props}>
      <g id='note-strikethrough' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='restore-note' transform='translate(-949.000000, -721.000000)' fill='#000000' fillRule='nonzero'>
          <g id='ic_restore_48px' transform='translate(949.000000, 721.000000)'>
            <path
              d='M11.1382145,0 C6.51857154,0 2.78571433,3.74214292 2.78571433,8.357143 L0,8.357143 L3.61678578,11.9739288 L3.68178578,12.1085716 L7.42857156,8.357143 L4.64285722,8.357143 C4.64285722,4.76821437 7.5539287,1.85714289 11.1428573,1.85714289 C14.731786,1.85714289 17.6428574,4.76821437 17.6428574,8.357143 C17.6428574,11.9460716 14.731786,14.8571431 11.1428573,14.8571431 C9.34607159,14.8571431 7.72571442,14.1235717 6.55107154,12.9489288 L5.23714295,14.2628574 C6.74607154,15.7764288 8.83071444,16.714286 11.1382145,16.714286 C15.7578574,16.714286 19.5,12.9721431 19.5,8.357143 C19.5,3.74214292 15.7578574,0 11.1382145,0 Z M10.2142859,4.64285722 L10.2142859,9.28571444 L14.1885717,11.6442859 L14.8571431,10.5160716 L11.6071431,8.58928586 L11.6071431,4.64285722 L10.2142859,4.64285722 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
