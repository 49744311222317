import Location, { getDefaults as getLocationModelDefaults, LocationOptions } from 'models/Location';

export interface RehabFacilityOptions extends LocationOptions {}

export default class RehabFacility extends Location {
  constructor(options: Partial<RehabFacilityOptions> = {}) {
    const opts = { ...getLocationModelDefaults(), ...options };

    super(opts);
  }
}
