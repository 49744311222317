import React from 'react';

export default function DropdownCaretIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={8} height={6} viewBox={'0 0 8 6'} version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='dropdown-caret-icon-black100' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M7 0.750008C6.8011 0.75005 6.61037 0.829096 6.46975 0.969758L4 3.43951L1.53025 0.969758C1.3888 0.833139 1.19935 0.757544 1.0027 0.759253C0.806052 0.760961 0.617942 0.839838 0.478886 0.978894C0.33983 1.11795 0.260953 1.30606 0.259245 1.50271C0.257536 1.69935 0.333132 1.88881 0.46975 2.03026L3.46975 5.03026C3.6104 5.17086 3.80113 5.24985 4 5.24985C4.19887 5.24985 4.3896 5.17086 4.53025 5.03026L7.53025 2.03026C7.63511 1.92537 7.70651 1.79175 7.73544 1.64628C7.76436 1.50082 7.74951 1.35004 7.69276 1.21302C7.63601 1.07599 7.5399 0.958869 7.41659 0.876456C7.29329 0.794043 7.14831 0.750039 7 0.750008Z'
          fill='currentColor'
          fillOpacity='0.75'
        />
      </g>
    </svg>
  );
}
