import styled from 'styled-components';

import CircularProgress from 'components/shared/CircularProgress';
import { useAuth } from 'context/auth';
import { useEnvironment } from 'context/environment';
import { useProfile } from 'context/profile';
import { colors } from 'styles/theme';

export default function AppLoadingWrapper({ children }) {
  const { loading: loadingEnv } = useEnvironment();
  const { loading: loadingAuth } = useAuth();
  const { loading: loadingProfile } = useProfile();

  const loading = loadingEnv || loadingAuth || loadingProfile;

  if (loading) {
    return (
      <FullScreen>
        <CircularProgress color={colors.primaryBlue} />
      </FullScreen>
    );
  }

  return children;
}

const FullScreen = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
