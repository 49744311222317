import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';

import OlioFooter from 'components/shared/OlioFooter';

function FormPage({ children }: Props) {
  return (
    <FormPageWrapper>
      <Container>
        {children}
        <OlioFooter />
      </Container>
    </FormPageWrapper>
  );
}

FormPage.propTypes = {
  children: PropTypes.element,
};

type Props = InferProps<typeof FormPage.propTypes>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default FormPage;

const FormPageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: unset;
  min-height: 100%;
  padding: 24px 24px 0 24px;
  overflow: auto;
`;
