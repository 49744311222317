import { ForwardedRef, forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { camelCase } from 'lodash';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import MoreContentRequester from 'components/MoreContentRequester';
import PortfolioLaneEmptyState from 'components/portfolio/PortfolioLaneEmptyState';
import Menu from 'components/shared/menu';
import { PatientState } from 'constants/filterKeysConstants';
import { Episode, Profile } from 'models';
import RehabState, { RehabStateName, RehabStateOptions } from 'models/RehabState';
import { showLocationEpisode } from 'services/api/locationEpisodes';
import { indexPortfolioLane, PortfolioColumn, portfolioQueryKeys } from 'services/api/portfolio';
import { upsertPortfolioFilter } from 'services/api/preferences';
import { transitionRules } from 'services/transitionrules';
import {
  FilterState,
  PortfolioSorts,
  PortfolioSortValue,
  usePortfolioActions,
  usePortfolioStore,
} from 'stores/portfolioStore';
import { useToastActions } from 'stores/toastStore';
import { colors } from 'styles/theme';
import SortIcon from 'svg/SortIcon';

import AdmittedAtModal from '../modals/AdmittedAtModal';
import DischargeModal from '../modals/DischargeModal';

import PatientCard, { SkeletonPatientCard } from './PortfolioCard';

type PortfolioLaneType = {
  locationType?: string;
  patientState?: PatientState;
  debouncedSearch: string;
  currentRehabState: RehabStateName;
  profile?: Profile;
  rehabStates: null | RehabStateOptions[];
  filters: FilterState;
  initialColumnData?: PortfolioColumn;
  loadingPortfolio: boolean;
};

type DraggableCard = {
  type: string;
  episode: Episode;
  locationEpisodeId: string;
  rehabState: RehabState;
};

export const PortfolioLane = forwardRef((props: PortfolioLaneType, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    debouncedSearch,
    currentRehabState,
    profile,
    rehabStates,
    filters,
    patientState,
    locationType,
    initialColumnData,
    loadingPortfolio,
  } = props;

  const { mutate } = useMutation({
    mutationFn: upsertPortfolioFilter,
  });

  const sorts = usePortfolioStore((state) => state.sorts);
  const { setSort } = usePortfolioActions();

  const rehabStateApiName = camelCase(currentRehabState);

  const queryClient = useQueryClient();
  const default_sort =
    currentRehabState.toLowerCase() == 'in treatment'
      ? { key: rehabStateApiName, attributeName: 'lengthOfStay', direction: 'desc' }
      : { key: rehabStateApiName, attributeName: 'patientName', direction: 'asc' };

  const sort = sorts?.[rehabStateApiName] ?? default_sort;

  const [sortingDifferentThanInitial, setSortingDifferentThanInitial] = useState(false);

  const queryParams = useMemo(
    () => ({
      locationType: locationType,
      patientState: patientState,
      search: debouncedSearch,
      active: true,
      currentRehabState: currentRehabState,
      sortBy: `${sort.attributeName} ${sort.direction}`,
      filters,
    }),
    [locationType, patientState, debouncedSearch, currentRehabState, sort, filters]
  );

  // if sorting does not change, lane uses initial data and will query subsequent pages starting at page 2
  // if sorting changes, lane controls itself starting at page 1
  const entries = useInfiniteQuery({
    queryKey: [
      ...portfolioQueryKeys.lane(currentRehabState!),
      profile?.actingClientId,
      queryParams,
      sortingDifferentThanInitial,
      initialColumnData,
    ],
    queryFn: ({ pageParam, signal }) => indexPortfolioLane({ ...queryParams, page: pageParam, pageSize: 25 }, signal),
    initialPageParam: sortingDifferentThanInitial ? 1 : 2,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    initialData: initialColumnData ? { pages: [initialColumnData], pageParams: [1] } : undefined,
    enabled: sortingDifferentThanInitial,
  });

  const { addToast } = useToastActions();
  const [discharging, setDischarging] = useState('');
  const [admitting, setAdmitting] = useState<{ locationEpisodeId: string; patientName: string }>();
  const dischargingEpisode = useRef<Episode>();
  const rehabStateToInvalidate = useRef<string>();

  const { data: locationEpisode } = useQuery({
    queryKey: ['locationEpisode', discharging],
    queryFn: () => showLocationEpisode({ id: discharging, include: 'question_templates,owner.client.group_types' }),
    enabled: !!discharging,
  });

  const [{ dragItem, dragItemType, isOver }, dropZone] = useDrop({
    accept: 'card',
    drop: (item: DraggableCard) => {
      if (item.rehabState.state === currentRehabState) return item;
      const result = canChangeState(item);
      if (result.valid) {
        rehabStateToInvalidate.current = item.rehabState.apiName;
        if (currentRehabState == RehabStateName.Discharged) {
          dischargingEpisode.current = item.episode;
          setDischarging(item.locationEpisodeId);
        }
        if (currentRehabState == RehabStateName.Admission) {
          setAdmitting({ locationEpisodeId: item.locationEpisodeId, patientName: item.episode.patient.name });
        }
      } else {
        addToast({ text: result.message ?? 'Something went wrong.' });
      }
      return item;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      dragItem: monitor.getItem(),
      dragItemType: monitor.getItemType(),
    }),
  });

  const canChangeState = (item: any) => {
    if (!item) return { valid: false };
    const sourceState: RehabStateName = item.rehabState.state;
    const destinationState = currentRehabState;
    const acceptRules = transitionRules[sourceState].accepts;
    const rejectRules = transitionRules[sourceState].rejects;
    const permission = transitionRules[destinationState].permission;
    if (permission.permission ? !profile?.permissions[permission.permission] : false) {
      return {
        valid: false,
        message: permission.message,
      };
    }
    if (acceptRules.includes(destinationState)) {
      return {
        valid: true,
        message: '',
      };
    }
    const rejectRule = rejectRules.find((x) => x.states.includes(destinationState));
    return {
      valid: false,
      message: rejectRule?.message,
    };
  };

  const invalidate = useCallback(() => {
    if (rehabStateToInvalidate.current) {
      queryClient.invalidateQueries({
        queryKey: ['portfolio'],
      });
    }
  }, [queryClient]);

  const handleSetSort = (sort: PortfolioSortValue) => {
    setSort(rehabStateApiName, sort);

    setSortingDifferentThanInitial(true);

    const updatedSorts: PortfolioSorts = { ...sorts, [rehabStateApiName]: sort };

    mutate({
      clientId: profile?.actingClient?.id as string,
      value: {
        ...filters,
        sorts: updatedSorts,
      },
    });
  };

  const cards = entries.data?.pages.flatMap((page) => page.data) ?? [];

  const totalRecords = sortingDifferentThanInitial
    ? entries.data?.pages?.[0]?.meta?.totalRecords || 0
    : initialColumnData?.meta.totalRecords;

  const showSkeleton = !profile || entries.isLoading || loadingPortfolio;

  return (
    <SwimlaneColumn
      ref={ref}
      $dragValid={dragItemType == 'card' && canChangeState(dragItem).valid}
      $isOver={isOver}
      data-cy={`lane${currentRehabState.replace(/\s/g, '')}`}>
      {!!admitting && (
        <AdmittedAtModal
          setShow={(show) => {
            if (!show) {
              setAdmitting(undefined);
            }
          }}
          invalidateData={invalidate}
          rehabStates={rehabStates}
          locationEpisodeId={admitting.locationEpisodeId}
          patientName={admitting.patientName}
        />
      )}
      {discharging && locationEpisode && (
        <DischargeModal
          rehabStates={rehabStates}
          invalidateData={invalidate}
          episode={dischargingEpisode.current!}
          locationEpisode={locationEpisode}
          setShow={(show) => {
            if (!show) {
              setDischarging('');
            }
          }}
          patientName={dischargingEpisode.current?.patient.name ?? ''}
        />
      )}
      <SwimlaneColumnHeader>
        <RehabStateText>{currentRehabState}</RehabStateText>
        <Menu>
          <Menu.Trigger>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }} role='button'>
              <SortIcon color={colors.black50} />
              <div>{totalRecords}</div>
            </div>
          </Menu.Trigger>
          <Menu.Content position='right'>
            <Menu.Item
              onClick={() =>
                handleSetSort({
                  key: rehabStateApiName,
                  attributeName: 'patientName',
                  direction: 'asc',
                  rehabStateApiName,
                })
              }
              $active={sort.attributeName === 'patientName' && sort.direction === 'asc'}>
              Patient Name (A-Z)
            </Menu.Item>
            <Menu.Item
              $active={sort.attributeName === 'patientName' && sort.direction === 'desc'}
              onClick={() =>
                handleSetSort({
                  key: rehabStateApiName,
                  attributeName: 'patientName',
                  direction: 'desc',
                  rehabStateApiName,
                })
              }>
              Patient Name (Z-A)
            </Menu.Item>

            {currentRehabState !== RehabStateName.Queue && (
              <>
                <Menu.Item
                  $active={sort.attributeName === 'lengthOfStay' && sort.direction === 'desc'}
                  onClick={() =>
                    handleSetSort({
                      key: rehabStateApiName,
                      attributeName: 'lengthOfStay',
                      direction: 'desc',
                      rehabStateApiName,
                    })
                  }>
                  Length of Stay (Highest)
                </Menu.Item>
                <Menu.Item
                  $active={sort.attributeName === 'lengthOfStay' && sort.direction === 'asc'}
                  onClick={() =>
                    handleSetSort({
                      key: rehabStateApiName,
                      attributeName: 'lengthOfStay',
                      direction: 'asc',
                      rehabStateApiName,
                    })
                  }>
                  Length of Stay (Lowest)
                </Menu.Item>
              </>
            )}
          </Menu.Content>
        </Menu>
      </SwimlaneColumnHeader>
      <SwimlaneColumnItems ref={dropZone}>
        {showSkeleton ? (
          <>
            <SkeletonPatientCard />
            <SkeletonPatientCard />
            <SkeletonPatientCard />
          </>
        ) : (
          <>
            {cards.length == 0 ? (
              <PortfolioLaneEmptyState rehabStateName={currentRehabState} />
            ) : (
              cards.map((x) => (
                <PatientCard
                  key={x.info.locationEpisodeId}
                  episode={x.episode}
                  info={x.info}
                  profile={profile}
                  invalidateColumn={invalidate}
                />
              ))
            )}
            <MoreContentRequester resource={entries} />
          </>
        )}
      </SwimlaneColumnItems>
    </SwimlaneColumn>
  );
});

const SwimlaneColumn = styled.div<{ $dragValid: boolean; $isOver: boolean }>`
  scroll-snap-align: center center;
  scroll-snap-stop: always;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 100%;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  flex-direction: column;
  padding: 15px 0px 12px 12px;
  border: ${({ $dragValid }) => ($dragValid ? '2px dotted ' + colors.primaryBlue : '2px solid transparent')};
  background-color: ${({ $dragValid, $isOver }) =>
    $dragValid && $isOver ? 'rgba(50, 83, 239, 0.1)' : 'rgb(226, 228, 230)'};

  @media ${({ theme }) => theme.devices.desktop} {
    min-width: 0;
  }
`;
const SwimlaneColumnHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  padding-bottom: 24px;
  padding-right: 10px;
`;

const SwimlaneColumnItems = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 4px;

  &::-webkit-scrollbar {
    height: 0.5em;
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.scrollbarGray};
    border-radius: 5pt;
  }
`;

const RehabStateText = styled.p`
  flex: 1;
`;
