import Client from 'models/Client';

import AttrValue from './AttrValue';

export const baseAttrMatchRules = {
  '*': ['%{value}'],
};

export interface AttrOptions {
  id: string;
  clientId: string;
  createdAt: string | null;
  createdBy: string | null;
  name: string;
  displayName: string | null;
  olioAttr: string | null;
  visible: boolean;
  matchRules: Record<string, unknown> | null;
  client: Client | null;
  active: boolean;
  attrValues?: AttrValue[];
}
function getDefaults(): AttrOptions {
  return {
    id: '',
    clientId: '',
    createdAt: null,
    createdBy: null,
    name: '',
    displayName: '',
    olioAttr: '',
    visible: false,
    matchRules: baseAttrMatchRules,
    client: null,
    active: true,
  };
}
/**
 * @class Attr
 * @classdesc Represents an attribute in the system
 * @property {string} id - The attribute's id
 * @property {string} clientId - The attribute's client id
 * @property {string} createdAt - The attribute's creation date
 * @property {string} createdBy - The attribute's creator
 * @property {string} name - The attribute's name
 * @property {string} displayName - The attribute's display name
 * @property {string} olioAttr - The attribute's Olio attribute
 * @property {boolean} visible - Whether the attribute is visible
 * @property {Record<string, unknown>} matchRules - The attribute's match rules
 * @property {Client} client - The attribute's client
 * @property {boolean} active - Whether the attribute is active
 * @property {AttrValue[]} attrValues - The attribute's values
 * @method {string} olioAttrObjectType - The attribute's Olio attribute object type
 * @method {boolean} isGroupType - Whether the attribute is a group type
 * @method {boolean} isClassificationType - Whether the attribute is a classification type
 * @method {boolean} isGroupType - Whether the attribute is a group type
 * @method {boolean} isClassificationType - Whether the attribute is a classification type
 * @method {boolean} isGroupType - Whether the attribute is a group type
 */
export default class Attr {
  id: string;
  clientId: string;
  createdAt: string | null;
  createdBy: string | null;
  name: string;
  displayName: string | null;
  olioAttr: string | null;
  visible: boolean;
  matchRules: Record<string, unknown> | null;
  client: Client | null;
  active: boolean;
  attrValues?: AttrValue[];

  constructor(options: Partial<AttrOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.clientId = opts.clientId;
    this.client = opts.client ? new Client(opts.client) : opts.client;
    this.createdAt = opts.createdAt;
    this.createdBy = opts.createdBy;
    this.name = opts.name;
    this.displayName = opts.displayName;
    this.olioAttr = opts.olioAttr;
    this.visible = opts.visible;
    this.matchRules = opts.matchRules;
    this.active = opts.active;
    this.attrValues = opts.attrValues?.map((attrValue) => new AttrValue(attrValue));
  }

  get olioAttrObjectType(): string | null {
    if (!this.olioAttr) return null;

    return this.olioAttr.match(/classification/i) ? 'Classification' : 'Group';
  }

  get isGroupType(): boolean {
    return this.olioAttrObjectType === 'Group';
  }

  get isClassificationType(): boolean {
    return this.olioAttrObjectType === 'Classification';
  }
}
