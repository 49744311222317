import React, { Fragment, useMemo } from 'react';

import { emptySelectValue, FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import InputGroup from 'components/shared/form/InputGroup';
import ToggleGroup, { ToggleOption } from 'components/shared/form/ToggleGroup';
import { useForm } from 'context/form';
import { useAddressOptions } from 'hooks/useAddressOptions';
import Profile from 'models/Profile';
import { RehabStateName } from 'models/RehabState';
import { useInfiniteLocations } from 'services/api/location';

export type RehabFacilityInputGroupProps = {
  profile: Profile;
};

function RehabFacilityInputGroup({ profile }: RehabFacilityInputGroupProps) {
  const { values, meta, errors, setValue } = useForm<FormValues, FormMeta>();
  const owner = values.owner;

  const asyncLocationOptions = useInfiniteLocations({
    ownerId: owner?.id,
    type: values.locationType?.value,
    include: 'group_type',
    sortBy: 'name asc',
  });

  const addressProps = useAddressOptions();

  const patientInQueue = !meta.currentRehabState || meta.currentRehabState == RehabStateName.Queue;
  const editingNonQueuePatient = meta.mode == PatientFormMode.Edit && !patientInQueue;
  const locationTypeIsDisabled = !owner?.id || editingNonQueuePatient;
  const isPostAcuteUser = profile?.isPostAcute;

  const locationTypeOptions = useMemo(() => {
    let enabledProviderTypes = profile.enabledProviderTypes;
    if (isPostAcuteUser) {
      const ownerClientEnabledProviderTypes = owner?.client?.enabledProviderTypes.map(
        (enabledProviderType) => enabledProviderType.apiName
      );

      enabledProviderTypes = profile.enabledProviderTypes.filter((userProviderType) =>
        ownerClientEnabledProviderTypes?.includes(userProviderType.apiName)
      );
    }

    return enabledProviderTypes.map((groupType) => ({ label: groupType.displayName, value: groupType.apiName }));
  }, [isPostAcuteUser, owner?.client?.enabledProviderTypes, profile.enabledProviderTypes]);

  const rehabFacilityIsDisabled =
    locationTypeIsDisabled || editingNonQueuePatient || !!meta.patientHasActiveServiceRefusals;
  const isVisible = patientInQueue && locationTypeOptions.length > 1;

  const locationLabel = values.locationType?.label ? values.locationType.label : locationTypeOptions[0]?.label;

  const handleLocationTypeChange = (change: ToggleOption) => {
    if (change.value != values.locationType?.value) {
      setValue('rehabFacility', null, { validate: false });
    }
    setValue('locationType', change);
  };

  return (
    <Fragment>
      {isVisible && (
        <InputGroup title={'What care is the patient receiving?'}>
          <ToggleGroup
            selectedOption={values.locationType ?? emptySelectValue}
            disabled={rehabFacilityIsDisabled}
            options={locationTypeOptions}
            getOptionValue={(prop) => prop.value}
            getOptionLabel={(prop) => prop.label}
            onChange={handleLocationTypeChange}
            visible={isVisible}
            autoSelectFirstOption
          />
        </InputGroup>
      )}
      <InputGroup title={locationLabel} error={errors.rehabFacility} data-cy='rehabFacility'>
        <Select
          {...asyncLocationOptions}
          {...addressProps}
          name={locationLabel}
          placeholder={locationLabel}
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.name}
          onChange={(change) => setValue('rehabFacility', change)}
          disabled={rehabFacilityIsDisabled || !values.locationType}
          value={values.rehabFacility}
          hasError={!!errors.rehabFacility}
        />
      </InputGroup>
    </Fragment>
  );
}

export default RehabFacilityInputGroup;
