import React from "react";
import styled from "styled-components";

type Props = {
  count: number;
  countType: string;
};

function CountMessage({ count, countType }: Props) {
  const messageText = `Showing ${count} ${countType}`;

  return (
    <Container>
      {messageText}
      {count === 1 ? "" : "s"}
    </Container>
  );
}

export default CountMessage;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  margin-left: 16px;
`;
