import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

export default function AtIcon({ ...props }: Props) {
  return (
    <svg
      width='18px'
      height='18px'
      viewBox='0 0 18 18'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <g id='web' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
        <g
          id='a---note-container-with-@'
          transform='translate(-818.000000, -427.000000)'
          stroke='currentColor'
          strokeWidth='1'>
          <g id='at-sign-2' transform='translate(819.000000, 428.000000)'>
            <circle id='Oval' cx='7.78105263' cy='7.78105263' r='3.70526316' />
            <path
              d='M10.8934737,14.9147958 C7.74004111,16.2910664 4.05710481,15.4328691 1.83685083,12.8044228 C-0.383403146,10.1759764 -0.613782567,6.40139729 1.27033017,3.52244313 C3.1544429,0.643488966 6.70567122,-0.656241716 10.003073,0.326305689 C13.3004748,1.30885309 15.5610924,4.34037583 15.5621053,7.78105263 L15.5621053,9.44842105 C15.5621053,10.5739192 14.6497087,11.4863158 13.5242105,11.4863158 C12.3987123,11.4863158 11.4863158,10.5739192 11.4863158,9.44842105 L11.4863158,4.44631579'
              id='at-icon-path'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
