import { useState } from 'react';
import styled from 'styled-components';

import { SkeletonBlurb } from 'components/Containers';
import ProgressUpdateModal from 'components/modals/ProgressUpdateModal';
import ServiceRefusalModal from 'components/modals/ServiceRefusalModal';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import { dateAtTime } from 'lib/date';
import { Activity, LocationEpisode } from 'models';
import Profile from 'models/Profile';
import { colors } from 'styles/theme';
import { BodySmallBold, Label } from 'styles/typography';
import ThumbsDownIcon from 'svg/ThumbsDownIcon';
import ThumbsUpIcon from 'svg/ThumbsUpIcon';
import UnsureIcon from 'svg/UnsureIcon';

enum StatusResponse {
  YES = 'Yes',
  NO = 'No',
  UNSURE = 'Unsure',
  EXPIRED = 'Expired',
}

const RefuseServiceButton = styled(Button)`
  border: 1px solid var(--black);
`;

const SkeletonBlank = styled(SkeletonBlurb)<{
  $width: number;
  $height: number;
}>`
  border-radius: ${({ $height }) => $height + 'px'};
  height: ${({ $height }) => $height + 'px'};
  width: ${({ $width }) => $width + 'px'};
  margin-right: 8px;
`;

type PatientStatusType = {
  locationEpisode?: LocationEpisode;
  patientName?: string;
  profile?: Profile;
  activities?: Activity[];
};
export default function PatientStatus(props: PatientStatusType) {
  const { locationEpisode, patientName, profile, activities } = props;

  const [showServiceRefusal, setShowServiceRefusal] = useState(false);
  const [showProgressUpdate, setShowProgressUpdate] = useState(false);

  const STATUS_MAP = {
    [StatusResponse.YES]: {
      icon: ThumbsUpIcon,
      label: 'On track',
      color: colors.accentGreen,
      textColor: colors.white,
    },
    [StatusResponse.NO]: {
      icon: ThumbsDownIcon,
      label: 'Off track',
      color: colors.accentRed,
      textColor: colors.white,
    },
    [StatusResponse.UNSURE]: {
      icon: UnsureIcon,
      label: 'Unsure',
      color: colors.accentYellow,
      textColor: colors.black75,
    },
    [StatusResponse.EXPIRED]: {
      icon: undefined,
      label: 'Needs update',
      color: colors.black50,
      textColor: colors.white,
    },
  };
  if (!locationEpisode || !profile) {
    return (
      <ProgressContainer>
        <PatientStatusContainer>
          <Status>
            <SkeletonBlank $height={15} $width={150} />
            <SkeletonBlank $height={24} $width={80} />
          </Status>
          <SkeletonBlank $height={12} $width={130} />
        </PatientStatusContainer>
      </ProgressContainer>
    );
  }
  const { onTrack, onTrackUpdatedAt, statusOutOfDate } = locationEpisode;
  const status = statusOutOfDate ? STATUS_MAP[StatusResponse.EXPIRED] : STATUS_MAP[onTrack as StatusResponse];
  const Icon = status?.icon;
  const chipColor = onTrack === StatusResponse.UNSURE ? 'black' : 'white';

  const progressButtonText =
    !locationEpisode.progressTemplate || locationEpisode.inTreatment || locationEpisode.discharged
      ? 'Update Progress'
      : 'Start Treatment';

  const eligibleForProgressUpdate = locationEpisode.rehabInformation.eligibleForProgressUpdate;
  const canRefuseService =
    profile.allowedLocationIds.includes(locationEpisode.locationId) &&
    profile.permissions.reviewServiceRefusalCreate &&
    locationEpisode.rehabInformation?.latestRehabFacilityType === 'HHA' &&
    locationEpisode.id &&
    locationEpisode.currentRehabState.queue;
  const canCreateProgressUpdate =
    !profile.isAcute &&
    profile.permissions.activityProgressUpdateCreate &&
    profile.allowedLocationIds.includes(locationEpisode.locationId);
  const hasActiveRefusal = !!locationEpisode.reviews.find((r) => r.pending && r.isServiceRefusal); //used to reference r.active
  const progressUpdateDisabled = !locationEpisode.progressTemplate.id || !eligibleForProgressUpdate || hasActiveRefusal;

  if (
    !locationEpisode.latest ||
    locationEpisode.archived ||
    (!canRefuseService && !canCreateProgressUpdate && (!locationEpisode.onTrack || !eligibleForProgressUpdate))
  ) {
    return <></>;
  }
  return (
    <ProgressContainer>
      {locationEpisode.onTrack && eligibleForProgressUpdate && (
        <PatientStatusContainer>
          <Status>
            <StyledLabel>Patient Progress Status</StyledLabel>
            <Chip $backgroundColor={status.color} $textColor={status.textColor}>
              {Icon && <Icon color={chipColor} size={12} />}
              {status.label}
            </Chip>
          </Status>
          <LastUpdated>Updated: {dateAtTime(onTrackUpdatedAt)}</LastUpdated>
        </PatientStatusContainer>
      )}

      {(canRefuseService || canCreateProgressUpdate) && (
        <PatientActions>
          {canRefuseService && (
            <RefuseServiceButton
              variant='ghost'
              data-cy='clickable'
              disabled={hasActiveRefusal}
              onClick={() => setShowServiceRefusal(true)}>
              Refuse Services
            </RefuseServiceButton>
          )}
          {canCreateProgressUpdate && (
            <Button data-cy='clickable' onClick={() => setShowProgressUpdate(true)} disabled={progressUpdateDisabled}>
              {progressButtonText}
            </Button>
          )}
        </PatientActions>
      )}
      {showServiceRefusal && (
        <ServiceRefusalModal
          setShow={setShowServiceRefusal}
          locationEpisode={locationEpisode}
          patientName={patientName!}
        />
      )}
      {showProgressUpdate && (
        <ProgressUpdateModal
          activities={activities!}
          profile={profile}
          setShow={setShowProgressUpdate}
          patientName={patientName!}
          locationEpisode={locationEpisode}
        />
      )}
    </ProgressContainer>
  );
}

const Status = styled.div`
  display: flex;
`;

const StyledLabel = styled(BodySmallBold)`
  margin: auto 8px auto auto;
`;

const LastUpdated = styled(Label)`
  font-style: italic;
  margin-top: 4px;
`;

const PatientStatusContainer = styled.div`
  margin-right: 8px;
`;

const PatientActions = styled(ButtonGroup)`
  margin-left: auto;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Chip = styled(Label)<{ $backgroundColor: string; $textColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $textColor }) => $textColor};
  height: 24px;
  white-space: nowrap;
  border-radius: 12px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 24px;
`;
