import React from 'react';

export default function ActivityPatientFieldChangeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='standardized-activity-feed' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='patient-details-change---expanded' transform='translate(-573.000000, -528.000000)'>
          <g id='Group-3-Copy' transform='translate(559.000000, 514.000000)'>
            <g id='Group-6' transform='translate(14.000000, 14.000000)'>
              <g id='Group-4' fill='#0F1226' fillOpacity='0.25' opacity='0.25'>
                <rect id='Rectangle-Copy-2' x='0' y='0' width='24' height='24' rx='3' />
              </g>
              <g id='a-edit' transform='translate(5.000000, 5.000000)' fill='#7F808B' fillRule='nonzero'>
                <circle id='Oval' cx='7' cy='3.5' r='3.5' />
                <path
                  d='M6.125,11.524625 L8.79725,8.852375 C8.23725,8.79025 7.6405,8.75 7,8.75 C4.207,8.75 2.153375,9.445625 1.017625,9.95925 C0.39375,10.241875 0,10.864875 0,11.55 L0,14 L6.125,14 L6.125,11.524625 Z'
                  id='Path'
                />
                <polygon id='Path' points='9.625 14 7.875 14 7.875 12.25 12.25 7.875 14 9.625' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
